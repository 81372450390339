import * as React from "react";
import envs, { DEFAULT_ENV, Env } from "../constants/envs";

type EnvContextValue = [Env, (env: Env) => void, string[]];

const initialValue: EnvContextValue = [DEFAULT_ENV as Env, () => undefined, envs];

const EnvContext = React.createContext(initialValue);

export const useEnv = () => React.useContext(EnvContext);

export const EnvProvider: React.FC = ({ children }) => {
  const [selected, setSelected] = React.useState(() => initialValue[0]);
  const handleSelected = React.useCallback(
    (env: Env) => {
      setSelected(env);
    },
    [setSelected]
  );
  const value = React.useMemo<EnvContextValue>(
    () => [selected, handleSelected, envs],
    [selected, handleSelected]
  );

  return <EnvContext.Provider value={value}>{children}</EnvContext.Provider>;
};
