import * as React from "react";
import * as Cache from "../services/cache";
import {StyleSheet, Image, ActivityIndicator, SafeAreaView, Appearance, Platform} from "react-native";
import { Button, Select, Icon } from "native-base";

import { View, Label, Text } from "../components/Styled";
import { useRegion } from "../contexts/region";
import { useTranslation } from "../contexts/translations";
import { useTheme } from "../contexts/theme/hooks";
import { FontAwesome } from "@expo/vector-icons";
import AppBrand from '../components/AppBrand';

function BasicAuth({
  signIn,
  signOut,
  forgotPassword,
  disabled,
  isLoading,
}: {
  signIn: () => void;
  signOut: () => void;
  forgotPassword: () => void;
  disabled: boolean;
  isLoading: boolean;
}) {
  const { region, setRegion, regions, askRegion } = useRegion();
  const { t } = useTranslation();
  const [theme] = useTheme();

  return (
    <View $fullscreen>
      <SafeAreaView style={styles.content}>
        <AppBrand/>
        <View flexGrow={1} $transparent width={330} spaceTop={100}>
          {askRegion && (
            <>
              <Label>{t("auth.region_picker")}</Label>
              <Select selectedValue={region} onValueChange={setRegion}>
                <Select.Item label="" value="" />
                {regions.map((r) => (
                  <Select.Item key={r} label={r} value={r} />
                ))}
              </Select>
              <Button
                variant="ghost"
                rightIcon={<Icon name="sign-out" as={FontAwesome} />}
                onPress={signOut}
                accessible
                accessibilityLabel={t("accessibility.signOut")}
                accessibilityRole="button"
              >
                {t("settings.sign_out")}
              </Button>
            </>
          )}
          {!askRegion && (
            <>
              <Button
                onPress={signIn}
                shadow="9"
                backgroundColor="blue.600"
                variant="unstyled"
                disabled={disabled}
                borderRadius={20}
                padding={3}
                accessible
                accessibilityLabel={t("auth.sign_in")}
                accessibilityRole="button"
              >
                <Text color="white" size={14} $bold>{t("auth.sign_in")}</Text>
              </Button>
              {/* <Button
                variant="unstyled"
                onPress={forgotPassword}
                disabled={disabled}
                mt={2}
              >
                {t("auth.forgot_password")}
              </Button> */}
            </>
          )}
          {
            isLoading &&
            <View $transparent spaceTop={10}>
              <ActivityIndicator
                  accessible
                  accessibilityLabel={t("accessibility.loading", {
                    text: "App",
                  })}
                  color="#b0b0b0"
                />
            </View>
          }
        </View>
      </SafeAreaView>
    </View>
  );
}

export default BasicAuth;

const styles = StyleSheet.create({
  content: {
    flex: 1,
    // justifyContent: "center",
    alignItems: "center",
  },
  center: {
    width: 220,
  },
  logo: {
    width: 50,
    height: 50,
    marginBottom: 20,
    alignSelf: "center",
  },
  regionPicker: {
    flexGrow: 0,
    padding: 8,
    marginTop: 10,
    marginBottom: 40,
    width: 220,
    height: 40,
    backgroundColor: "rgba(225,225,225,0.2)",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
    elevation: 2,
  },
});
