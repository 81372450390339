import { Button} from "native-base";
import * as React from "react";
import { StyleSheet } from "react-native";
import { useTranslation } from "../contexts/translations";
import { useNavigation } from "@react-navigation/native";
import { HStack, Pressable, Toast} from "native-base";
import { View, Label, Divider ,Text} from "../components/Styled";
import { MaterialCommunityIcons } from "@expo/vector-icons";

import {
    useStartupUser,
  } from "../contexts/startup/hooks";
import * as API from "../services/api";
import { useRoute } from '@react-navigation/native';

export default function CredentialTypeListScreen() {
  const { t } = useTranslation();
  const data = useStartupUser();
  const navigation = useNavigation();
  const route = useRoute();


  return (
    <View $transparent height={300}>
        <View spaceBottom={10}>
            <Text $textAlign="center" $bold size={14}>{route.params?.name}</Text>
        </View>
        <Divider $fullSize spaceVertical={1} />
        {
            route.params?.cards?.map(({ id, cardNumber, status }) => 
                <View key={id}>
                    <Pressable onPress={() => navigation.navigate("CredentialAction", {id, cardNumber, status, name: route.params?.name})}>
                        <HStack style={styles.pressableMennu} justifyContent="space-between">
                        <Label>{cardNumber}</Label>
                        <MaterialCommunityIcons name="chevron-right" size={23}/>
                        </HStack>
                    </Pressable>
                    <Divider $fullSize spaceVertical={1} />
                </View>
            )
        }    
    </View>
  );
}

const styles = StyleSheet.create({
    btn: {
        marginVertical:5
    },
    pressableMennu: {
        marginVertical:12
      },
});