import * as React from "react";
import {
  Platform,
  StyleSheet,
  Linking,
  Alert,
} from "react-native";
import Constants from "expo-constants";
import {
  Button,
  VStack,
  HStack,
  Select,
  Switch,
  Divider,
  Icon,
} from "native-base";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { FontAwesome } from "@expo/vector-icons";

import { useAuth } from "../contexts/auth/hooks";
import { Label, Text, View } from "../components/Styled";
import { usePin } from "../contexts/pin";
import { useFeatureFlags } from "../contexts/feature-flags";
import { useTranslation } from "../contexts/translations";
import { useTheme } from "../contexts/theme/hooks";
import { DARK_MODE } from "../constants/Colors";
import {
  useStartupOrganization,
  useStartupUser,
  useStartupUserActions,
} from "../contexts/startup/hooks";
import {
  ACTIVATED,
  BLOCKED,
  INITIALIZED,
  IN_PRODUCTION,
  PRINTED,
  WITHDRAWN,
  APPROVED,
} from "../constants/card-status";
import * as Cache from "../services/cache";
import * as API from "../services/api";
import { CardHolderData } from "../contexts/startup";
import { MainStackParams } from "../navigation/types";
import useIsMobile from "../hooks/useIsMobile";
import usePushNotification, { PUSH_TOKEN } from "../hooks/usePushNotification";
import * as FileSystem from "expo-file-system";
import * as MailComposer from "expo-mail-composer";
import { NativeModules } from "react-native";
const { OrigoSDKModule } = NativeModules; // Access custom native module off of NativeModules. This should only be used for creating EventEmitter instance for iOS
import OrigoSDKModuleInterface from "../OrigoSDKModuleInterface"; // Interface for react native modules that we can call.
import { CardHolderContext } from "../contexts/card-holder";

const CACHE_CREDENTIALS_DATA = "credentials";

declare global {
  var isHidLoggingEnabled: boolean;
}

function filterCards(card: CardHolderData["cards"][number]) {
  return (
    card.cardStatus === ACTIVATED ||
    card.cardStatus === APPROVED ||
    (card.cardStatus === BLOCKED && card.blockReason === null)
  );
}

function filterPendingCards(card: CardHolderData["cards"][number]) {
  return [INITIALIZED, IN_PRODUCTION, PRINTED, WITHDRAWN].includes(
    card.cardStatus
  );
}

const CACHE_AUDIT_MODE = "CACHE_AUDIT_MODE";
const CACHE_PUSH_NOTIFICAION = "CACHE_PUSH_NOTIFICAION";
const CACHE_AUTOLOCK = "CACHE_AUTOLOCK";

type UserProfiles = {
  id: string;
  profile: any;
  status: string;
};

const title = "Awesome Contents";
const message = "Please check this out.";

export default function SettingsScreen() {
  const { SET_PIN, RESET_PASSWORD, MANAGE_CARD } = useFeatureFlags();
  const { reload: reloadStartup } = useStartupUserActions();
  const [, { reload: reloadCardHolderInfo }] =
    React.useContext(CardHolderContext);
  const { signOut, refreshAuth } = useAuth();
  const { locale, locales, setLocale, t } = useTranslation();
  const [theme, toggleTheme] = useTheme();
  const { lock, reset: resetPin } = usePin();
  const navigation = useNavigation<StackNavigationProp<MainStackParams>>();
  const user = useStartupUser();
  const { name: organizationName } = useStartupOrganization();
  const userCards = user?.cards || [];
  const activeOrBlockedCards = userCards.filter(filterCards);
  const pendingCards = userCards.filter(filterPendingCards);
  const cards = MANAGE_CARD ? activeOrBlockedCards : [];
  const hasPendingCards = pendingCards.length > 0;
  const [canRequestCard, setCanRequestCard] = React.useState(MANAGE_CARD);
  const [userProfiles, setUserProfiles] = React.useState<UserProfiles[]>([]);
  const [{ data }, { updateData, updateCardHolderData }] =
    React.useContext(CardHolderContext);

  const [isLoading, setIsLoading] = React.useState(!hasPendingCards);
  const [backgroundScan, setBackgroundScan] = React.useState<boolean>(false);
  const [auditMode, setAuditMode] = React.useState<boolean>();
  const [pushnotif, setPushnotif] = React.useState<boolean>(true);
  const [isAutoLock, setIsAutoLock] = React.useState<boolean>(false);
  const [isHidLoggingEnable, setHidLogging] = React.useState<boolean>(false);
  const [backgroundScanning, setBackgroundScanning] = React.useState<boolean>();
  const [profileObject, setProfileObject] = React.useState<{}>();
  const [itemProfileName, setItemProfileName] = React.useState<string>("");
  const [preferredProfile, setPreferredProfile] = React.useState<string>("");
  const [accessibilityURL, setaccessibilityURL] = React.useState<string>();

  const isMobile = useIsMobile();

  React.useEffect(() => {
    (async () => {
      const isAutoLockFromLocalStorage = await Cache.get(CACHE_AUTOLOCK);
      setIsAutoLock(isAutoLockFromLocalStorage);

      const hasPushNotifFromLocalStorage = await Cache.get(
        CACHE_PUSH_NOTIFICAION
      );
      setPushnotif(hasPushNotifFromLocalStorage);

      setHidLogging(global.isHidLoggingEnabled);

      const backScanFromLocalStorage = await Cache.get("isBackScan");
      if (backScanFromLocalStorage || !backScanFromLocalStorage) {
        // Set Background Scanning
        if (backScanFromLocalStorage) {
          setBackgroundScanning(true);
          if (Platform.OS === "android") {
            let android = await OrigoSDKModuleInterface.activateBackgroundScan(
              true
            );
          } else if (Platform.OS === "ios") {
            let ios = await OrigoSDKModule.setEnableBackgroundScan(true);
          }
        } else {
          setBackgroundScanning(false);
          if (Platform.OS === "android") {
            await OrigoSDKModuleInterface.activateBackgroundScan(false);
          } else if (Platform.OS === "ios") {
            await OrigoSDKModule.setEnableBackgroundScan(false);
          }
        }
      }
    })();
  }, [backgroundScanning]);

  React.useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const res = await Cache.get("card");

        if (res.profiles.length <= 1) {
          setPreferredProfile(res?.profiles[0]?.profile.name);
          setUserProfiles(res.profiles);
          setIsLoading(false);
          return;
        }
        if (res.profiles) {
          const profiles = res.profiles.filter(
            (t: { email: string | undefined }) => t.email === user?.email
          );
          setPreferredProfile(
            user?.profilePreference || profiles[0].profile.name
          );
          setUserProfiles(profiles);
        }
        setIsLoading(false);
      } catch (error) {
        console.log("error", error);
        setIsLoading(false);
      }
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      const generalSettings = await API.getAllThemes();
      setaccessibilityURL(generalSettings.data.standAccessibilityStatement);
    })();
  }, []);

  async function toggleAudit() {
    try {
      await Cache.set(CACHE_AUDIT_MODE, "true");
      setAuditMode(!auditMode);
    } catch (err) {
      console.log("err", err);
    }
  }

  async function toggleAutoLock() {
    try {
      await Cache.set(CACHE_AUTOLOCK, `${!isAutoLock}`);
      setIsAutoLock(!isAutoLock);
    } catch (err) {
      console.log("err", err);
    }
  }

  async function togglePushNotif() {
    try {
      const pushToken = await Cache.get(PUSH_TOKEN);

      await Cache.set(CACHE_PUSH_NOTIFICAION, "true");
      setPushnotif(!pushnotif);

      if (pushnotif) {
        if (pushToken) {
          await API.deletePushToken(pushToken);
        }
      } else {
        usePushNotification();
      }
    } catch (err) {
      console.log("err", err);
    }
  }

  const handleProfileName = async (profileName: string) => {
    setItemProfileName(profileName);
    const profile = userProfiles.filter(
      (obj) => obj.profile.name === profileName
    );
    const profileIdToSend = profile[0].profile.id;
    const personIdToSend = user?.personId;
    
    let data = {
      personId: personIdToSend,
      defaultProfile: profileIdToSend,
    };

    if (personIdToSend) {
      try {
        await Cache.set("ProfilePreference", profileName);
        await API.updateProfilePreference(data);
        await API.updateDefaultProfile(data);
        await reload();
        setStandTemplate(standTemplate);
      } catch (e) {
        console.log(e);
      }
    }
  };

  async function reload() {
    reloadStartup();
    await reloadCardHolderInfo();
  }

  const sendEmail = async () => {
    const logUri = FileSystem.cacheDirectory + "log.txt";
    try {
      const result = await MailComposer.composeAsync({
        subject: `${t("email_log.subject")}`,
        body: `${t("email_log.body")}`,
        attachments: [logUri],
      }).then(() => {
        FileSystem.deleteAsync(logUri);
        global.isHidLoggingEnabled = false;
        setHidLogging(global.isHidLoggingEnabled);
      });
    } catch (error) {
      FileSystem.deleteAsync(logUri);
      global.isHidLoggingEnabled = false;
      setHidLogging(global.isHidLoggingEnabled);
    }
  };

  const toggleHidLogging = async () => {
    global.isHidLoggingEnabled = !isHidLoggingEnable;
    setHidLogging(!isHidLoggingEnable);
  };

  const handleBackgroundScanning = async (event: boolean) => {
    setBackgroundScanning(event);
    if (event) {
      Alert.alert(
        t("alert.warning"),
        t("settings.background_scanning_warning"),
        [
          {
            text: "Cancel",
            onPress: () => {},
            style: "cancel",
          },
          {
            text: "OK",
            onPress: async () => {
              await Cache.set("isBackScan", "true");
              setBackgroundScanning(true);
            },
          },
        ]
      );
    } else {
      await Cache.remove("isBackScan");
      setBackgroundScanning(false);
    }
  };

  const handleLanguage = async (event: string) => {
    setLocale(event);
    await API.updatePersonLanguage(event, user?.personId);
  };

  return (
    <View $transparent justifyContent="space-between">
      <VStack justifyContent="space-between">
        <VStack space={3} divider={<Divider />}>
          <HStack height={9} justifyContent="space-between">
            <Label justifyContent={"center"}>{t("settings.locale")}</Label>
            <Select
              dropdownIcon={
                <Icon name="caret-down" as={FontAwesome} size={17} />
              }
              selectedValue={locale}
              onValueChange={handleLanguage}
              w={Platform.OS === "web" ? undefined : 100}
              h={Platform.OS === "web" ? 9 : undefined}
              accessible
              accessibilityLabel={t("settings.locale")}
              accessibilityRole="combobox"
            >
              {locales.map((l) => (
                <Select.Item key={l} label={t(`locales.${l}`)} value={l} />
              ))}
            </Select>
          </HStack>
          <HStack height={9} justifyContent="space-between">
            <Label>{t("settings.theme")}</Label>
            <Switch
              colorScheme={theme === DARK_MODE ? "blue" : "primary"}
              isChecked={theme === DARK_MODE ? true : false}
              onToggle={toggleTheme}
              size="sm"
              accessible
              accessibilityLabel={t("settings.theme")}
              accessibilityRole="combobox"
            />
          </HStack>
          {(
            <HStack height={9} justifyContent="space-between">
              <Label>{t("settings.default_profile")}</Label>
              {userProfiles.length > 0 && (
                <Select
                  dropdownIcon={
                    <Icon name="caret-down" as={FontAwesome} size={17} />
                  }
                  selectedValue={itemProfileName || preferredProfile}
                  onValueChange={handleProfileName}
                  w={Platform.OS === "web" ? undefined : 100}
                  h={Platform.OS === "web" ? 9 : undefined}
                  accessible
                  accessibilityLabel={t("settings.default_profile")}
                  accessibilityRole="combobox"
                >
                  {userProfiles.map((item, index) => (
                    <Select.Item
                      key={index}
                      label={item.profile?.name || ""}
                      value={item.profile?.name || ""}
                    />
                  ))}
                </Select>
              )}
            </HStack>
          )}
          {__DEV__ && SET_PIN && (
            <HStack height={9} justifyContent="space-between">
              <Label>{t("settings.notification")}</Label>
              <Switch
                colorScheme={theme === DARK_MODE ? "blue" : "primary"}
                isChecked={pushnotif}
                onToggle={togglePushNotif}
                size="sm"
              />
            </HStack>
          )}

          {__DEV__ && SET_PIN ? (
            <HStack height={9} justifyContent="space-between">
              <Label>{t("settings.auto_lock")}</Label>
              <Switch
                colorScheme={theme === DARK_MODE ? "blue" : "primary"}
                isChecked={isAutoLock}
                onToggle={toggleAutoLock}
                size="sm"
              />
            </HStack>
          ) : null}

          {__DEV__ && isMobile && (
            <HStack height={9} justifyContent="space-between">
              <Label>{t("settings.audit")}</Label>
              <Switch
                colorScheme={theme === DARK_MODE ? "blue" : "primary"}
                isChecked={auditMode}
                onToggle={toggleAudit}
                size="sm"
              />
            </HStack>
          )}

          {SET_PIN && isMobile ? (
            <HStack height={9} justifyContent="space-between">
              <Label>{t("settings.background_scanning")}</Label>
              <Switch
                colorScheme={theme === DARK_MODE ? "blue" : "primary"}
                isChecked={backgroundScanning}
                onToggle={handleBackgroundScanning}
                size="sm"
              />
            </HStack>
          ) : null}
          {SET_PIN && isMobile ? (
            <HStack height={9} justifyContent="space-between">
              <Label>{t("settings.enable_hid_logging")}</Label>
              <Switch
                colorScheme={theme === DARK_MODE ? "blue" : "primary"}
                isChecked={isHidLoggingEnable}
                onToggle={toggleHidLogging}
                size="sm"
              />
            </HStack>
          ) : null}
          {SET_PIN && isMobile && isHidLoggingEnable ? (
            <HStack height={9} justifyContent="space-between">
              <Label>{t("settings.send_log")}</Label>
              <Button onPress={sendEmail}>
                {t("settings.send_log_email")}
              </Button>
            </HStack>
          ) : null}
        </VStack>

        <VStack space={2} mt={10}>
          {SET_PIN ? (
            <Button variant="ghost" onPress={resetPin}>
              {t("settings.reset_pin")}
            </Button>
          ) : null}
          {RESET_PASSWORD ? (
            <Button
              variant="ghost"
              onPress={() => navigation.navigate("ResetPassword")}
              colorScheme={theme === DARK_MODE ? "blue" : "primary"}
              accessible
              accessibilityLabel={t("accessibility.change_password")}
              accessibilityRole="button"
            >
              {t("settings.reset_password")}
            </Button>
          ) : null}

          <Text size={12} $textAlign="center">
            {organizationName}
          </Text>
          {accessibilityURL && (
            <Text
              size={14}
              $textAlign="center"
              style={{ color: "blue", textDecorationLine: "underline" }}
              onPress={() => Linking.openURL(accessibilityURL)}
            >
              {t("settings.accessibility_URL")}
            </Text>
          )}
          <Text size={12} $textAlign="center">
            {t("settings.user_info", { email: user?.email || "" })}
          </Text>
          <Button
            colorScheme="danger"
            onPress={signOut}
            style={styles.signOut}
            accessible
            accessibilityLabel={t("accessibility.signOut")}
            accessibilityRole="button"
          >
            {t("settings.sign_out")}
          </Button>

          <HStack justifyContent="space-between">
            <Text style={styles.version}>
              {t("settings.version", { version: Constants.manifest?.version })}
            </Text>

            <Text style={styles.version}>
              {t("settings.card_exchange", { year: new Date().getFullYear() })}
            </Text>
          </HStack>
        </VStack>
      </VStack>
    </View>
  );
}

const styles = StyleSheet.create({
  version: {
    color: "gray",
    fontSize: 10,
    textAlign: "center",
    textTransform: "none",
  },
  picker: {
    flexGrow: 0,
    borderWidth: 0,
    width: 100,
    backgroundColor: "rgba(225,225,225,0.2)",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
    elevation: 2,
  },
  signOut: {
    backgroundColor: "#AD0000",
    marginTop: 10,
  },
});
