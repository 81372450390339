import { Feather, Ionicons, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import { Box, Button, Heading, Input, VStack, Icon } from "native-base";
import * as React from "react";
import { ActivityIndicator, StyleSheet, Image } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { View, Text, Divider } from "../components/Styled";
import { useAuth } from "../contexts/auth/hooks";
import {
  useStartupNotifications,
  useStartupUserActions,
} from "../contexts/startup/hooks";
import { useTranslation } from "../contexts/translations";
import * as API from "../services/api";
import { LIGHT_MODE } from "../constants/Colors";
import { useTheme } from "../contexts/theme/hooks";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { MainStackParams } from "../navigation/types";
import useIsMobile from "../hooks/useIsMobile";
type Notification = {
  id: string;
  title: string;
  message: string;
  statusRead: boolean;
};

export default function NotificationMessage() {
  const { t } = useTranslation();
  const { refreshAuth } = useAuth();
  const { unreadCount = 0 } = useStartupNotifications();
  const { markNotificationRead, deleteNotification } = useStartupUserActions();
  const [expanded, setExpanded] = React.useState<null | string>();
  const [data, setData] = React.useState<Notification[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [theme] = useTheme();
  const [unreadColor, setUnreadColor] = React.useState<null | string>();
  const navigation = useNavigation<StackNavigationProp<MainStackParams>>();
  const [textSearched, setTextSearched] = React.useState("");
  const isMobile = useIsMobile()

  React.useEffect(() => {
    (async () => {
      try {
        await refreshAuth();
        const res = await API.getNotifications();
        setData(res.data);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        setError(err?.response?.data || true);
        console.warn(
          "Failed requesting notifications",
          err?.response?.data || err
        );
      }
    })();
  }, []);
  
  React.useEffect(() => {
    if(theme === LIGHT_MODE) {
      setUnreadColor("#F0F0F0")
    }else{
      setUnreadColor("#2D2D2D")
    }
  },[]);

  async function handleExpand(id: string, statusRead: boolean, message: string, title: string) {
    // if (expanded === id) {
    //   setExpanded(null);
    //   return;
    // }

    // setExpanded(id);
    navigation.navigate("NotificationMessage", {title: title, message: message})

    if (!statusRead) {
      setData((currentData) =>
        currentData.map((item) => ({
          ...item,
          statusRead: item.id === id || item.statusRead,
        }))
      );
      await markNotificationRead(id);
    }
  }

  async function handleDeleteNotification(id: string) {
    setIsLoading(true);
    await deleteNotification(id);
    const res = await API.getNotifications();
    setData(res.data);
    setIsLoading(false);
  }

  if (isLoading) {
    return (
      <View
        $transparent
        height={300}
        alignItems="center"
        justifyContent="center"
      >
        <ActivityIndicator
          accessible
          accessibilityLabel={t("accessibility.loading", {
            text: t("tabs.notifications"),
          })}
        />
      </View>
    );
  }

  if (error) {
    return (
      <View
        $transparent
        height={300}
        alignItems="center"
        justifyContent="center"
      >
        <Text>{t("notifications.error")}</Text>
      </View>
    );
  } 

  // const handleChange = (searchText: Field, value: string | number) => {
  //   setSearchText((current) => ({
  //     ...current,
  //     searchText: value,
  //   }));
  // };

  const SearchBar = ({ value, onChange }) => {
    const [searchText, setSearchText] = React.useState(`${value}`);
    return(
      <Input 
        placeholder={t("notifications.search")} 
        width="100%"
        borderRadius="4" 
        py="3" 
        px="1" 
        fontSize="14"
        InputLeftElement={<Icon m="2" ml="3" size="6" color="gray.400" as={<MaterialIcons name="search" />} />} 
        InputRightElement={<Icon m="2" mr="3" size="6" color="gray.400" as={<MaterialIcons name="mic" />} />} 
        value={searchText}
        marginBottom={1}
        onChangeText={(v) => setSearchText(v)}
        onBlur={() => onChange(searchText)}
        returnKeyType="done"
      />
    )
  }

  function alldatalist(){
    if(textSearched){
      return data.filter(x => String(x.title?.toLowerCase()).includes(textSearched?.toLowerCase()))
    }else{
      return data;
    }
  }

  return (
    <View $fullscreen>
      <View $transparent space={isMobile ? 10: 0}>
        <SearchBar value={textSearched} onChange={(text: any) => setTextSearched(text)}/>
        {data?.length > 0 ? (
          alldatalist().map(({ id, title, message, statusRead }, index) => (
            <View key={id} bgColor={!statusRead && unreadColor} spaceBottom={1}>
              <TouchableOpacity
                style={styles.btnTouch}
                accessible={false}
                onPress={() => handleExpand(id, statusRead, message, title)}
              >
                {/* <View justifyContent="center" $transparent>
                  <Image
                    style={[styles.logo]}
                    source={require("../assets/images/card-exchange.png")}
                  />
                </View> */}
                <View $transparent flexShrink={1} justifyContent="center" spaceHorizontal={10}>
                  <Text
                    $bold
                    size={16}
                    accessibilityLabel={t("accessibility.notifications.notification", {
                      count: statusRead ? 0 : 1,
                      title,
                    })}
                  >
                    {title}
                  </Text>
                  <Text numberOfLines={2} size={14}>{message}</Text>
                </View>
              </TouchableOpacity>
              <Divider $fullSize spaceVertical={1} bgColor="#b0b0b0"/>
            </View>
          ))
        ) : (
          <Text $textAlign="center">{t("notifications.empty")}</Text>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  deleteBtn: {
    backgroundColor: "#AD0000",
    marginTop: 10,
    paddingHorizontal: 5,
  },
  logo:{
    width: 50,
    height: 50,
    resizeMode: "contain",
  },
  btnTouch:{
    height:80,
    flexDirection:"row",
    borderRadius:3,
    margin:5,
    backgroundColor:  "transparent" 
  },
  inputSearch: {
    fontSize: 20,
    marginLeft: 10,
    width: "90%",
  },
  searchContainer: {
    // margin: 15,
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "row",
    // width: "90%",

  },
});
