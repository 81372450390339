import { Heading, ScrollView } from "native-base";
import * as React from "react";
import { StyleSheet, Platform, SafeAreaView } from "react-native";
import { Button, Divider, Text, View } from "../components/Styled";
import { useTranslation } from "../contexts/translations";
import ClientBrand from "../components/ClientBrand";
import useIsMobile from "../hooks/useIsMobile";
import * as Cache from "../services/cache";
import { useStartupConfig } from "../contexts/startup/hooks";
import WizardHeader from "../components/WizardHeader";
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { useTheme } from "../contexts/theme/hooks";
import { DARK_MODE } from "../constants/Colors";

function ThankYouScreen({children}: {children: JSX.Element}){
    const { t } = useTranslation();
    const isMobile = useIsMobile();
    const { customThankYouMessage, showCustomThankYouMessage } = useStartupConfig();
    const [theme] = useTheme();
    const [hasSubmittedID, setHasSubmittedID] = React.useState<boolean>(false);
    const [hasSubmittedPhoto, setHasSubmittedPhoto] = React.useState<boolean>(false);
    const [complete, setComplete] = React.useState<boolean>(false);
    let submittedId;
    let submittedPhoto;

    let handleComplete =()=> {
        Cache.remove("subId");
        Cache.remove("subPhoto");
        setComplete(true);
    }

    const SubHeadView= (props:any) => {
        return (
            <ScrollView contentContainerStyle={{ flex: 1 }} showsVerticalScrollIndicator={false}>
                <View $transparent
                    spaceVertical={hp("9%")}
                    flexGrow={1}
                    width={!isMobile ? wp("25%") : 300}
                    alignSelf="center"
                >
                    {
                        showCustomThankYouMessage && customThankYouMessage ?
                            <View $transparent spaceTop={hp("5%")}>
                                <Text $responsive size={2} $textAlign="center">{customThankYouMessage}</Text>
                            </View> :
                            <>
                                <View $transparent spaceTop={hp("5%")} spaceBottom={hp("10%")}>
                                    <Text $responsive size={2} $textAlign="center">
                                        {props.subID ? t("message_label.review_submitted_id") : props.subPhoto ? t("message_label.review_submitted_photo") : t("message_label.review_submitted")}
                                    </Text>
                                </View>
                                <Text $responsive size={1.7} $textAlign="center">{t("message_label.thank_you")}</Text>
                            </>
                    }

                </View>
            </ScrollView>
        )
    }

    const BottomButtonView = () => {
        return (
            <>
                <Divider $fullSize color={theme === DARK_MODE ? "#5c5b5b" : "#ddd"} />
                <View spaceVertical={hp("0.5%")} $transparent flexDirection="row" width={"100%"}>
                    <Button
                        variant="unstyled"
                        alignItems="center"
                        justifyContent="center"
                        $fullWidth
                        height={hp("5%")}
                    >
                        <Text color="grey" $responsive size={1.7}>{t("auth.btn_prev")}</Text>
                    </Button>
                    <Divider $vertical />
                    <Button
                        variant="unstyled"
                        alignItems="center"
                        justifyContent="center"
                        $fullWidth
                        height={hp("5%")}
                        onPress={handleComplete}
                    >
                        <Text color="#2969cf" $responsive size={1.7}>{t("auth.finish")}</Text>
                    </Button>
                </View>
            </>
        )
    }

    React.useEffect(()=>{
        (async () => {
            submittedId = await Cache.get("subId");

            if(submittedId) {
                setHasSubmittedID(true);
            }

            submittedPhoto = await Cache.get("subPhoto");
            if(submittedPhoto) {
                setHasSubmittedPhoto(true);
            } 
        })();
    },[]);


    return  hasSubmittedID && !complete && !hasSubmittedPhoto ? isMobile ?
        <View $fullscreen>
            <SafeAreaView style={{ alignItems: "center", flex: 1 }}>
                <ClientBrand />
                <Text accessibilityRole="header" $bold $responsive size={2.5}>{t("header.thank_you")}</Text>
                <SubHeadView subID />
                <BottomButtonView />
            </SafeAreaView>
        </View> :
        <View $fullscreen bgColor={theme === DARK_MODE ? "#313131" : "#F7F7F7"}>
            <WizardHeader />
            <View bgColor={theme === DARK_MODE ? "black" : "white"}
                spaceVertical={hp("5%")}
                justifyContent="center"
                borderWidth={1}
                borderColor={theme === DARK_MODE ? "#5c5b5b" : "#ddd"}
                width={wp("50%")}
                alignSelf="center"
                style={{ flex: 1 }}
            >
                <View $transparent spaceTop={hp("10%")}>
                    <Text accessibilityRole="header" $bold $textAlign="center" $responsive size={2.5}>{t("header.thank_you")}</Text>
                </View>
                <SubHeadView subID />
                <View $transparent alignItems="center">
                    <BottomButtonView />
                </View>
            </View>
        </View>
        : hasSubmittedPhoto && !hasSubmittedID && !complete ? isMobile ?
        <View $fullscreen>
            <SafeAreaView style={{ alignItems: "center", flex: 1 }}>
                <ClientBrand />
                <Text accessibilityRole="header" $bold $responsive size={2.5}>{t("header.thank_you")}</Text>
                <SubHeadView subPhoto />
                <BottomButtonView />
            </SafeAreaView>
        </View> :
        <View $fullscreen bgColor={theme === DARK_MODE ? "#313131" : "#F7F7F7"}>
            <WizardHeader />
            <View bgColor={theme === DARK_MODE ? "black" : "white"}
                spaceVertical={hp("5%")}
                justifyContent="center"
                borderWidth={1}
                borderColor={theme === DARK_MODE ? "#5c5b5b" : "#ddd"}
                width={wp("50%")}
                alignSelf="center"
                style={{ flex: 1 }}
            >
                <View $transparent spaceTop={hp("10%")}>
                    <Text accessibilityRole="header" $bold $textAlign="center" $responsive size={2.5}>{t("header.thank_you")}</Text>
                </View>
                <SubHeadView subPhoto />
                <View $transparent alignItems="center">
                    <BottomButtonView />
                </View>
            </View>
        </View> : hasSubmittedPhoto && hasSubmittedID && !complete ? isMobile ? 
              <View $fullscreen>
              <SafeAreaView style={{ alignItems: "center", flex: 1 }}>
                  <ClientBrand />
                  <Text accessibilityRole="header" $bold $responsive size={2.5}>{t("header.thank_you")}</Text>
                  <SubHeadView />
                  <BottomButtonView />
              </SafeAreaView>
          </View> :
          <View $fullscreen bgColor={theme === DARK_MODE ? "#313131" : "#F7F7F7"}>
              <WizardHeader />
              <View bgColor={theme === DARK_MODE ? "black" : "white"}
                  spaceVertical={hp("5%")}
                  justifyContent="center"
                  borderWidth={1}
                  borderColor={theme === DARK_MODE ? "#5c5b5b" : "#ddd"}
                  width={wp("50%")}
                  alignSelf="center"
                  style={{ flex: 1 }}
              >
                  <View $transparent spaceTop={hp("10%")}>
                      <Text accessibilityRole="header" $bold $textAlign="center" $responsive size={2.5}>{t("header.thank_you")}</Text>
                  </View>
                  <SubHeadView />
                  <View $transparent alignItems="center">
                      <BottomButtonView />
                  </View>
              </View>
          </View>
        : children

}

export default ThankYouScreen;

const styles = StyleSheet.create({
    pressableBtn: {
        flex: Platform.OS === "android" || Platform.OS === "ios" ? 1 : undefined,
        height: 40,
        justifyContent: 'center',
        alignItems: 'center'
    },
    headingText: {
        fontWeight: "bold", textAlign: "center"
    }
});
