import { Button } from "native-base";
import * as React from "react";
import { StyleSheet, ActivityIndicator, Alert } from "react-native";
import { useTranslation } from "../contexts/translations";
import { useNavigation } from "@react-navigation/native";
import { HStack, Pressable, Toast } from "native-base";
import { View, Label, Divider, Text, Image } from "../components/Styled";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useFeatureFlags } from "../contexts/feature-flags";
import * as API from "../services/api";
import { useRoute } from "@react-navigation/native";
import { useAuth } from "../contexts/auth/hooks";
import {
  useDigitalCard,
  useStartupOrganization,
  useStartupUser,
  useStartupUserActions,
} from "../contexts/startup/hooks";
import {
  ACTIVATED,
  BLOCKED,
  INITIALIZED,
  IN_PRODUCTION,
  PRINTED,
  WITHDRAWN,
  APPROVED,
  EXPIRED,
} from "../constants/card-status";
import { useTheme } from "../contexts/theme/hooks";
import { DARK_MODE } from "../constants/Colors";
import { ICard } from "../interfaces/card.interface";
import DIGITAL_SYSTEM, {
  SALTO,
} from "../contexts/startup/constants/digitalSystem";
import OrigoSDKModule from "../OrigoSDKModuleInterface";

var digitalCard = {} as ICard;

export default function CredentialActionScreen() {
  const { t } = useTranslation();
  const data = useStartupUser();
  const navigation = useNavigation();
  const route = useRoute();
  const { MANAGE_CARD } = useFeatureFlags();
  const [canRequestCard, setCanRequestCard] = React.useState(MANAGE_CARD);
  const { refreshAuth } = useAuth();
  const [requestCardLoading, setRequestCardLoading] = React.useState(false);
  const [activateLoading, setActivateLoading] = React.useState(false);
  const [blockLoading, setBlockLoading] = React.useState(false);
  const { reload, updateDigitalCard } = useStartupUserActions();
  const userCard = route.params?.userCard || null;
  const [theme] = useTheme();
  const user = useStartupUser();
  const digitalCard = useDigitalCard();

  async function createCard() {
    setRequestCardLoading(true);
    try {
      await refreshAuth();
      await API.createCard({ cardholder: data?.cardholderId || "" });
      await reload();
      setRequestCardLoading(false);
      setCanRequestCard(false);
      Toast.show({
        accessible: true,
        accessibilityLabel: t("digital_id.request_card.success"),
        duration: 8000,
        render: () => (
          <View bgColor="green" style={{ padding: 10 }}>
            <Text color="white">{t("digital_id.request_card.success")}</Text>
          </View>
        ),
      });
      navigation.goBack();
    } catch (error) {
      setRequestCardLoading(false);
      Toast.show({
        accessible: true,
        accessibilityLabel: t("digital_id.request_card.error"),
        duration: 8000,
        render: () => (
          <View bgColor="orange" style={{ padding: 10 }}>
            <Text color="white">{t("digital_id.request_card.error")}</Text>
          </View>
        ),
      });
      console.warn("Failed requesting a card", error.response.data);
    }
  }

  async function updateCardStatus(cardId: string, value: boolean, card: ICard) {
    try {
      var status = value ? ACTIVATED : BLOCKED;

      if (card?.type?.category?.isDigital) {
        updateDigitalCard({
          type: card?.type?.category?.digitalCredentialSystem,
          status: status,
        });
      }

      await refreshAuth();

      await API.updateCardStatus(
        card.id,
        status,
        card?.type.id,
        card?.cardholder,
        user!.personId!,
        user!.profiles![0].profile.id,
        new Date(),
        new Date(new Date().setFullYear(new Date().getFullYear() + 1))
      );
      
      await reload();
      setActivateLoading(false);
      setBlockLoading(false);
      Toast.show({
        accessible: true,
        accessibilityLabel: t("digital_id.update_status.success"),
        duration: 8000,
        render: () => (
          <View bgColor="green" style={{ padding: 10 }}>
            <Text color="white">{t("digital_id.update_status.success")}</Text>
          </View>
        ),
      });
    } catch (error) {
      Toast.show({
        accessible: true,
        accessibilityLabel: t("digital_id.update_status.error"),
        duration: 8000,
        render: () => (
          <View bgColor="orange" style={{ padding: 10 }}>
            <Text color="white">{t("digital_id.update_status.error")}</Text>
          </View>
        ),
      });
      setActivateLoading(false);
      setBlockLoading(false);
      console.warn("Failed updating card status", error.response.data);
    }
  }

  async function checkActivatedCard(card: ICard, value: boolean) {
    var activatedCard = undefined;
    var blockedCard = undefined;

    const cardsObj = await API.getCardCredentials(user?.cardholderId);
    const cardsArrPerType = cardsObj.data[0].cardsPerType;

    cardsArrPerType.map((card: any) => {
      var isActivated = Array.from(card.cards).find(
        (s: any) =>
          s?.type?.category?.digitalCredentialSystem === SALTO &&
          s?.status === ACTIVATED
      );

      var isBlocked = Array.from(card.cards).find(
        (s: any) =>
          s?.type?.category?.digitalCredentialSystem === SALTO &&
          s?.status === BLOCKED
      );
      if (isBlocked) {
        blockedCard = isBlocked;
      }

      if (isActivated) {
        activatedCard = isActivated;
      }
    });

    if (blockedCard && activatedCard && value) {
      Alert.alert(
        `${t("id_settings.activate_credential_title")}`,
        `${t("id_settings.activate_credential_body")}`,
        [
          {
            text: `${t("profile.button_no")}`,
            onPress: () => {
              setActivateLoading(false);
              return;
            },
            style: "cancel",
          },
          {
            text: `${t("profile.button_yes")}`,
            onPress: async () => {
              await updateCardStatus(activatedCard!.id, !value, activatedCard!);
              navigation.goBack();
              await updateCardStatus(card.id, value, card);
            },
          },
        ]
      );
    } else {
      updateCardStatus(card.id, value, card);
      navigation.goBack();
    }
  }

  function loadComponent() {
    return (
      <ActivityIndicator
        accessible
        accessibilityLabel={t("accessibility.loading", {
          text: "",
        })}
        color="white"
      />
    );
  }

  return (
    <View $transparent height={300}>
      <View spaceBottom={10} $transparent>
        <Text $textAlign="center" $bold size={14}>
          {route.params?.name}
        </Text>
      </View>
      <Divider $fullSize spaceVertical={1} />
      {userCard?.type?.category?.digitalCredentialSystem ===
        "hid_mobile_access" && (
        <View
          $transparent
          width={60}
          height={35}
          alignSelf="center"
          style={{ marginTop: 10 }}
        >
          <Image
            style={{
              flex: 1,
              ...StyleSheet.absoluteFillObject,
              resizeMode: "contain",
              width: 60,
              height: 35,
            }}
            source={
              theme === DARK_MODE
                ? require("../assets/images/seos-logo/SEOS-by-ASSA-ABLOY-RGB-White.png")
                : require("../assets/images/seos-logo/SEOS-by-ASSA-ABLOY-RGB-Black.png")
            }
          />
        </View>
      )}
      {userCard !== null ? (
        userCard.status === APPROVED ||
        (userCard.status == BLOCKED && userCard.blockReason === null) ? (
          <Button
            marginTop={2}
            height={12}
            onPress={() => {
              setActivateLoading(true);
              checkActivatedCard(userCard, true);
            }}
            disabled={activateLoading}
            style={
              activateLoading
                ? {
                    //@ts-ignore
                    cursor: "default",
                  }
                : null
            }
          >
            {activateLoading ? loadComponent() : t("h_id.activate")}
          </Button>
        ) : userCard.status == ACTIVATED ? (
          <Button
            marginTop={2}
            height={12}
            onPress={() => {
              setBlockLoading(true);
              checkActivatedCard(userCard, false);
            }}
            disabled={blockLoading}
            style={
              blockLoading
                ? {
                    //@ts-ignore
                    cursor: "default",
                  }
                : null
            }
          >
            {blockLoading ? loadComponent() : t("h_id.block")}
          </Button>
        ) : (
          <></>
        )
      ) : canRequestCard ? (
        <View $transparent justifyContent="center" space={20}>
          <Text $textAlign="center">
            {t("digital_id.card.do_not_have_card")}
          </Text>
          <View space={20} />
          <Button onPress={createCard} isDisabled={requestCardLoading}>
            {requestCardLoading
              ? loadComponent()
              : t("digital_id.card.request_card")}
          </Button>
        </View>
      ) : null}
    </View>
  );
}

const styles = StyleSheet.create({
  btn: {
    marginVertical: 5,
  },
  pressableMennu: {
    marginVertical: 12,
  },
});
