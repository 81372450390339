import React, { useEffect, ReactNode } from "react";
import { Platform } from "react-native";
import * as ExpoImagePicker from "expo-image-picker";
import { Toast, Button } from "native-base";
import { ImageInfo } from "expo-image-picker/build/ImagePicker.types";
import { useTranslation } from "../contexts/translations";
import { View, Text } from "./Styled";
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';

type ImagePicker = {
  onCancel?: () => void;
  onSelect: (image: ImageInfo) => void;
  children?: ReactNode;
};

const imageLibraryOptions: ExpoImagePicker.ImagePickerOptions = {
  mediaTypes: ExpoImagePicker.MediaTypeOptions.Images,
  allowsEditing: false,
  aspect: [4, 3],
  quality: 1,
  base64: true,
  exif: true,
};

function isImage(url: any) {
  return /\.(jpg|jpeg|png|tiff|gif|bmp)$/.test(url);
}

const validImageTypes = ['image/gif','image/tiff','image/bmp', 'image/jpg', 'image/jpeg', 'image/png'];


export default function ImagePicker({
  onCancel,
  onSelect,
  children,
}: ImagePicker) {
  const { t } = useTranslation();
  const pickImage = async () => {
    const result = await ExpoImagePicker.launchImageLibraryAsync(
      imageLibraryOptions
    );

    if (!result.cancelled) {
      if (Platform.OS === "web") {
        var url = result.assets[0].uri;
        var part = url.split(';')[0];
        var filetype = part.split(":")[1]
        if (validImageTypes.includes(filetype)) {
          onSelect(result.assets[0]);
        } else {
          Toast.show({
            accessible: true,
            accessibilityLabel: t("identity_upload.media_type_error"),
            duration: 8000,
            render:() => <View bgColor="red" style={{padding:10}}>
              <Text color="white">{t("identity_upload.media_type_error")}</Text>
            </View>
          })
        }
      } else {
        if (isImage(result.uri)) {
          onSelect(result);
        } else {
          Toast.show({
            accessible: true,
            accessibilityLabel: t("identity_upload.media_type_error"),
            duration: 8000,
            render:() => <View bgColor="red" style={{padding:10}}>
              <Text color="white">{t("identity_upload.media_type_error")}</Text>
            </View>
          })
        }
      }
    } else if (onCancel) {
      onCancel();
    }
  };

  return (
    <Button
      flex={1} style={{ marginRight: wp("0.2%")  }}
      colorScheme={"primary"}
      onPress={pickImage}
    >
      <Text color="white" $responsive size={1.5}>{t("photo.btn_upload")}</Text>
    </Button>

  );
}
