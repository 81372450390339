import * as React from "react";
import { Platform, StyleSheet } from "react-native";
import styled from "styled-components/native";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';

import {
  useStartupNotifications,
  useStartupUser,
  useStartupConfig,
  useStartupOrganization
} from "../contexts/startup/hooks";
import { Avatar, Text, Icon, View, Image } from "./Styled";
import { getBase64 } from "../utils";
import useIsMobile from "../hooks/useIsMobile";
import { useTranslation } from "../contexts/translations";
import { MainStackParams } from "../navigation/types";
import * as AuthSession from "expo-auth-session";
import jwtDecode from "jwt-decode";
import * as Cache from "../services/cache";

const Wrapper = styled.SafeAreaView<{ shadow?: boolean }>`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => props.theme.bgColor};
  ${(props) => props.shadow && `box-shadow: 1px 0 5px rgba(0,0,0,0.25)`}
`;

const FlexRow = styled.View`
  flex-direction: row;
`;

const Button = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
  padding: 10px;
`;

const ProfileView = styled.View`
  flex-direction: row;
  align-items: center;
  padding: 10px;
`;

const ThemedIcon = styled(Icon)`
  color: ${(props) => props.theme.textColor};
`;

const getName = ({ firstName = "", lastName = "" } = {}) =>
  firstName && lastName
    ? `${firstName.substring(0, 1)}. ${lastName.split(" ")[0]}`
    : "Unknown";

export default function Header() {
  const navigation = useNavigation<StackNavigationProp<MainStackParams>>();
  const user = useStartupUser() || {};
  const { unreadCount = 0 } = useStartupNotifications();
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const { showIdButtonInStand } = useStartupConfig();
  const { logo } = useStartupOrganization();

  return (
    <Wrapper shadow={!isMobile}>
      <View $transparent spaceHorizontal={logo && logo !== "" ? hp("2%") : undefined} width={250} height={70}>
        {
          logo && logo !== "" ? (
            <Image
              style={{ flex: 1, ...StyleSheet.absoluteFillObject, resizeMode: "contain", width:200 , height:70 }}
              source={{ uri: logo }}
            />
          ) :
            <Image
              style={{ flex: 1, ...StyleSheet.absoluteFillObject, resizeMode: "contain", width:250 , height:70 }}
              source={require("../assets/images/cardexchange-logo.png")}
            />
        }
        {/* <ProfileView
          // onPress={() => navigation.navigate("Profile")}
          // accessibilityLabel={t("accessibility.header.profile", {
          //   name: getName(user),
          // })}
        >
          {user.photo ? (
            <Avatar source={{ uri: getBase64(user.photo) }} />
          ) : (
            <ThemedIcon as={MaterialIcons} name="account-circle" />
          )}
          <View spaceLeft={10} />
          <Text size={24} $bold numberOfLines={1}>
            {getName(user)}
          </Text>
        </ProfileView> */}
      </View>
      <FlexRow>
        {
          showIdButtonInStand &&
          <Button
            onPress={() => navigation.navigate("IdSettings")}
            accessible
            accessibilityLabel={t("accessibility.header.id")}
            accessibilityRole="button"
          >
            <ThemedIcon as={MaterialCommunityIcons} name="card-account-details" />
            {!isMobile && <Text $bold> {t("tabs.id")}</Text>}
          </Button>
        }
        <Button
          onPress={() => navigation.navigate("Notifications")}
          accessible
          accessibilityLabel={t("accessibility.header.notifications", {
            count: unreadCount,
          })}
          accessibilityRole="button"
        >
          {isMobile && unreadCount > 0 && <Text>{unreadCount}</Text>}
          <ThemedIcon as={MaterialCommunityIcons} name="email-outline" />
          {!isMobile && (
            <Text $bold>
              {" "}
              {t("tabs.notifications")}
              {unreadCount > 0 && ` (${unreadCount})`}
            </Text>
          )}
        </Button>
        <Button
          onPress={() => navigation.navigate("Settings")}
          accessible
          accessibilityLabel={t("accessibility.header.settings")}
          accessibilityRole="button"
        >
          <ThemedIcon as={MaterialIcons} name="settings" />
          {!isMobile && <Text $bold> {t("tabs.settings")}</Text>}
        </Button>
      </FlexRow>
    </Wrapper>
  );
}
