import * as React from "react";
import * as Cache from "../services/cache";
import {
  ActivityIndicator,
  Platform,
  StyleSheet,
  SafeAreaView,
} from "react-native";
import {
  Checkbox,
  FormControl,
  Switch,
  HStack,
  Pressable,
  ScrollView,
  Icon,
} from "native-base";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { CameraCapturedPicture } from "expo-camera";
import { ImageInfo } from "expo-image-picker/build/ImagePicker.types";
import { FontAwesome, MaterialCommunityIcons } from "@expo/vector-icons";

import Camera from "../components/Camera";
import { CardHolderContext } from "../contexts/card-holder";
import ImagePicker from "../components/ImagePicker";
import { View, Text, Divider, Button, Image } from "../components/Styled";
import { Icon as NewIcon } from "../components/Styled";
import { useTranslation } from "../contexts/translations";
import useKeyboardEvent from "../hooks/useKeyboardEvent";
import useFocusTrap from "../hooks/useFocusTrap";
import {
  useStartupConfig,
  useStartupUser,
  useStartupUserActions,
} from "../contexts/startup/hooks";
import { MainStackParams } from "../navigation/types";
import ClientBrand from "../components/ClientBrand";
import useIsMobile from "../hooks/useIsMobile";
import { useRoute } from "@react-navigation/native";
import ThankYouScreen from "./ThankYouScreen";
import WizardHeader from "../components/WizardHeader";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import { useTheme } from "../contexts/theme/hooks";
import { DARK_MODE } from "../constants/Colors";
import { useAuth } from "../contexts/auth/hooks";
import IdentityUploadScreen from "./IndentityUploadScreen";

const CAMERA = "camera";
const EXIT_SCREEN = "exit";
const SHOW_THANK_YOU = "SHOW_THANK_YOU";
const NAVIGATE = "navigate";

function ProfilePhotoScreen({ children }: { children: JSX.Element }) {
  var navigation:
    | StackNavigationProp<MainStackParams, keyof MainStackParams, undefined>
    | undefined;
  const { t } = useTranslation();
  const { identityExists, photo } = useStartupUser() || {};

  const { updatePhoto, reload: reloadStartup } = useStartupUserActions();
  const {
    showApprovePhoto,
    photoGuidelinesMessage,
    samplePhotoGuidelines,
    photoMessageToDisplay,
  } = useStartupConfig();
  const [approved, setApproved] = React.useState(
    () => showApprovePhoto === null
  );
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [errmessage, setErrMessage] = React.useState("");
  const [showPicker, setShowPicker] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isExitingWithRefresh, setIsExitingWithRefresh] = React.useState(false);

  const [active, setActive] = React.useState(
    children && photo ? EXIT_SCREEN : ""
  );
  const [, { reload: reloadCardHolderInfo }] =
    React.useContext(CardHolderContext);

  const [picture, setPicture] = React.useState<
    CameraCapturedPicture | ImageInfo
  >();
  const isMobile = useIsMobile();
  const [theme] = useTheme();
  const user = useStartupUser();
  const { reload } = useStartupUserActions();
  const { signOut } = useAuth();

  if (!children) {
    navigation = useNavigation<StackNavigationProp<MainStackParams>>();
    const [tabKey, tabHandler] = useFocusTrap(navigation);

    useKeyboardEvent({
      Escape: navigation.goBack,
      [tabKey]: tabHandler,
    });
  }

  React.useEffect(() => {
    if (user?.photo) setShowPicker(true);
    if (user?.archive) {
      setActive(NAVIGATE);
    }
    const interval = setInterval(() => {
      reload();
      if (user?.archive) {
        setActive(NAVIGATE);
      }
    }, 3600000);
    return () => clearInterval(interval);
  }, [user]);

  function handleSelect(file: CameraCapturedPicture | ImageInfo) {
    setIsLoading(true);
    updatePhoto(file, approved, user?.personId).then(async (value: any) => {
      if (value === "success") {
        setIsSubmitted(true);
      } else {
        setErrMessage(value);
      }
      await reloadStartup();
      await reloadCardHolderInfo();
      setIsLoading(false);
    });
  }

  async function handleExit() {
    if (isSubmitted) {
      setActive(SHOW_THANK_YOU)
      await Cache.set("subPhoto", "true");
    } 
    
    if (!children) {
      if (isMobile) {
        navigation?.navigate("DigitalId");
      } else {
        navigation?.navigate("Profile");
      }
    } else {
      setActive(EXIT_SCREEN);
    }
  }

  function gotoCamera() {
    const ImagePicker = require("react-native-image-crop-picker");
    ImagePicker.openCamera({
      includeBase64: true,
      compressImageQuality: 1,
      width: 250,
      height: 300,
    }).then(
      (image: {
        mime: any;
        path: any;
        height: any;
        width: any;
        size: any;
        data: any;
      }) => {
        setPicture({
          mime: image.mime,
          uri: image.path,
          height: image.height,
          width: image.width,
          size: image.size,
          base64: image?.data,
          fullUri: false,
          originalUri: image.path,
        });
      }
    );
  }

  function cropImage() {
    try {
      const ImagePicker = require("react-native-image-crop-picker");
      ImagePicker.openCropper({
        path: picture?.originalUri,
        includeBase64: true,
        freeStyleCropEnabled: false,
        cropping: true,
        compressImageQuality: 1,
        width: 250,
        height: 300,
      }).then(
        (image: {
          mime: any;
          path: any;
          height: any;
          width: any;
          size: any;
          data: any;
        }) => {
          setPicture({
            mime: image.mime,
            uri: image.path,
            height: image.height,
            width: image.width,
            size: image.size,
            base64: image?.data,
            fullUri: false,
            originalUri: picture?.originalUri,
          });
        }
      );
    } catch (error) {
      console.log("error", error);
    }
  }

  function gotoImagePicker() {
    const ImagePicker = require("react-native-image-crop-picker");
    ImagePicker.openPicker({
      includeBase64: true,
      compressImageQuality: 1,
      width: 250,
      height: 300,
      mediaType: "photo",
    }).then(
      (image: {
        mime: any;
        path: any;
        height: any;
        width: any;
        size: any;
        data: any;
      }) => {
        setPicture({
          mime: image.mime,
          uri: image.path,
          height: image.height,
          width: image.width,
          size: image.size,
          base64: image?.data,
          fullUri: false,
          originalUri: image.path,
        });
      }
    );
  }

  const NoAccessView = () => {
    return (
      <ScrollView
        contentContainerStyle={{ flex: 1 }}
        showsVerticalScrollIndicator={false}
        backgroundColor={theme === DARK_MODE ? "black" : "white"}
      >
        <View
          $transparent
          spaceVertical={hp("9%")}
          flexGrow={1}
          width={!isMobile ? wp("23%") : wp("80%")}
          justifyContent={!isMobile ? "center" : undefined}
          alignSelf="center"
        >
          <Text $responsive size={2} $textAlign="center">
            {t("startup_message.no_access")}
          </Text>
        </View>
      </ScrollView>
    );
  };

  const ButtonNoAccess = () => {
    return (
      <Button
        variant="ghost"
        rightIcon={<Icon name="sign-out" as={FontAwesome} />}
        onPress={signOut}
        accessible
        accessibilityLabel={t("accessibility.signOut")}
        accessibilityRole="button"
      >
        {t("settings.sign_out")}
      </Button>
    );
  };

  if (active === CAMERA) {
    return (
      <Camera
        onCancel={() => setActive("")}
        onSelect={(picture) => {
          picture["fullUri"] = true;
          setPicture(picture);
          setActive("");
        }}
      />
    );
  } else if (active === SHOW_THANK_YOU) {
    return (
      <ThankYouScreen
        onComplete={() => {
          if (children) {
            setActive(EXIT_SCREEN);
          } else {
            if (isMobile) {
              navigation?.navigate("DigitalId");
            } else {
              navigation?.navigate("Profile");
            }
          }
        }}
      />
    );
  } else if (active === NAVIGATE) {
    return isMobile ? (
      <SafeAreaView
        style={{
          alignItems: "center",
          flex: 1,
          backgroundColor: theme === DARK_MODE ? "black" : "white",
        }}
      >
        <ClientBrand />
        <NoAccessView />
        <ButtonNoAccess />
      </SafeAreaView>
    ) : (
      <View $fullscreen bgColor={theme === DARK_MODE ? "#313131" : "#eee"}>
        <WizardHeader />
        <View
          spaceVertical={hp("5%")}
          justifyContent="center"
          borderWidth={1}
          borderColor={theme === DARK_MODE ? "#5c5b5b" : "lightgrey"}
          width={wp("50%")}
          alignSelf="center"
          style={{ flex: 1 }}
        >
          <NoAccessView />
          <View $transparent alignItems="center">
            <ButtonNoAccess />
          </View>
        </View>
      </View>
    );
  } else if (active === EXIT_SCREEN) {
    return children;
  }

  const SubHeadView = () => {
    return (
      <ScrollView
        contentContainerStyle={{ flex: 1 }}
        showsVerticalScrollIndicator={false}
      >
        <View
          $transparent
          spaceVertical={hp("9%")}
          flexGrow={1}
          width={!isMobile ? wp("23%") : wp("80%")}
          alignSelf="center"
        >
          {showPicker ? (
            <>
              <Pressable
                height={hp("35%")}
                width={!isMobile ? wp("15%") : wp("60%")}
                justifyContent="center"
                alignItems="center"
                alignSelf="center"
                disabled={!picture}
                style={{
                  borderWidth: 1,
                  borderColor: theme === DARK_MODE ? "#5c5b5b" : "#ddd",
                }}
                onPress={() => {
                  if (Platform.OS !== "web") {
                    cropImage();
                  }
                }}
              >
                {picture ? (
                  <Image
                    style={{
                      flex: 1,
                      ...StyleSheet.absoluteFillObject,
                      resizeMode: "cover",
                    }}
                    source={{
                      uri: picture.fullUri
                        ? picture.uri
                        : `data:${picture.mime};base64,${picture.base64}`,
                    }}
                  />
                ) : (
                  <NewIcon as={FontAwesome} name="user" size={hp("20%")} />
                )}
              </Pressable>
              <View
                $transparent
                alignSelf="center"
                width={!isMobile ? wp("35%") : wp("80%")}
              >
                {!isSubmitted && Platform.OS !== "web" && picture && (
                  <View $transparent spaceTop={hp("2%")}>
                    <Text $textAlign="center" $responsive size={1.5}>
                      {t("message_label.tap_and_crop")}
                    </Text>
                  </View>
                )}
                {isLoading ? (
                  <View $transparent spaceVertical={hp("8%")}>
                    <Text $responsive size={1.5} $textAlign="center">
                      {t("identity_upload.loading_message")}
                    </Text>
                  </View>
                ) : isSubmitted || errmessage !== "" ? (
                  <View $transparent spaceVertical={20}>
                    {errmessage !== "" ? (
                      <Text
                        $textAlign="center"
                        $bold
                        $responsive
                        size={1.7}
                        color="red"
                      >
                        <MaterialCommunityIcons name="close" size={hp("3%")} />
                        {t("identity_upload.error_msg")}
                      </Text>
                    ) : (
                      <Text
                        $textAlign="center"
                        $bold
                        $responsive
                        size={1.7}
                        color="green"
                      >
                        <MaterialCommunityIcons name="check" size={hp("3%")} />
                        {t("identity_upload.submitted")}
                      </Text>
                    )}

                    <View spaceTop={10}>
                      {errmessage !== "" ? (
                        <Text $textAlign="center" $responsive size={1.5}>
                          {errmessage}
                        </Text>
                      ) : (
                        <Text $textAlign="center" $responsive size={1.5}>
                          {t("message_label.success_msg_photo")}
                        </Text>
                      )}
                    </View>
                  </View>
                ) : (
                  <>
                    <View
                      height={hp("5%")}
                      spaceVertical={hp("1%")}
                      $transparent
                      flexDirection="row"
                      spaceHorizontal={wp("10%")}
                    >
                      {!picture && Platform.OS === "web" ? (
                        <ImagePicker
                          onSelect={(image) => {
                            setPicture(image);
                          }}
                        />
                      ) : (
                        <Button
                          style={{ marginRight: wp("0.2%"), flex: 1 }}
                          colorScheme={picture ? "success" : "primary"}
                          onPress={() => {
                            if (picture) {
                              handleSelect(picture);
                            } else {
                              gotoImagePicker();
                            }
                          }}
                        >
                          <Text color="white" $responsive size={1.5}>
                            {picture
                              ? t("photo.btn_submit")
                              : t("photo.btn_upload")}
                          </Text>
                        </Button>
                      )}
                      <Button
                        style={{ marginLeft: wp("0.2%"), flex: 1 }}
                        colorScheme={picture ? "error" : "primary"}
                        onPress={() => {
                          if (picture) {
                            setPicture(undefined);
                          } else {
                            if (Platform.OS === "web") {
                              setActive(CAMERA);
                            } else {
                              gotoCamera();
                            }
                          }
                        }}
                      >
                        <Text color="white" $responsive size={1.5}>
                          {picture
                            ? t("photo.btn_retake")
                            : t("photo.btn_capture")}
                        </Text>
                      </Button>
                    </View>

                    {showApprovePhoto && (
                      <View
                        $transparent
                        justifyContent="center"
                        spaceHorizontal={wp("10%")}
                        alignSelf="center"
                      >
                        <View
                          $transparent
                          alignSelf="center"
                          spaceVertical={10}
                        >
                          <FormControl.Label>
                            <HStack space={3}>
                              <Text $responsive size={1.3}>
                                {t("profile.photo_edit.photo_message")}
                              </Text>
                              <Switch
                                size="sm"
                                // boxSize={hp("%")}
                                colorScheme="emerald"
                                isChecked={approved}
                                onToggle={() => setApproved(!approved)}
                                accessibilityLabel={t(
                                  "profile.photo_edit.photo_message"
                                )}
                                accessibilityRole={"switch"}
                              />
                            </HStack>
                          </FormControl.Label>
                        </View>
                      </View>
                    )}
                  </>
                )}
              </View>
            </>
          ) : (
            <>
              {photoGuidelinesMessage && (
                <Text $textAlign="center" $responsive size={1.8}>
                  {photoGuidelinesMessage}
                </Text>
              )}
              {samplePhotoGuidelines && (
                <View
                  $transparent
                  height={hp("35%")}
                  width={!isMobile ? wp("15%") : wp("60%")}
                  spaceTop={hp("2%")}
                  alignSelf="center"
                  style={{
                    borderWidth: 1,
                    borderColor: theme === DARK_MODE ? "#5c5b5b" : "#ddd",
                  }}
                >
                  <Image
                    style={{
                      flex: 1,
                      ...StyleSheet.absoluteFillObject,
                      resizeMode: "contain",
                    }}
                    source={{
                      uri: `data:image/png;base64,${samplePhotoGuidelines}`,
                    }}
                  />
                </View>
              )}
            </>
          )}
        </View>
      </ScrollView>
    );
  };

  const BottomButtonView = () => {
    return (
      <>
        <Divider $fullSize color={theme === DARK_MODE ? "#5c5b5b" : "#ddd"} />
        <View
          spaceVertical={hp("0.5%")}
          $transparent
          flexDirection="row"
          width={"100%"}
        >
          {showPicker ? (
            <>
              <Button
                variant="unstyled"
                alignItems="center"
                justifyContent="center"
                $fullWidth
                height={hp("5%")}
                disabled={
                  isLoading || isSubmitted || errmessage === "Network Error"
                    ? true
                    : false
                }
                onPress={() => {
                  setShowPicker(false);
                  setErrMessage("");
                  setPicture(undefined);
                }}
              >
                <Text
                  color={
                    isLoading || isSubmitted || errmessage === "Network Error"
                      ? "grey"
                      : "#2969cf"
                  }
                  $responsive
                  size={1.7}
                >
                  {t("auth.btn_prev")}
                </Text>
              </Button>
              <Divider $vertical />
              {errmessage === "Network Error" ? (
                <Button
                  variant="unstyled"
                  alignItems="center"
                  justifyContent="center"
                  $fullWidth
                  height={hp("5%")}
                  disabled={isExitingWithRefresh}
                  onPress={async () => {
                    setIsExitingWithRefresh(true);
                    await reloadStartup();
                    await reloadCardHolderInfo();
                    if (isMobile) {
                      navigation?.navigate("DigitalId");
                    } else {
                      navigation?.navigate("Profile");
                    }
                  }}
                >
                  {isExitingWithRefresh ? (
                    <ActivityIndicator
                      accessible
                      accessibilityLabel={t("accessibility.loading", {
                        text: t("tabs.profile_edit"),
                      })}
                    />
                  ) : (
                    <Text color={"#2969cf"} $responsive size={1.7}>
                      {t("auth.btn_next")}
                    </Text>
                  )}
                </Button>
              ) : (
                <Button
                  variant="unstyled"
                  alignItems="center"
                  justifyContent="center"
                  $fullWidth
                  height={hp("5%")}
                  disabled={isLoading || !isSubmitted}
                  onPress={() => handleExit()}
                >
                  <Text
                    color={isLoading || !isSubmitted ? "grey" : "#2969cf"}
                    $responsive
                    size={1.7}
                  >
                    {t("auth.btn_next")}
                  </Text>
                </Button>
              )}
            </>
          ) : (
            <>
              <Button
                variant="unstyled"
                alignItems="center"
                justifyContent="center"
                $fullWidth
                height={hp("5%")}
                onPress={() => handleExit()}
              >
                <Text color="#2969cf" $responsive size={1.7}>
                  {t("auth.no_thanks")}
                </Text>
              </Button>
              <Divider $vertical />
              <Button
                variant="unstyled"
                alignItems="center"
                justifyContent="center"
                $fullWidth
                height={hp("5%")}
                onPress={() => setShowPicker(true)}
              >
                <Text color="#2969cf" $responsive size={1.7}>
                  {t("auth.btn_next")}
                </Text>
              </Button>
            </>
          )}
        </View>
      </>
    );
  };

  return isMobile ? (
    <View $fullscreen>
      <SafeAreaView
        style={{
          alignItems: "center",
          flex: 1,
          backgroundColor: "transparent",
        }}
      >
        <ClientBrand />
        <Text accessibilityRole="header" $bold $responsive size={3}>
          {t("header.photo")}
        </Text>
        <SubHeadView />
        <BottomButtonView />
      </SafeAreaView>
    </View>
  ) : (
    <View $fullscreen bgColor={theme === DARK_MODE ? "#313131" : "#F7F7F7"}>
      <WizardHeader />
      <View
        bgColor={theme === DARK_MODE ? "black" : "white"}
        spaceVertical={hp("5%")}
        justifyContent="center"
        borderWidth={1}
        borderColor={theme === DARK_MODE ? "#5c5b5b" : "#ddd"}
        width={wp("50%")}
        alignSelf="center"
        style={{ flex: 1 }}
      >
        <View $transparent spaceTop={hp("10%")}>
          <Text
            accessibilityRole="header"
            $bold
            $textAlign="center"
            $responsive
            size={3}
          >
            {t("header.photo")}
          </Text>
        </View>
        <SubHeadView />
        <View $transparent alignItems="center">
          <BottomButtonView />
        </View>
      </View>
    </View>
  );
}

export default ProfilePhotoScreen;

const styles = StyleSheet.create({
  pressableBtn: {
    flex: Platform.OS === "android" || Platform.OS === "ios" ? 1 : undefined,
    height: 40,
    justifyContent: "center",
    alignItems: "center",
  },
  headingText: {
    fontWeight: "bold",
    textAlign: "center",
  },
});
