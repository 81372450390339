export const CARD_DATA = "card";
export const CONFIG_DATA = "config";
export const ORGANIZATION_DATA = "organization";
export const NOTIFICATIONS_DATA = "notifications";
export const CREDENTIALS_DATA = "credentials"
export const STARTUP_PROFILE = "profile";
export const APP_VERSION = "appVersion";
export const DIGITAL_CARD = "digitalCard";
export const CACHE_KEY_PRIVACY_POLICY = "CACHE_KEY_PRIVACY_POLICY";
export const CACHE_KEY_PIN = "pin";
export const FIRST_LOGIN = "firstLogin";

const CACHE = {
    CARD_DATA,
    CONFIG_DATA,
    ORGANIZATION_DATA,
    NOTIFICATIONS_DATA,
    CREDENTIALS_DATA,
    STARTUP_PROFILE,
    APP_VERSION,
    DIGITAL_CARD,
    CACHE_KEY_PRIVACY_POLICY,
    CACHE_KEY_PIN,
    FIRST_LOGIN
} as const;

export default CACHE

type CacheKeys = keyof typeof CACHE;
export type Cache = typeof CACHE[CacheKeys]