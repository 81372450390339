import * as React from "react";
import { getBaseApiUrl } from "../constants/base-urls";
import regions, { Region } from "../constants/regions";
import { setBaseURL } from "../services/api";
import { useEnv } from "./env";
import * as Cache from "../services/cache";

type RegionContextValue = {
  region: Region | null;
  setRegion: (region: Region) => void;
  regions: string[];
  askRegion: boolean;
  setAskRegion: (value: boolean) => void;
};

const initialValue: RegionContextValue = {
  region: null,
  setRegion: () => undefined,
  regions,
  askRegion: false,
  setAskRegion: () => undefined,
};

const RegionContext = React.createContext(initialValue);

const REGION_CACHE_KEY = "REGION_CACHE_KEY";

export const useRegion = () => React.useContext(RegionContext);

export const RegionProvider: React.FC = ({ children }) => {
  const [env] = useEnv();
  const [askRegion, setAskRegion] = React.useState(false);
  const [selected, setSelected] = React.useState(() => initialValue.region);
  const handleSelected = React.useCallback(
    (region: Region) => {
      setSelected(region);
      setAskRegion(false);
    },
    [setSelected]
  );
  const value = React.useMemo<RegionContextValue>(
    () => ({
      region: selected,
      setRegion: handleSelected,
      regions,
      askRegion,
      setAskRegion,
    }),
    [selected, handleSelected, askRegion]
  );

  React.useEffect(() => {
    (async () => {
      const cachedLocale = await Cache.get(REGION_CACHE_KEY);
      if (cachedLocale) {
        setSelected(cachedLocale);
      }
    })();
  }, []);

  React.useEffect(() => {
    if (!selected) return;
    Cache.set(REGION_CACHE_KEY, selected.toString());
    setBaseURL(getBaseApiUrl(env, selected));
    setAskRegion(false);
  }, [selected]);

  return (
    <RegionContext.Provider value={value}>{children}</RegionContext.Provider>
  );
};
