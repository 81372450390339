import * as React from "react";
import { Button, Text as BaseText } from "native-base";
import ReactSignatureCanvas from "react-signature-canvas";
import { Text, View } from "./Styled";

const Signature = ({
  text,
  clearText,
  confirmText,
  onSelect,
}: {
  text: string;
  clearText: string;
  confirmText: string;
  onSelect: (signature: string) => void;
}) => {
  const ref = React.useRef<React.MutableRefObject<any>>(null);

  const handleDrawConfirm = () => {
    // @ts-ignore
    const signature = ref.current?.toDataURL("image/png");
    // @ts-ignore
    ref.current?.clear();
    onSelect(signature);
  };

  return (
    <View bgColor="white">
      <Text as={BaseText} color="white">
        {text}
      </Text>
      <ReactSignatureCanvas ref={ref} canvasProps={{ height: 500 }} />
      <Text color="black" $textAlign="center">
        {text}
      </Text>
      <View
        $transparent
        flexDirection="row"
        justifyContent="center"
        spaceTop={20}
      >
        <Button onPress={handleDrawConfirm}>
          <Text as={BaseText} color="white" $uppercase>
            {confirmText}
          </Text>
        </Button>
        <View width={30} />
        <Button onPress={() => ref.current?.clear()}>
          <Text as={BaseText} color="white" $uppercase>
            {clearText}
          </Text>
        </Button>
      </View>
    </View>
  );
};

export default Signature;
