import * as React from "react";
import { StyleSheet } from "react-native";
import { useStartupOrganization } from "../contexts/startup/hooks";
import { View, Image } from "./Styled";
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';

const ClientBrand = () => {
  const { logo } = useStartupOrganization();
  return (
    <View $transparent justifyContent="center" alignItems="center" spaceVertical={hp("3%")} width={300} height={80}>
      {
        logo && logo !== "" ? (
          <Image
            style={{flex: 1, ...StyleSheet.absoluteFillObject, resizeMode: "contain", height:80, width:300  }}
            source={{ uri: logo }}
          />
        ) : 
          <Image
            style={{flex: 1, ...StyleSheet.absoluteFillObject, resizeMode: "contain" , height:80, width:300 }}
            source={require("../assets/images/card-exchange.png")}
          />
      }
    </View>
  )
};

const styles = StyleSheet.create({
  logo: {
    flex: 1, ...StyleSheet.absoluteFillObject, resizeMode: "contain" 
  },
});

export default ClientBrand;
