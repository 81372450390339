import * as React from "react";
import { NavigationContainer } from "@react-navigation/native";

import MobileNavigation from "./Mobile";
import DesktopNavigation from "./Desktop";
import useIsMobile from "../hooks/useIsMobile";

export default function Navigation() {
  const isMobile = useIsMobile();
  return (
    <NavigationContainer>
      {isMobile ? <MobileNavigation /> : <DesktopNavigation />}
    </NavigationContainer>
  );
}
