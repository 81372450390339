import * as React from "react";
import { Platform } from "react-native";
import initialValue, {
  FeatureFlagType,
  mergeFeatures,
} from "../constants/feature-flags";
import { ConfigType } from "./startup";

const FeatureFlagsContext = React.createContext<FeatureFlagType>(initialValue);

export const FeatureFlagsProvider: React.FC<{ config: ConfigType | null }> = ({
  children,
  config,
}) => {
  const value = React.useMemo(
    (): FeatureFlagType =>
      mergeFeatures(config, initialValue, {
        SET_PIN: Platform.select({
          web: false,
          default: !!initialValue.SET_PIN,
        }),
      }),
    [config]
  );

  return (
    <FeatureFlagsContext.Provider value={value}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};

export const useFeatureFlags = () => React.useContext(FeatureFlagsContext);
