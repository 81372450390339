export const PENDING = "Pending";
export const APPROVED = "Approved";
export const REJECTED = "Rejected";
export const UNDER_REVIEW = "UnderReview";

const PHOTO_STATUS = {
  PENDING,
  APPROVED,
  REJECTED,
  UNDER_REVIEW,
} as const;

export default PHOTO_STATUS;

type PhotoStatusKeys = keyof typeof PHOTO_STATUS;
export type PhotoStatus = typeof PHOTO_STATUS[PhotoStatusKeys];
