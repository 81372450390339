import * as React from "react";
import {
  createStackNavigator,
  StackNavigationOptions,
} from "@react-navigation/stack";
import DigitalIdScreen from "../screens/DigitalIdScreen";
import ResetPasswordScreen from "../screens/ResetPasswordScreen";
import ProfileScreen from "../screens/ProfileScreen";
import NotificationsScreen from "../screens/NotificationsScreen";
import SettingsScreen from "../screens/SettingsScreen";
import ProfileEditScreen from "../screens/ProfileEditScreen";
import withModal from "../hoc/withModal";
import { useTranslation } from "../contexts/translations";
import ProfilePhotoScreen from "../screens/ProfilePhotoScreen";
import SignatureUploadScreen from "../screens/SignatureUploadScreen";
import AddressScreen from "../screens/AddressScreen";
import IdSettingScreen from "../screens/IdSettingScreen";
import CredentialScreen from "../screens/CredentialScreen";
import CredentialTypeListScreen from '../screens/CredentialTypeListScreen';
import ProfileActivationScreen from "../screens/ProfileActivationScreen";
import CredentialActionScreen from "../screens/CredentialActionScreen";
import { MainStackParams } from "./types";
import MobileHeader from "../components/MobileHeader";
import NotificationMessageScreen from "../screens/NotificationMessageScreen";
import { Platform } from "react-native";
import StartupMessageScreen from "../screens/StartupMessageScreen";
import { useStartupUser } from "../contexts/startup/hooks";
import IdentityUploadScreen from "../screens/IndentityUploadScreen";
import MissingFieldScreen from "../screens/MissingFieldScreen";
import KeysScreen from "../screens/KeysScreen";

const Stack = createStackNavigator<MainStackParams>();

const modalOptions: StackNavigationOptions = {
  headerShown: false,
  cardStyle: { backgroundColor: "transparent" },
  cardOverlayEnabled: Platform.OS === "android",
  detachPreviousScreen: false,
  presentation: "modal",
};

export default function MobileNavigation() {
  const { t } = useTranslation();
  const user = useStartupUser();

  return (
    <Stack.Navigator initialRouteName="DigitalId">
      <Stack.Screen
        name="DigitalId"
        component={user?.archive ? StartupMessageScreen : DigitalIdScreen}
        options={{
          headerShown: false
        }}
      />

      <Stack.Screen
        name="Profile"
        component={user?.archive ? StartupMessageScreen : ProfileScreen}
        options={{
          header: () => <MobileHeader routName={t("profile.details")}/>,
          cardStyle: { backgroundColor: "transparent"},
          detachPreviousScreen: true
        }}
      />

      <Stack.Screen
        name="EditProfile"
        component={user?.archive ? StartupMessageScreen : ProfileEditScreen}
        options={{
          header: () => <MobileHeader routName={t("profile.actions.edit")}/>,
          cardStyle: { backgroundColor: "transparent" },
        }}
      />

      <Stack.Screen
        name="ProfilePhoto"
        component={user?.archive ? StartupMessageScreen : ProfilePhotoScreen}
        options={{ headerShown: false, detachPreviousScreen: false }}
      />

      <Stack.Screen
        name="IdentityScreen"
        component={user?.archive ? StartupMessageScreen : IdentityUploadScreen}
        options={{ headerShown: false }}
      />

      <Stack.Screen
        name="MissingFields"
        component={user?.archive ? StartupMessageScreen : MissingFieldScreen}
        options={{ headerShown: false }}
      />

      <Stack.Screen
        name="SignatureUpload"
        component={user?.archive ? StartupMessageScreen : SignatureUploadScreen}
        options={{ headerShown: false, detachPreviousScreen: false }}
      />

      <Stack.Screen
        name="Notifications"
        component={user?.archive ? StartupMessageScreen : NotificationsScreen}
        options={{
          header: () => <MobileHeader routName={t("tabs.notifications")}/>
        }}
      />

      <Stack.Screen
        name="NotificationMessage"
        component={user?.archive ? StartupMessageScreen : NotificationMessageScreen}
        options={{
          header: () => <MobileHeader routName={"Notification"}/>
        }}      
      />

      <Stack.Screen
        name="IdSettings"
        component={user?.archive ? StartupMessageScreen : withModal(t("tabs.id_settings"), IdSettingScreen, true)}
        options={modalOptions}
      />

      <Stack.Screen
        name="Credentials"
        component={user?.archive ? StartupMessageScreen : withModal(t("tabs.credentials"), CredentialScreen, false)}
        // options={modalOptions}
        options={Platform.OS === "android" ? modalOptions : {
          headerShown:false,
          cardStyle: { backgroundColor: "transparent"},
          detachPreviousScreen: false,
          cardOverlayEnabled: true
        }}
      />

      <Stack.Screen
        name="CredentialAction"
        component={user?.archive ? StartupMessageScreen : withModal(t("tabs.credentials"), CredentialActionScreen, false)}
        // options={modalOptions}
        options={Platform.OS === "android" ? modalOptions : {
          headerShown:false,
          cardStyle: { backgroundColor: "transparent"},
          detachPreviousScreen: false,
          cardOverlayEnabled: true
        }}
      />

      <Stack.Screen
        name="CredentialTypeList"
        component={user?.archive ? StartupMessageScreen : withModal(t("tabs.credentials"), CredentialTypeListScreen, false)}
        options={modalOptions}
      />

      <Stack.Screen
        name="ProfileActivation"
        component={user?.archive ? StartupMessageScreen : withModal(t("tabs.profiles"), ProfileActivationScreen, false)}
        // options={modalOptions}
        options={Platform.OS === "android" ? modalOptions : {
          headerShown:false,
          cardStyle: { backgroundColor: "transparent"},
          detachPreviousScreen: false,
          cardOverlayEnabled: true
        }}
      />

      <Stack.Screen
        name="Settings"
        component={user?.archive ? StartupMessageScreen : withModal(t("tabs.settings"), SettingsScreen, true)}
        options={modalOptions}
      />

      <Stack.Screen
        name="ResetPassword"
        component={user?.archive ? StartupMessageScreen : withModal(t("tabs.new_password"), ResetPasswordScreen, true)}
        options={modalOptions}
      />

      <Stack.Screen
        name="FormAddress"
        component={user?.archive ? StartupMessageScreen : withModal(t("tabs.address"), AddressScreen, true)}
        options={modalOptions}
      />

      <Stack.Screen
        name="Keys"
        component={user?.archive ? StartupMessageScreen : withModal(t("tabs.keys"), KeysScreen, true)}
        options={modalOptions}
      />

      <Stack.Screen
        name="NotFound"
        component={StartupMessageScreen}
        options={{ title: "Oops!" }}
      />
      
      <Stack.Screen
        name="StartupMessageScreen"
        component={StartupMessageScreen}
        options={{ title: "No access",  headerShown: false }}
      />
    </Stack.Navigator>
  );
}
