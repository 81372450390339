import * as React from "react";
import { StyleSheet, Image } from "react-native";
import { View, Text, Divider } from "../components/Styled";
import { useRoute } from '@react-navigation/native';
import { ScrollView } from "react-native-gesture-handler";

export default function NotificationsScreen() {
  const route = useRoute();
  
  return (
    <View $fullscreen height={500}>
        <View $transparent space={10}>

            <View $transparent flexShrink={1} justifyContent="center" space={10}>
                <Text $bold size={16}>{route.params?.title}</Text>
              </View>         
            <Divider $fullSize spaceVertical={10}/>
            <View $transparent space={10} as={ScrollView}
                contentContainerStyle={{justifyContent: "center" }}> 
                <Text size={15}>{route.params?.message}</Text>
            </View>
        </View>
    </View>
  );
}

const styles = StyleSheet.create({
  deleteBtn: {
    backgroundColor: "#AD0000",
    marginTop: 10,
    paddingHorizontal: 5,
  },
  logo:{
    width: 50,
    height: 50,
    resizeMode: "contain",
  },
  btnTouch:{
    height:70,
    flexDirection:"row",
    borderRadius:3,
    margin:5,
    backgroundColor:  "transparent" 
  },
  inputSearch: {
    fontSize: 20,
    marginLeft: 10,
    width: "90%",
  },
  searchContainer: {
    // margin: 15,
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "row",
    // width: "90%",

  },
});
