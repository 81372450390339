import * as React from "react";
import BasicAuth from "../../screens/BasicAuth";
import useAuthRequest from "../../hooks/useAuthRequest";
import { WebBrowserResult } from "expo-web-browser";
import { useRegion } from "../region";

const initialValue = {
  refreshAuth: async () => undefined,
  signIn: () => undefined,
  forgotPassword: () => undefined,
  signOut: () => undefined,
  isLoading: false,
  isLiveEnabled: true,
  isAuthorized: false,
  disabled: false,
  hasStandSubscription: false
};

type AuthContext = {
  refreshAuth: () => Promise<void | undefined>;
  signIn: () => void | Promise<WebBrowserResult>;
  forgotPassword: () => void | Promise<WebBrowserResult>;
  signOut: () => void | Promise<void>;
  isLoading: boolean;
  isLiveEnabled: boolean;
  isAuthorized: boolean;
  disabled: boolean;
  hasStandSubscription: boolean;
};

export const AuthContext = React.createContext<AuthContext>(initialValue);

export const AuthProvider: React.FC = ({ children }) => {
  const value = useAuthRequest();
  const { askRegion } = useRegion();


  if (value.isAuthorized && !askRegion) {
    return (
      <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
  }

  return (
    <BasicAuth
      signIn={value.signIn}
      signOut={value.signOut}
      forgotPassword={value.forgotPassword}
      disabled={value.disabled}
      isLoading={value.isLoading}
    />
  );
};
