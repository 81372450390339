import * as React from "react";
import { Button, Icon, Text as ButtonText } from "native-base";
import { View, Text, Image, Divider } from "./Styled";
import ClientBrand from "./ClientBrand";
import { useTranslation } from "../contexts/translations";
import { useAuth } from "../contexts/auth/hooks";
import { useStartupOrganization } from "../contexts/startup/hooks";
import SmoothPinCodeInput from "./SmoothPinCodeInput";
import { FontAwesome } from "@expo/vector-icons";
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';

type PinCodeProps = {
  hasError: boolean;
  label?: string;
  value: string;
  onChange: (pin: string) => void;
  onComplete: (pin: string) => void;
  resetPin?: () => void;
  children: React.ReactNode | null;
};

const PinCode = ({
  hasError = false,
  label = "PIN Code",
  value,
  onChange,
  onComplete,
  resetPin,
  children,
  ...rest
}: PinCodeProps) => {
  const { t } = useTranslation();
  const { signOut } = useAuth();
  const { name } = useStartupOrganization();
  return (
    <View $fullscreen justifyContent="space-around">
      <View $transparent alignItems="center">
      <ClientBrand />
      </View>
      <View spaceHorizontal={30}>
        <Text $responsive size={2} $textAlign="center">{`${t("pin.welcome")} ${name}`}</Text>
      </View>
      <View alignItems="center" alignContent="space-around">
        <SmoothPinCodeInput
          cellStyle={{
            borderWidth: 1,
            borderColor: hasError ? "red" : "gray",
          }}
          cellStyleFocused={{
            borderWidth: 2,
            borderColor: hasError ? "red" : "gray",
          }}
          autoFocus
          password
          restrictToNumbers
          value={value}
          onTextChange={onChange}
          onFulfill={onComplete}
          {...rest}
        />
        <View spaceTop={10}>
          <Text>{label}</Text>
        </View>
        {resetPin && (
          <View $transparent spaceTop={20}>
            <Button variant="ghost" onPress={resetPin}>
              {t("pin.back")}
            </Button>
          </View>
        )}
        {children}
      </View>
      <View alignItems="center" $transparent>
        <View $transparent spaceVertical={hp("2%")}>
          <Text $responsive size={1.3}>Powered by</Text>
        </View>
        <Image height={5} width={11} source={require("../assets/images/stand-logo.png")} />
      </View>
    </View>
  );
};

export default PinCode;
