import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Button, Icon } from "native-base";
import * as React from "react";
import { StyleSheet } from "react-native";
import { useLocalAuth } from "../contexts/local-auth";
import { useTranslation } from "../contexts/translations";

const LocalAuth: React.FC<{ onConfirm: () => void }> = ({ onConfirm }) => {
  const { t } = useTranslation();
  const [{ hasEnabled, hasFaceIdSupport, hasTouchIdSupport }, authenticate] =
    useLocalAuth();

  async function handlePress() {
    const isAuthenticated = await authenticate();
    if (isAuthenticated) {
      onConfirm();
    }
  }

  return (
    <>
      {hasEnabled ? (
        hasFaceIdSupport ? (
          <Button
            onPress={handlePress}
            variant="ghost"
            size="lg"
            style={styles.button}
            accessibilityLabel={t("accessibility.auth.face_id")}
          >
            <Icon as={MaterialCommunityIcons} name="face-recognition" />
          </Button>
        ) : hasTouchIdSupport ? (
          <Button
            onPress={handlePress}
            variant="ghost"
            size="lg"
            style={styles.button}
            accessibilityLabel={t("accessibility.auth.finger_print")}
          >
            <Icon as={MaterialCommunityIcons} name="fingerprint" />
          </Button>
        ) : null
      ) : null}
    </>
  );
};

const styles = StyleSheet.create({
  button: { alignSelf: "center", marginTop: 20 },
  icon: {
    fontSize: 40,
  },
});

export default LocalAuth;
