export const HID = "hid_mobile_access";
export const SALTO = "salto_space";

const DIGITAL_SYSTEM = {
    HID,
    SALTO
} as const;

export default DIGITAL_SYSTEM

type DigitalSystemKeys = keyof typeof DIGITAL_SYSTEM;
export type DigitalSystem = typeof DIGITAL_SYSTEM[DigitalSystemKeys]