import * as React from "react";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { AuthProvider } from "../contexts/auth";
import { EnvProvider } from "../contexts/env";
import { PinProvider } from "../contexts/pin";
import { RegionProvider } from "../contexts/region";
import { TranslationProvider } from "../contexts/translations";
import { ThemeProvider } from "../contexts/theme";
import CardHolderProvider from "../contexts/card-holder";
import StartupProvider, { StartupState } from "../contexts/startup";
import { FeatureFlagsProvider } from "../contexts/feature-flags";
import LocalAuthProvider from "../contexts/local-auth";
import PrivacyPolicyScreen from "../screens/PrivacyPolicyScreen";
import StartupMessageScreen from "../screens/StartupMessageScreen";
import SandboxBanner from "./SandboxBanner";
import IdentityUploadScreen from "../screens/IndentityUploadScreen";
import ProfilePhotoScreen from "../screens/ProfilePhotoScreen";
import MissingFieldScreen from "../screens/MissingFieldScreen";
import ThankYouScreen from "../screens/ThankYouScreen";
import { StandTemplateProvider } from "../contexts/stand-template";
import { CurrentCardholderContextProvider } from "../contexts/current-cardholder";
import AppConfigProvider from "../contexts/app-config";

const Providers: React.FC = ({ children }) => (
  <SafeAreaProvider>
    <EnvProvider>
      <ThemeProvider>
        <RegionProvider>
          <TranslationProvider>
            <AuthProvider>
              <AppConfigProvider>
                <SandboxBanner>
                  <CurrentCardholderContextProvider>
                    <StartupProvider>
                      {({ config }: StartupState) => (
                        <FeatureFlagsProvider config={config}>
                          <LocalAuthProvider>
                            <PinProvider>
                              <StartupMessageScreen>
                                <PrivacyPolicyScreen>
                                  <IdentityUploadScreen>
                                    <ProfilePhotoScreen>
                                      <MissingFieldScreen>
                                        <ThankYouScreen>
                                          <StandTemplateProvider>
                                            <CardHolderProvider>
                                              {children}
                                            </CardHolderProvider>
                                          </StandTemplateProvider>
                                        </ThankYouScreen>
                                      </MissingFieldScreen>
                                    </ProfilePhotoScreen>
                                  </IdentityUploadScreen>
                                </PrivacyPolicyScreen>
                              </StartupMessageScreen>
                            </PinProvider>
                          </LocalAuthProvider>
                        </FeatureFlagsProvider>
                      )}
                    </StartupProvider>
                  </CurrentCardholderContextProvider>
                </SandboxBanner>
              </AppConfigProvider>
            </AuthProvider>
          </TranslationProvider>
        </RegionProvider>
      </ThemeProvider>
    </EnvProvider>
  </SafeAreaProvider>
);

export default Providers;
