export const DARK_MODE = "dark" as const;
export const LIGHT_MODE = "light" as const;

export type Theme = typeof DARK_MODE | typeof LIGHT_MODE;

const defaultBrandColor = "#5dc944";

const getLightPallete = (brandColor = defaultBrandColor) => ({
  text: "#000",
  background: "#fff",
  tint: brandColor,
  tabIconDefault: "#ccc",
  tabIconSelected: brandColor,
  headerTintColor: "#FFF",
  headerBackgroundColor: brandColor,
});

const getDarkPallete = (brandColor = defaultBrandColor) => ({
  text: "#fff",
  background: "#000",
  tint: brandColor,
  tabIconDefault: "#ccc",
  tabIconSelected: brandColor,
  headerTintColor: brandColor,
  headerBackgroundColor: "#222",
});

const defaultPalette = {
  light: getLightPallete(),
  dark: getDarkPallete(),
};

export function getThemeColors(theme: Theme, brandColor = defaultBrandColor) {
  return theme === DARK_MODE
    ? getDarkPallete(brandColor)
    : getLightPallete(brandColor);
}

export default defaultPalette;

export type ColorName = keyof typeof defaultPalette.light &
  keyof typeof defaultPalette.dark;
