import * as React from "react";
import styled from "styled-components";
import DigitalId from "../components/DigitalId";
import PersonalDetails from "../components/PersonalDetails";
import { View } from "../components/Styled";
import { DARK_MODE } from "../constants/Colors";
import { useTheme } from "../contexts/theme/hooks";
import useIsMobile from "../hooks/useIsMobile";

type Container = { bgColor: string };
const Container = styled.div<Container>`
  width: 100%;
  flex-grow: 1;
  background: ${(props) => props.bgColor};
`;

const Center = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: auto;
`;

export default function ProfileScreen() {
  const isMobile = useIsMobile();
  const [theme] = useTheme();
  return isMobile ? (
    <PersonalDetails />
  ) : (
    <Container bgColor={theme === DARK_MODE ? "#313131" : "#F7F7F7"}>
      <Center>
        <View
          $transparent
          space={10}
          flexDirection="row"
          justifyContent="space-between"
        >
          <View $transparent $fullscreen flexGrow={1} space={10}>
            <PersonalDetails />
          </View>
          <View
            $transparent
            width={400}
            flexDirection="row"
            justifyContent="center"
          >
            <DigitalId />
          </View>
        </View>
      </Center>
    </Container>
  );
}
