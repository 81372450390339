import * as React from "react";
import { useNavigation } from "@react-navigation/native";
import { useTranslation } from "../contexts/translations";
import { useAlert } from "./useAlert";

const useUnsavedChanges = (hasUnsavedChanges = false) => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const alert = useAlert(
    t("unsaved.title"),
    t("unsaved.description"),
    t("unsaved.cancel"),
    t("unsaved.confirm")
  );
  React.useEffect(
    () =>
      navigation.addListener("beforeRemove", (e) => {
        if (!hasUnsavedChanges) {
          // If we don't have unsaved changes, then we don't need to do anything
          return;
        }

        // Prevent default behavior of leaving the screen
        e.preventDefault();

        // Prompt the user before leaving the screen
        alert(
          () => null,
          () => navigation.dispatch(e.data.action)
        );
      }),
    [navigation, hasUnsavedChanges]
  );
};

export default useUnsavedChanges;
