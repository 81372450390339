import * as React from "react";
import { NetInfoState } from "@react-native-community/netinfo";
import { View, Text } from "./Styled";
import { useTranslation } from "../contexts/translations";
import useNetworkInfo from "../hooks/useNetworkInfo";

const NetworkContext = React.createContext<NetInfoState | null>(null);

export const useNetworkState = () => React.useContext(NetworkContext);

const NetworkIndication: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const state = useNetworkInfo();

  return (
    <NetworkContext.Provider value={state}>
      {children}
      {state && !state.isInternetReachable && (
        <View bgColor="#cd5c5c" height={36} justifyContent="center">
          <Text $textAlign="center" color="#FFF">
            {t("offline")}
          </Text>
        </View>
      )}
    </NetworkContext.Provider>
  );
};

export default NetworkIndication;
