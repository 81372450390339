import * as React from "react";
import * as LocalAuthentication from "expo-local-authentication";

type LocalAuthContext = [
  {
    hasFaceIdSupport: boolean;
    hasTouchIdSupport: boolean;
    hasEnabled: boolean;
  },
  () => Promise<boolean>
];

const initialValue = {
  hasFaceIdSupport: false,
  hasTouchIdSupport: false,
  hasEnabled: false,
};

const FINGERPRINT = 1;
const FACIAL_RECOGNITION = 2;

const LocalAuthContext = React.createContext<LocalAuthContext>([
  initialValue,
  async () => false,
]);

const LocalAuthProvider: React.FC = ({ children }) => {
  const [state, setState] = React.useState(initialValue);

  React.useEffect(() => {
    (async () => {
      const support = await LocalAuthentication.supportedAuthenticationTypesAsync();
      if (support.length > 0) {
        const hasEnabled = await LocalAuthentication.isEnrolledAsync();

        setState((current) => ({
          ...current,
          hasFaceIdSupport: support.includes(FACIAL_RECOGNITION),
          hasTouchIdSupport: support.includes(FINGERPRINT),
          hasEnabled,
        }));
      }
    })();
  }, []);

  const authenticate = React.useCallback(async () => {
    const result = await LocalAuthentication.authenticateAsync();
    return result.success;
  }, []);

  const contextValue = React.useMemo<LocalAuthContext>(
    () => [state, authenticate],
    [state, authenticate]
  );

  return (
    <LocalAuthContext.Provider value={contextValue}>
      {children}
    </LocalAuthContext.Provider>
  );
};

export const useLocalAuth = () => React.useContext(LocalAuthContext);

export default LocalAuthProvider;
