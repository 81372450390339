import * as React from "react";
import { StatusBar } from "expo-status-bar";
import Navigation from "../navigation";
import NetworkIndicator from "../components/NetworkIndicator";
import usePushNotification from "../hooks/usePushNotification";
import { useTheme } from "../contexts/theme/hooks";
import { LIGHT_MODE } from "../constants/Colors";
import { SafeAreaView } from "react-native";

const App: React.FC = ({ children }) => {
  usePushNotification();
  const [theme] = useTheme();
    return (
    <NetworkIndicator>
      <Navigation />
      <StatusBar style={theme === LIGHT_MODE ? "dark" : "light"} />
      <SafeAreaView>
        {children}
      </SafeAreaView>
    </NetworkIndicator>
  );
};

export default App;
