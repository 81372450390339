export const NONE = 'none'
export const ACTIVATED = 'Activated'
export const BLOCKED = 'Blocked'
export const EXPIRED = 'Expired'

const PROFILE_STATUS = {
  NONE,
  ACTIVATED,
  BLOCKED,
  EXPIRED,
 } as const;

export default PROFILE_STATUS

type ProfileStatusKeys = keyof typeof PROFILE_STATUS;
export type ProfileStatus = typeof PROFILE_STATUS[ProfileStatusKeys]