import * as React from "react";
import { ImageProps } from "react-native";
import { Image } from "./Styled";
import { getCorrectSize } from "../utils";
import { PhotoShape } from "../constants/photo-shape";

type ImageWithRatio = {
  size: number;
  ratio?: number;
  crop?: boolean;
  roundedBorder?: boolean;
  shape?: PhotoShape;
} & ImageProps;

const ImageWithRatio: React.FC<ImageWithRatio> = ({
  size,
  ratio = 1,
  crop = false,
  ...props
}) => {
  const [width, height] = getCorrectSize(size, ratio);

  return (
    <Image
      // width={width}
      // height={height}
      resizeMode={!crop ? "cover" : undefined}
      {...props}
      style={{width:width, height: height}}
    />
  );
};

export default ImageWithRatio;
