import {
  ColorMode,
  extendTheme,
  NativeBaseProvider,
  theme as defaultTheme,
  useColorMode,
  useColorModeValue,
} from "native-base";
import * as React from "react";
import { ThemeProvider as StyledThemeProvider } from "styled-components/native";
import {
  DARK_MODE,
  getThemeColors,
  LIGHT_MODE,
  Theme,
} from "../../constants/Colors";
import * as Cache from "../../services/cache";
type ThemeContextValue = [Theme, (theme : Theme) => void, any];

const initialValue: ThemeContextValue = [
  LIGHT_MODE,
  (theme: Theme) => {
    Cache.set(
      THEME_CACHE_KEY,
      theme === DARK_MODE ? DARK_MODE : LIGHT_MODE
    );
  },
  getThemeColors(LIGHT_MODE),
];

const highContrastBlue = {
  50: "#e9eeff",
  100: "#c4ccf0",
  200: "#9faae1",
  300: "#7a88d2",
  400: "#5566c4",
  500: "#3b4caa",
  600: "#2d3b85",
  700: "#1f2a61",
  800: "#11193d",
  900: "#05071a",
};

const APP_THEMES = {
  [DARK_MODE]: {
    bgColor: "#000",
    appBgColor: "#373737",
    bgColorContrast: "#333131",
    textColor: "#FFF",
    textLightColor: "#BBB",
    lineColor: "#CCC",
  },
  [LIGHT_MODE]: {
    bgColor: "#FFF",
    appBgColor: "#F3F3F3",
    bgColorContrast: "#FFF",
    textColor: "#000",
    textLightColor: "#606060",
    lineColor: "#CCC",
  },
};

export type AppTheme = {
  appBgColor: string;
  bgColor: string;
  bgColorContrast: string;
  textColor: string;
  textLightColor: string;
  lineColor: string;
};

export const ThemeContext = React.createContext(initialValue);

const THEME_CACHE_KEY = "THEME_CACHE_KEY";

const colorModeManager = {
  async get() {
    try {
      const color = await Cache.get(THEME_CACHE_KEY)
      return color === null || undefined || "" ? LIGHT_MODE : color ;
    } catch (e) {
      return LIGHT_MODE;
    }
  },
  async set(value: ColorMode) {
    try {
      await Cache.set(THEME_CACHE_KEY, value as string);
    } catch (e) {
      console.log(e);
    }
  },
};

const theme = extendTheme({
  colors: {
    primary: highContrastBlue,
  },
  fontConfig: {
    Roboto: {
      100: {
        normal: "Roboto_Light",
        italic: "Roboto_Light_Italic",
      },
      200: {
        normal: "Roboto_Light",
        italic: "Roboto_Light_Italic",
      },
      300: {
        normal: "Roboto_Light",
        italic: "Roboto_Light_Italic",
      },
      400: {
        normal: "Roboto_400Regular",
        italic: "Roboto_400Regular_Italic",
      },
      500: {
        normal: "Roboto_400Regular",
      },
      600: {
        normal: "Roboto_400Regular",
        italic: "Roboto_400Regular_Italic",
      },
      700: {
        normal: "Roboto_Bold",
      },
      800: {
        normal: "Roboto_Bold",
        italic: "Roboto_BoldI_talic",
      },
      900: {
        normal: "Roboto_Bold",
        italic: "Roboto_BoldI_talic",
      },
    },
  },
  fonts: {
    heading: "Roboto",
    body: "Roboto",
    mono: "Roboto",
  },
  components: {
    Button: {
      baseStyle: {
        textTransform: "uppercase",
      },
    },
  },  
});

export const ThemeProvider: React.FC<{ baseColor?: string }> = (props) => (
  <NativeBaseProvider colorModeManager={colorModeManager} theme={theme}>
    <InternalThemeProvider {...props} />
  </NativeBaseProvider>
);

const InternalThemeProvider: React.FC<{ baseColor?: string }> = ({
  children,
  baseColor,
}) => {
  const { colorMode: selected, toggleColorMode: toggleTheme } = useColorMode();

  const value = React.useMemo<ThemeContextValue>(
    () => [
      selected as Theme,
      toggleTheme,
      getThemeColors(selected as Theme, baseColor),
    ],
    [selected, toggleTheme, baseColor]
  );

  return (
    <StyledThemeProvider theme={APP_THEMES[selected as Theme]}>
      <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
    </StyledThemeProvider>
  );
};
