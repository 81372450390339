import { useEffect } from "react";

export type KeyMap = {
  [key in KeyboardEvent["key"]]: (event: KeyboardEvent) => void;
};

export default function useKeyboardEvent(keyMap: KeyMap) {
  useEffect(() => {
    function handler(e: KeyboardEvent) {
      Object.entries(keyMap).forEach(([key, fn]) => {
        if (key === e.key) {
          fn(e);
        }
      });
    }
    document.addEventListener("keydown", handler, true);
    return () => {
      document.removeEventListener("keydown", handler, true);
    };
  }, [keyMap]);
}
