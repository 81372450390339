import * as React from "react";
import styled from "styled-components/native";
import { ActivityIndicator, NativeModules, Platform, Dimensions } from "react-native";
import { Button, ScrollView, Text as ButtonText } from "native-base";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { format } from "date-fns";
import { CardHolderContext } from "../contexts/card-holder";
import { useTranslation } from "../contexts/translations";
import { profileFields } from "../constants/fixtures";
import { View, Text, Icon, Divider, Label, Image } from "./Styled";
import useIsMobile from "../hooks/useIsMobile";
import { useStartupConfig, useStartupUser, useStartupProfile, useStartupState } from "../contexts/startup/hooks";
import { getBase64 } from "../utils";
import ImageWithRatio from "./ImageWithRatio";
import { PhotoShape, PORTRAIT, ROUND } from "../constants/photo-shape";
import { MainStackParams } from "../navigation/types";
import { DARK_MODE } from "../constants/Colors";
import { useTheme } from "../contexts/theme/hooks";
import * as API from "../services/api";
import { useAuth } from "../contexts/auth/hooks";
import * as Cache from "../services/cache";

const AVATAR_SIZE = 140;

type ChangePhotoButton = { large?: boolean; shape?: PhotoShape };
const ChangePhotoButton = styled.TouchableOpacity<ChangePhotoButton>`
  border: 1px solid ${(props) => props.theme.lineColor};
  margin: 5px 0 0;
  background: white;
  padding: 0 5px;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  border-radius: 3px;
  line-height: 16px;

  ${(props) =>
    props.large &&
    `
    width: ${AVATAR_SIZE}px;
    height: ${props.shape === PORTRAIT ? AVATAR_SIZE * 1.25 : AVATAR_SIZE}px;
    border-radius: ${props.shape === PORTRAIT ? "5px" : AVATAR_SIZE / 2};
  `}
`;

type SignatureButton = {};
const SignatureButton = styled.View<ChangePhotoButton>`
  border: 1px dashed ${(props) => props.theme.lineColor};
  margin-top: 10px;
  background: white;
  padding: 10px 0;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  border-radius: 3px;
`;

type PersonFields = {
  attributeId: string,
  isEnum: boolean,
  name: string,
  translation: string,
  uneditable: boolean
}

function PersonalDetails() {
  const navigation = useNavigation<StackNavigationProp<MainStackParams>>();
  const {
    allowUserInfoEdit,
    allowUserUploadPhoto,
    allowUserUploadSignature,
    signatureRequiresApproval,
    showSignature,
    cropRatio,
    photoZoomToFit,
    photoShape,
    signatureIsMandatory
  } = useStartupConfig();
  const { hasConfirmedSignature, signature, signatureApproved, photo, birthdate } =
    useStartupUser() || {};
  const profile = useStartupProfile();
  const [{ data, isLoading, error }] = React.useContext(CardHolderContext);
  const { t } = useTranslation();
  const hasError = !data;
  const isMobile = useIsMobile();
  const [theme] = useTheme();
  const [btnAdjust, setBtnAdjust] = React.useState<boolean>(!isMobile && Dimensions.get('window').width <= 750);
  const [cardholderVisible, setCardholderVisible] = React.useState<PersonFields[]>([]);
  const [profileVisible, setProfileVisible] = React.useState<PersonFields[]>([]);
  const { refreshAuth } = useAuth();

  const [addressField, setAddressField] = React.useState(null);
  const [systemLabels, setSystemLabels] = React.useState<any[]>([]);
  const [isLoadingDetails, setIsLoadingDetails] = React.useState(true);

  var moment = require('moment');

  React.useEffect(() => {
    (async () => {
      await refreshAuth();

      const concurrentRequests = [
        API.getDetailsField(),
        API.getSystemLabels(),
      ];

      var cachedConcurrentRequests = await Cache.get("concurrentRequests");

      Promise.allSettled(concurrentRequests)
        .then((result) => {
          var rejected = result[0].status == "rejected" && result[1].status == "rejected";

          if (!rejected) {
            setConcurrentRequests(result);
            Cache.set("concurrentRequests", JSON.stringify(result));
          } else {
            setConcurrentRequests(cachedConcurrentRequests);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsLoadingDetails(false)
        });
    })()
  }, [])

  function setConcurrentRequests(result: any) {
    if (result[0]?.status === "fulfilled") {
      const dtField = result[0].value;
      if (dtField.data) {
        const person = dtField.data.person;
        const cardHolder = dtField.data.cardholder;
        setAddressField(dtField.data.address)
        setCardholderVisible([].concat(cardHolder.visible, cardHolder.editable));
        setProfileVisible([].concat(person.visible, person.editable));
      }
    }

    if (result[1]?.status === "fulfilled") {
      const sysLabels = result[1].value;
      setSystemLabels(sysLabels.data);
    }
    setIsLoadingDetails(false)
  }

  React.useEffect(() => {
    if (!isMobile) {
      Dimensions.addEventListener('change', () => {
        if (Dimensions.get('window').width <= 750) {
          setBtnAdjust(true);
        } else {
          setBtnAdjust(false);
        }
      });
    }
  }, []);

  const getFieldLabel = (field: any) => {
    if (systemLabels.length > 0) {
      let sysLabel = systemLabels.find(x => x.attributeId == field.attributeId);
      return (sysLabel != null) ? sysLabel.label : field.translation;
    } else {
      return field.translation;
    }
  }

  const lowerCaseFirst = (str: any) => {
    return str.charAt(0).toLowerCase() + str.slice(1);
  };

  const getProfileValue = (field: any) => {
    const cprofile = data?.cardholderProfile ? data?.cardholderProfile : profile
    const prof = cprofile[lowerCaseFirst(field.name)];
    if (prof) {
      if (typeof (prof) === 'string') {
        return prof;
      } else {
        return prof?.name
      }
    } else {
      return "-"
    }
  }

  function dateIsValid(date: string | number | Date) {
    return !Number.isNaN(new Date(date).getTime());
  }

  return (
    <View $fullscreen as={ScrollView}>
      {isLoadingDetails && (
        <View $transparent height={30} justifyContent="center">
          <ActivityIndicator
            accessible
            accessibilityLabel={t("accessibility.loading", {
              text: t("tabs.profile_edit"),
            })}
          />
        </View>
      )}
      {data?.pendingRequests && data?.pendingRequests.length > 0 && (
        <View
          height={30}
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
        // bgColor="rgba(200,200,200,0.2)"
        >
          <Icon as={MaterialCommunityIcons} name="alert-circle" size={18} />
          <Text>{t("notifications.pending_request")}</Text>
        </View>
      )}
      {hasError && !data && (
        <View $transparent height={400} justifyContent="center">
          <Text $textAlign="center">{t("profile.not_available")}</Text>
        </View>
      )}
      {!!data && (
        <View $transparent space={10}>
          <View $transparent flexDirection={btnAdjust ? "column" : "row"}>
            <View $transparent spaceHorizontal={15}>
              {photo ? (
                <View $transparent alignItems="center">
                  <ImageWithRatio
                    size={AVATAR_SIZE}
                    source={{ uri: `data:image/jpeg;base64,${photo}` }}
                    ratio={photoShape === ROUND ? 1 : cropRatio}
                    crop={photoZoomToFit}
                    shape={photoShape}
                  />
                  {allowUserUploadPhoto && (
                    <Button
                      mt={2}
                      colorScheme="black"
                      variant="outline"
                      size="sm"
                      accessible
                      accessibilityLabel={t("accessibility.change_photo")}
                      accessibilityRole="button"
                      leftIcon={
                        <Icon
                          as={MaterialCommunityIcons}
                          name="camera-outline"
                          size={16}
                          color={theme === DARK_MODE ? "white" : "black"}
                        />
                      }
                      disabled={!allowUserUploadPhoto}
                      onPress={() => navigation.navigate("ProfilePhoto")}
                    >
                      <Text
                        size={12}
                      >
                        {t("profile.change_photo")}
                      </Text>
                    </Button>
                  )}
                </View>
              ) : (
                <ChangePhotoButton
                  large
                  onPress={() => navigation.navigate("ProfilePhoto")}
                  disabled={!allowUserUploadPhoto}
                  shape={photoShape}
                  accessible
                  accessibilityLabel={t("accessibility.add_photo")}
                  accessibilityRole="button"
                >
                  {allowUserUploadPhoto ? (
                    <>
                      <Icon
                        as={MaterialCommunityIcons}
                        name="camera-outline"
                        size={18}
                        color="black"
                      />
                      <Text
                        color="black"
                        $uppercase
                        size={12}
                      >
                        {t("profile.add_photo")}
                      </Text>
                    </>
                  ) : (
                    <Icon
                      as={MaterialCommunityIcons}
                      name="account"
                      size={80}
                    />
                  )}
                </ChangePhotoButton>
              )}
            </View>
            <View $transparent style={btnAdjust ? { width: "100%", marginHorizontal: 0, marginTop: 5 } : null} justifyContent="center">
              {allowUserInfoEdit && (
                <Button
                  size="sm"
                  onPress={() => navigation.navigate("EditProfile")}
                >
                  {t("tabs.profile_edit")}
                </Button>
              )}
              {hasConfirmedSignature && !!signature && (
                <View spaceTop={10} alignItems="center" $transparent>
                  {signatureRequiresApproval && !signatureApproved && (
                    <Text>{t("signature_upload.pending_approval")}</Text>
                  )}
                </View>
              )}
              {
                showSignature &&
                <>
                  {!!signature ? (
                    <SignatureButton>
                      <Image
                        source={{ uri: getBase64(signature) }}
                        // width={10}
                        // height={7}
                        style={{ height: 65, width: 180 }}
                        resizeMode="contain"
                      />
                    </SignatureButton>
                  ) : signatureIsMandatory && (
                    <Button
                      mt={2}
                      colorScheme="black"
                      variant="outline"
                      size="sm"
                      accessible
                      accessibilityLabel={t("tabs.upload_signature")}
                      accessibilityRole="button"
                      onPress={() => navigation.navigate("SignatureUpload")}
                      disabled={!allowUserUploadSignature}
                    >
                      <Text $uppercase size={12}>
                        {t("tabs.upload_signature")}
                      </Text>
                    </Button>
                  )}
                  {
                    signature && signatureIsMandatory &&
                    <Button
                      mt={2}
                      colorScheme="black"
                      variant="outline"
                      size="sm"
                      accessible
                      accessibilityLabel={t("accessibility.profile.edit_signature")}
                      accessibilityRole="button"
                      onPress={() => navigation.navigate("SignatureUpload")}
                      disabled={!allowUserUploadSignature}
                    >
                      <Text
                        $uppercase
                        size={12}
                      >
                        {t("profile.change_signature")}
                      </Text>
                    </Button>
                  }
                </>
              }
            </View>
          </View>

          <View $transparent={isMobile} spaceVertical={20}>
            {profileVisible.map((field, fieldKey) => (
              <View $transparent key={field.attributeId} space={10}>
                <Label>{getFieldLabel(field)}</Label>
                <Text size={16}>{
                  field.name === "BirthDate" && data[lowerCaseFirst(field.name)] ?
                    dateIsValid(data[lowerCaseFirst(field.name)]) ? moment.utc(data[lowerCaseFirst(field.name)]).format("MMMM DD, YYYY") : moment.utc(birthdate).format("MMMM DD, YYYY") :
                    !data[lowerCaseFirst(field.name)] || data[lowerCaseFirst(field.name)] === "" ? "-"
                      : data[lowerCaseFirst(field.name)]
                }
                </Text>
                <Divider $fullSize spaceVertical={3} />
              </View>
            ))}
            {cardholderVisible.map((field, fieldKey) => (
              <View $transparent key={field.attributeId} space={10}>
                <Label>{getFieldLabel(field)}</Label>
                <Text size={16}>{getProfileValue(field)}
                </Text>
                <Divider $fullSize spaceVertical={3} />
              </View>
            ))}

            {
              addressField?.showAddress &&
              <View $transparent space={10}>
                <Label>{t(`profile.address`)}</Label>
                <Text size={16}>{data["address"] || "-"}</Text>
              </View>
            }
          </View>
        </View>
      )}
    </View>
  );
}

export default PersonalDetails;
