import * as React from "react";
import { StyleSheet } from "react-native";
import { useNavigation } from "@react-navigation/native";
import {
  Button,
  FormControl,
  Stack,
  Toast,
} from "native-base";

import { Label, Input, View, Text } from "../components/Styled";
import { useTranslation } from "../contexts/translations";
import { changePassword } from "../services/api";

type Field = "currentPassword" | "newPassword" | "confirmationPassword";


let idCounter = 0;

const generateUniqueId = () => {
  idCounter += 1;
  return `input_${idCounter}`;
};


export default function ResetPasswordScreen() {
  const navigation = useNavigation();
  const { t } = useTranslation();
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [values, setValues] = React.useState({
    currentPassword: "",
    newPassword: "",
    confirmationPassword: "",
  });
  const [errors, setErrors] = React.useState({
    currentPassword: "",
    newPassword: "",
    confirmationPassword: "",
  });

  const isValid =
    values.currentPassword !== "" &&
    values.newPassword !== "" &&
    values.newPassword === values.confirmationPassword;

  const handleChange = (field: Field, value: string | number) => {
    setValues((current) => ({
      ...current,
      [field]: value,
    }));
    setErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
  };

  const handleSubmit = async () => {
    const validationErrors = {};

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setSubmitting(true);

      try {
        const res = await changePassword(
          values.currentPassword,
          values.newPassword
        );

        if (res.status === 200) {
          Toast.show({
            accessible: true,
            accessibilityLabel: t("reset_password.success"),
            duration: 8000,
            render: () => <View bgColor="green" style={{ padding: 10 }}>
              <Text color="white">{t("reset_password.success")}</Text>
            </View>
          })
          navigation.goBack();
        }
      } catch (err) {
        if (err.response?.data?.errors) {
          Object.values(err.response.data.errors).forEach((error) => {
            Toast.show({
              accessible: true,
              accessibilityLabel: t("reset_password.error"),
              duration: 8000,
              render: () => <View bgColor="orange" style={{ padding: 10 }}>
                <Text color="white">{error as string}</Text>
              </View>
            })
          });
        } else {
          Toast.show({
            accessible: true,
            accessibilityLabel: t("reset_password.error"),
            duration: 8000,
            render: () => <View bgColor="orange" style={{ padding: 10 }}>
              <Text color="white">{t("reset_password.error")}</Text>
            </View>
          })
        }
      }

      setSubmitting(false);
    }
  };

  return (
    <FormControl style={{ marginTop: 80 }}>
      <Stack style={styles.field}>
        <Label as={FormControl.Label}>
          {t("reset_password.current_password")}
        </Label>
        <Input
          secureTextEntry
          textContentType="password"
          value={values.currentPassword}
          onChangeText={(value) => handleChange("currentPassword", value)}
          returnKeyType="done"
          isInvalid={!!errors.currentPassword}
          accessibilityLabel={t('reset_password.current_password')}
          aria-aria-labelledby={generateUniqueId()}
        />
        {!!errors.newPassword && <Text>{errors.newPassword}</Text>}
      </Stack>
      <Stack style={styles.field}>
        <Label as={FormControl.Label}>{t("reset_password.new_password")}</Label>
        <Input
          secureTextEntry
          textContentType="newPassword"
          value={values.newPassword}
          onChangeText={(value) => handleChange("newPassword", value)}
          returnKeyType="done"
          isInvalid={!!errors.newPassword}
          accessibilityLabel={t('reset_password.new_password')}
          aria-aria-labelledby={generateUniqueId()}
        />
        {!!errors.newPassword && <Text>{errors.newPassword}</Text>}
      </Stack>
      <Stack style={styles.field}>
        <Label as={FormControl.Label}>
          {t("reset_password.confirmation_password")}
        </Label>
        <Input
          secureTextEntry
          textContentType="password"
          value={values.confirmationPassword}
          onChangeText={(value) => handleChange("confirmationPassword", value)}
          returnKeyType="done"
          isInvalid={!!errors.confirmationPassword}
          accessibilityLabel={t('reset_password.confirmation_password')}
          aria-aria-labelledby={generateUniqueId()}
        />
        {!!errors.confirmationPassword && <Text>{errors.confirmationPassword}</Text>}
      </Stack>
      <Button
        disabled={!isValid || isSubmitting}
        onPress={handleSubmit}
        style={styles.button}
      >
        {t("reset_password.submit")}
      </Button>
    </FormControl>
  );
}

const styles = StyleSheet.create({
  field: {
    marginBottom: 20,
  },
  button: {
    marginHorizontal: 20,
    marginBottom: 20,
  },
});
