import { ConfigType } from "../contexts/startup";

const FeatureFlags = {
  CONFIRM_IDENTITY: false,
  CONFIRM_SIGNATURE: false,
  MANAGE_CARD: true,
  PHOTO_ZOOM_TO_FIT: false,
  RESET_PASSWORD: true,
  SET_PIN: true,
  SHOW_LOGO_ON_DIGITAL_ID: true,
  UPLOAD_PHOTO: true,
};

const mapFeatureToConstant = {
  hasIdentityConfirmation: "CONFIRM_IDENTITY",
  hasPin: "SET_PIN",
  hasSignatureConfirmation: "CONFIRM_SIGNATURE",
  photoZoomToFit: "PHOTO_ZOOM_TO_FIT",
  showLogoOnDigitalid: "SHOW_LOGO_ON_DIGITAL_ID",
} as const;

type FeatureFlagKeys = keyof typeof FeatureFlags;

export type FeatureFlagType = {
  [key in FeatureFlagKeys]: boolean;
};

export const mergeFeatures = (
  base: ConfigType | null,
  features: FeatureFlagType,
  overwrites: Partial<FeatureFlagType>
): FeatureFlagType => ({
  ...Object.entries(base || {}).reduce(
    (result, [key, value]) => ({
      ...(result || {}),
      [mapFeatureToConstant[key as keyof typeof mapFeatureToConstant]]: Boolean(
        value
      ),
    }),
    features || {}
  ),
  ...overwrites,
});

export default FeatureFlags;
