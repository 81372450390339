import * as React from "react";
import * as Localization from "expo-localization";
import i18n from "i18n-js";
import * as Cache from "../services/cache";

import en from "../locales/en.json";
import pt from "../locales/pt.json";
import de from "../locales/de.json";
import es from "../locales/es.json";
import fr from "../locales/fr.json";
import it from "../locales/it.json";
import nl from "../locales/nl.json";

const LOCAL_CACHE_KEY = "LOCAL_CACHE_KEY";

type i18nTranslations = {
  [key: string]: object;
};

export type Locale = "en" | "pt" | "de" | "es" | "fr" | "it" | "nl";

type TranslationContextValue = {
  t: any;
  locale: Locale | null;
  locales: Locale[];
  setLocale: (localeStr: Locale | string) => void;
};

i18n.translations = {
  en,
  pt,
  de,
  es,
  fr,
  it,
  nl,
} as i18nTranslations;

i18n.locale = Localization.locale.substring(0, 2);
i18n.defaultLocale = "en";

const availableLocales = Object.keys(i18n.translations) as Locale[];

const initialValue = {
  t: i18n.t,
  locale: i18n.locale,
  locales: availableLocales,
  setLocale: (localeStr: Locale) => {
    i18n.locale = localeStr;
    Cache.set(LOCAL_CACHE_KEY, localeStr)
  },
};

const TranslationContext =
  React.createContext<TranslationContextValue>(initialValue);

const getLocaleValueOrDefault = (locale: Locale = i18n.locale) =>
  availableLocales.includes(locale) ? locale : "en";

export const useTranslation = () => React.useContext(TranslationContext);

export const TranslationProvider: React.FC = ({ children }) => {
  const [locale, setLocale] = React.useState<Locale | null>(null);
  const value = {
    t: i18n.t,
    locale,
    locales: availableLocales,
    setLocale: (localeStr: Locale) => {
      i18n.locale = localeStr;
      setLocale(localeStr);
      Cache.set(LOCAL_CACHE_KEY, localeStr)
    },
  };

  React.useEffect(() => {
    (async () => {
      const cachedLocale = await Cache.get(LOCAL_CACHE_KEY);
      const newLocale = getLocaleValueOrDefault(cachedLocale || i18n.locale);
      i18n.locale = newLocale;
      setLocale(newLocale);
      console.log(locale)
      if(cachedLocale === null || undefined || "") await Cache.set(LOCAL_CACHE_KEY, newLocale)
    })();
  }, []);

  return locale ? (
    <TranslationContext.Provider value={value}>
      {children}
    </TranslationContext.Provider>
  ) : null;
};
