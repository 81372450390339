import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Button } from "native-base";
import * as React from "react";
import { Image, StyleSheet } from "react-native";
import { View, Text, Icon } from "./Styled";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { MainStackParams } from "../navigation/types";
import { useTranslation } from "../contexts/translations";
import {
    useStartupNotifications,
    useStartupUser,
    useStartupConfig,
    useDigitalCard
  } from "../contexts/startup/hooks";
import useIsMobile from "../hooks/useIsMobile";
import styled from "styled-components/native";
import { ACTIVATED } from "../constants/card-status";
import { SALTO } from "../contexts/startup/constants/digitalSystem";

const ThemedIcon = styled(Icon)`
  color: ${(props) => props.theme.textColor};
  font-size: 25px;
`;

const Badge = styled.View`
    position: absolute;
    top:-6px;
    right:1px;
    height: 18px;
    width: 18px;
    background-color: red;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    background-color: #fa3e3e;
`;

const BottomTab = (props: any) => {
    const navigation = useNavigation<StackNavigationProp<MainStackParams>>();
    const { t } = useTranslation();
    const { unreadCount = 0 } = useStartupNotifications();
    const user = useStartupUser() || {};
    const isMobile = useIsMobile();
    const { showIdButtonInStand } = useStartupConfig();
    const [isSalto, setIsSalto] = React.useState<boolean>(false);
    const digitalCard = useDigitalCard();

    const getName = ({ firstName = "", lastName = "" } = {}) =>
    firstName && lastName
        ? `${firstName.substring(0, 1)}. ${lastName.split(" ")[0]}`
        : "Unknown";

    
    const tabNames = [
        {name:"id", icon: "card-account-details", route:"IdSettings", accessibilityLabel: "accessibility.header.id"},
        {name:"details", icon: "account-circle", route:"Profile", accessibilityLabel:t("accessibility.header.details", {
            name: getName(user),
        })},
        {name:"notifications", icon: "bell", route:"Notifications", accessibilityLabel: t("accessibility.header.notifications", {
            count: unreadCount,
        })},
        {name:"settings", icon: "cog", route:"Settings", accessibilityLabel:t("accessibility.header.settings")}
    ]

    return (
        <View flexDirection="row" height={"7%"}>
            {
                showIdButtonInStand &&
                <Button colorScheme="primary" 
                    variant={"unstyled"} flex={1} 
                    onPress={() => navigation.navigate("IdSettings")}
                    accessible
                    accessibilityLabel={t("accessibility.header.id")}
                >
                    <View style={styles.buttonstyle}>
                        <ThemedIcon as={MaterialCommunityIcons} name={"card-account-details"}/>
                        <Text size={8} $textAlign="center">{t("tabs.id")}</Text>
                    </View>
                </Button>
            }
            <Button colorScheme="primary" 
                variant={"unstyled"} flex={1} 
                onPress={() => navigation.navigate("Profile")}
                accessible
                accessibilityLabel={t("accessibility.header.details", {
                    name: getName(user),
                })}
            >
                <View style={styles.buttonstyle}>
                    <ThemedIcon as={MaterialCommunityIcons} name={"account-circle"}/>
                    <Text size={8} $textAlign="center">{t("tabs.details")}</Text>
                 </View>
            </Button>

            {(digitalCard?.type == SALTO && digitalCard?.status == ACTIVATED) && 
            (<Button colorScheme="primary" 
                variant={"unstyled"} flex={1} 
                onPress={() => navigation.navigate("Keys")}
                accessible
                accessibilityLabel={t("accessibility.header.keys", {
                    name: getName(user),
                })}
            >
                <View style={styles.buttonstyle}>
                    <ThemedIcon as={MaterialCommunityIcons} name={"key-variant"}/>
                    <Text size={8} $textAlign="center">{t("tabs.keys")}</Text>
                 </View>
            </Button>)}

            

            <Button colorScheme="primary" 
                variant={"unstyled"} flex={1} 
                onPress={() => navigation.navigate("Notifications")}
                accessible
                accessibilityLabel={t("accessibility.header.notifications", {
                    count: unreadCount,
                })}
            >
                <View style={styles.buttonstyle}>
                    {isMobile && unreadCount > 0 && <Badge><Text color="white" size={12}>{unreadCount}</Text></Badge>}
                    <ThemedIcon as={MaterialCommunityIcons} name={"bell"}/>
                    <Text size={8} $textAlign="center">{t("tabs.notifications")}</Text>
                 </View>
            </Button>
            <Button colorScheme="primary" 
                variant={"unstyled"} flex={1} 
                onPress={() => navigation.navigate("Settings")}
                accessible
                accessibilityLabel={t("accessibility.header.settings")}
            >
                <View style={styles.buttonstyle}>
                    <ThemedIcon as={MaterialCommunityIcons} name={"cog"}/>
                    <Text size={8} $textAlign="center">{t("tabs.settings")}</Text>
                 </View>
            </Button>
        </View>
    )
};

export default BottomTab;

const styles = StyleSheet.create({
    buttonstyle: {
        flex:1,
        justifyContent:'center',
        alignItems:'center'
    },
  });
  