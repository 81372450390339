import { format } from "date-fns";
import * as React from "react";
import { Text, View } from "./Styled";

const QRCodeSecurityWrapper = ({ children }: { children: React.ReactChild }) => {
  const [time, setTime] = React.useState(new Date());
  React.useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, []);
  return (
    <View $transparent>
      {children}
      <Text
        style={{
          fontSize: 11,
          color: "#000",
          textAlign: 'center',
          marginBottom: 5
        }}
      >
        {format(time, "H:mm:ss")}
      </Text>
    </View>
  );
};

export default QRCodeSecurityWrapper;
