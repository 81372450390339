import { format } from "date-fns";

export const fakeProfileData: { [key: string]: string } = {
  email: "itseeds@cardexchangesolutions.com",
  phoneNumber: "+1 (925) 430 4424",
  address: `622 Home Lone Ct
  City CA 94665
  United States`,
  birthDate: format(new Date(), "P"),
};

export const profileFields = [
  "firstName",
  "lastName",
  "middleName",
  "email",
  "phoneNumber",
  "mobileNumber",
  "birthDate",
  "profileName",
  "gender",
  "prefix",
  "suffix",
  "location"
] as const;

export const cardHolderFields = [
  "businessUnit",
  "externalCompany",
  "department",
  "costAccount",
  "jobTitle",
  "nameOnCard",
  "note",
  "freeField1",
  "freeField2"
] as const;

export const fieldsKeyboardType = {
  firstName: "default",
  lastName: "default",
  phoneNumber: "phone-pad",
  mobileNumber: "phone-pad",
  birthDate: "numeric",
} as const;

export const editableFields = Object.keys(fieldsKeyboardType);
