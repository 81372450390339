import "@expo/match-media";
import React from "react";
import "expo-dev-launcher";
import "expo-dev-menu";
import "expo-dev-client";

import Providers from "./components/Providers";
import App from "./components/App";
import useCachedResources from "./hooks/useCachedResources";

export default function Entry() {
  const isReady = useCachedResources();

  return isReady ? (
    <Providers>
      {/* <IdentityUploadScreen> */}
        <App />
      {/* </IdentityUploadScreen> */}
    </Providers>
  ) : (
    // <AppLoading />
    null
  );
}