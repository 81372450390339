import { useEffect } from "react";
import Constants from "expo-constants";
import * as Notifications from "expo-notifications";
import { Platform } from "react-native";
import * as API from "../services/api";
import * as Cache from "../services/cache";
import { useAuth } from "../contexts/auth/hooks";

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
});

export const PUSH_TOKEN = "push_token";

export default function usePushNotification() {
  const { refreshAuth } = useAuth();
  useEffect(() => {
    registerForPushNotificationsAsync().then(async (token?: string) => {
      if (token) {
        try {
          await refreshAuth();
          await Cache.set(PUSH_TOKEN, token);
          await API.pushToken(token);

        } catch (err) {
          console.warn("Failed setting push token", err.response.data);
        }
      }
    });
  }, []);
}

async function registerForPushNotificationsAsync() {
  let token;
  if (Platform.OS === "web") {
    return;
  }
  if (Constants.isDevice) {
    const { status: existingStatus } = await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== 'granted') {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus !== 'granted') {
      console.error('Failed to get push token for push notification!');
      return;
    }
    token = (await Notifications.getExpoPushTokenAsync()).data;
    
    // const { status: existingStatus } = await Permissions.getAsync(
    //   Permissions.NOTIFICATIONS
    // );
    // let finalStatus = existingStatus;
    // if (existingStatus !== "granted") {
    //   const { status } = await Permissions.askAsync(Permissions.NOTIFICATIONS);
    //   finalStatus = status;
    // }
    // if (finalStatus !== "granted") {
    //   console.error("Failed requesting permissions for push notification");
    //   return;
    // }
    // token = (await Notifications.getExpoPushTokenAsync()).data;
  } else {
    console.warn("Push notification not supported");
  }

  if (Platform.OS === "android") {
    Notifications.setNotificationChannelAsync("default", {
      name: "default",
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: "#5dc9447C",
    });
  }

  return token;
}
