import { getBaseAuthUrl } from "./base-urls";
import { Env } from "./envs";
import { Region } from "./regions";
import * as Linking from 'expo-linking';

const scopes = [
  "openid",
  "profile",
  "email",
  "offline_access",
  "roles",
  "customer",
  "subscription",
  "api1",
  "IdentityServerApi",
];
const clientId = "standMobApp";
const redirectUri = Linking.createURL('');
const usePKCE = false;
// const responseType = ResponseType.Token;

export const discovery = (env: Env) => {
  const oidcBaseUrl = getBaseAuthUrl(env);
  return {
    authorizationEndpoint: `${oidcBaseUrl}/connect/authorize`,
    tokenEndpoint: `${oidcBaseUrl}/connect/token`,
    revocationEndpoint: `${oidcBaseUrl}/connect/revocation`,
    forgotPassword: `${oidcBaseUrl}/Account/ForgetPassword`,
    // endSessionEndpoint: `${oidcBaseUrl}/Account/Logout`,
    endSessionEndpoint: `${oidcBaseUrl}/connect/endsession`,
    loginSessionEndpoint: `${oidcBaseUrl}/Account/Login`,
  };
};

export const auth = () => ({
  scopes,
  clientId,
  redirectUri,
  usePKCE,
  // responseType,
});
