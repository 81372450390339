import * as React from "react";
import { ActivityIndicator, Platform } from "react-native";
import { FormControl, Button, Stack, Icon, ScrollView, Select } from "native-base";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { FontAwesome } from "@expo/vector-icons";
import * as API from "../services/api";

import { CardHolderContext, UserProfileData } from "../contexts/card-holder";
import { View, Text, Input } from "./Styled";
import { useTranslation } from "../contexts/translations";
import {
  editableFields,
  fieldsKeyboardType,
  profileFields,
  cardHolderFields
} from "../constants/fixtures";
import DatePicker from "./DatePicker";
import { MainStackParams } from "../navigation/types";
import { useTheme } from "../contexts/theme/hooks";
import { DARK_MODE } from "../constants/Colors";
import { useStartupConfig, useStartupUser, useStartupProfile } from "../contexts/startup/hooks";
import { useAuth } from "../contexts/auth/hooks";

type Field = typeof profileFields[number];
type FieldsState = Partial<UserProfileData>;

function getFieldTypeKeyboard(field: string) {
  return Object.keys(fieldsKeyboardType).includes(field)
    ? fieldsKeyboardType[field as keyof typeof fieldsKeyboardType]
    : "default";
}

type Locations = {
  createdDateUtc: string,
  description: string,
  id: string,
  modifiedDateUtc: string,
  name: string
}

type PersonFields = {
  attributeId: string,
  isEnum: boolean,
  name: string,
  translation: string,
  uneditable: boolean
}

function PersonalDetailsForm({
  isSubmitting,
  onChange,
  onSave,
}: {
  isSubmitting: boolean;
  onChange: (field: Field | string, value: string | number) => void;
  onSave: (data: Partial<UserProfileData>, cardHolderData: Partial<UserProfileData>) => Promise<void>;
}) {
  const navigation = useNavigation<StackNavigationProp<MainStackParams>>();
  const [{ data, isLoading }] = React.useContext(CardHolderContext);
  const { t } = useTranslation();
  const [theme] = useTheme();

  const [loadingDetails, setLoadingDetails] = React.useState<boolean>(true);
  const [listOfOptions, setListOfOptions] = React.useState(null);
  const [profileEditable, setProfileEditable] = React.useState<PersonFields[]>([]);
  const [profileVisible, setProfileVisible] = React.useState<PersonFields[]>([]);

  const [cardHolderEditable, setCardHolderEditable] = React.useState<PersonFields[]>([]);
  const [cardHolderVisible, setCardHolderVisible] = React.useState<PersonFields[]>([]);

  const [addressField, setAddressField] = React.useState(null);
  const profile = useStartupProfile();
  const { birthdate } = useStartupUser() || {};

  const [systemLabels, setSystemLabels] = React.useState<any[]>([]);
  const { refreshAuth } = useAuth();

  const [values, setValues] = React.useState<FieldsState>(
    () =>
      profileFields.reduce(
        (mappedValues, field) =>
          editableFields.includes(field)
            ? {
              ...mappedValues,
              [field]: data && data[field] ? data[field] : "",
            }
            : mappedValues,
        {}
      ) as FieldsState
  );

  const [cardValues, setCardValues] = React.useState<FieldsState>(
    () =>
      cardHolderFields.reduce(
        (mappedValues) =>
          mappedValues,
        {}
      ) as FieldsState
  );

  React.useEffect(() => {
    (async () => {
      setLoadingDetails(true);
      await refreshAuth();

      const concurrentRequests = [
        API.getDetailsField(),
        API.getEnumOptions(),
        API.getSystemLabels(),
      ];

      Promise.allSettled(concurrentRequests)
        .then((result) => {
          if (result[0]?.status === "fulfilled") {
            const dtField = result[0].value;
            if (dtField.data) {
              const person = dtField.data.person;
              const cardHolder = dtField.data.cardholder;
              setAddressField(dtField.data.address)

              setCardHolderEditable(cardHolder.editable)
              setCardHolderVisible([].concat(cardHolder.visible, cardHolder.editable))

              setProfileEditable(person.editable);
              setProfileVisible([].concat(person.visible, person.editable));
            }
          }

          if (result[1]?.status === "fulfilled") {
            const resEnums = result[1].value;
            setListOfOptions(resEnums.data);
          }

          if (result[2]?.status === "fulfilled") {
            const sysLabels = result[2].value;
            setSystemLabels(sysLabels.data);
          }
          setLoadingDetails(false)
        })
        .catch((err) => {
          console.log(err);
          setLoadingDetails(false)
        });
    })()
  }, [])

  const handleCardHolderChange = (field: Field | string, value: string | number) => {
    onChange(field, value);
    setCardValues((current) => ({
      ...current,
      [field]: value,
    }));
  };

  const handleChange = (field: Field | string, value: string | number) => {
    onChange(field, value);
    setValues((current) => ({
      ...current,
      [field]: value,
    }));
  };

  const handleValueChange = (field: Field | string, value: string | number) => {
    onChange(field, value)
    setValues((current) => ({
      ...current,
      [field]: value,
    }));
  }

  const handleCardholderValueChange = (field: Field | string, value: string | number) => {
    onChange(field, value)
    setCardValues((current) => ({
      ...current,
      [field]: value,
    }));
  }

  async function handleSave() {
    onSave(values, cardValues);
  }

  function getValue(field: string) {
    return typeof values[field] !== "undefined"
      ? values[field]
      : data
        ? data[field]
        : "";
  }

  const lowerCaseFirst = (str: any) => {
    return str.charAt(0).toLowerCase() + str.slice(1);
  };

  const getFieldLabel = (field: any) => {
    if (systemLabels.length > 0) {
      let sysLabel = systemLabels.find(x => x.attributeId == field.attributeId);
      return (sysLabel != null) ? sysLabel.label : field.translation;
    } else {
      return field.translation;
    }
  }

  const getProfileValue = (field: any) => {
    const cprofile = data?.cardholderProfile ? data?.cardholderProfile : profile;
    const prof = cprofile[lowerCaseFirst(field.name)];
    if (prof) {
      if (typeof (prof) === 'string') {
        return prof;
      } else {
        return prof?.id
      }
    } else {
      return null;
    }
  }

  function dateIsValid(date: string | number | Date) {
    return !Number.isNaN(new Date(date).getTime());
  }

  return (
    <View $fullscreen as={ScrollView}>
      {isLoading || loadingDetails && <ActivityIndicator />}
      {!data && !isLoading && !loadingDetails && (
        <View $transparent>
          <Text>{t("profile.not_available")}</Text>
        </View>
      )}
      {!!data && !loadingDetails && (
        <FormControl style={{ padding: 10 }}>
          {profileVisible.map((field, fieldKey) => (
            <View key={field.attributeId} spaceBottom={20} $transparent>
              <Stack>
                <FormControl.Label accessible={false}>
                  {getFieldLabel(field)}
                </FormControl.Label>
                {
                  field.name === "BirthDate" ?
                    (
                      <DatePicker
                        value={dateIsValid(getValue(lowerCaseFirst(field.name))) ? getValue(lowerCaseFirst(field.name)) : birthdate}
                        onChange={(value) => handleChange(lowerCaseFirst(field.name), value)}
                        disabled={profileEditable.findIndex(x => x.name === field.name) === -1}
                      />
                    ) : listOfOptions && listOfOptions?.hasOwnProperty(`${lowerCaseFirst(field.name)}Options`) ?
                      <Select
                        h={Platform.OS === "web" ? 9 : undefined}
                        dropdownIcon={<Icon name="caret-down" as={FontAwesome} size={17} />}
                        selectedValue={getValue(lowerCaseFirst(field.name))}
                        placeholder={getFieldLabel(field)}
                        onValueChange={(value) => handleValueChange(lowerCaseFirst(field.name), value)}
                        isDisabled={profileEditable.findIndex(x => x.name === field.name) === -1}
                        defaultValue={getProfileValue(field)}
                      >
                        {
                          listOfOptions[`${lowerCaseFirst(field.name)}Options`].sort((a: { name: string; }, b: { name: string; }) => a.name.localeCompare(b.name)).map((item: { name: string; id: string; }, index: React.Key | null | undefined) =>
                            <Select.Item key={index} label={item.name} value={item.id} />
                          )
                        }
                      </Select> : (
                        <Input
                          accessibilityLabel={getFieldLabel(field)}
                          placeholder={field.name === "MobileNumber" ? "eg: +19222222222" : field.name === "PhoneNumber" ? "Office/Home Number" : getFieldLabel(field)}
                          onChangeText={(value) => handleChange(lowerCaseFirst(field.name), value)}
                          isReadOnly={profileEditable.findIndex(x => x.name === field.name) === -1}
                          keyboardType={getFieldTypeKeyboard(lowerCaseFirst(field.name))}
                          defaultValue={getValue(lowerCaseFirst(field.name))}
                          returnKeyType="done"
                        />
                      )
                }
              </Stack>
            </View>
          ))}

          {cardHolderVisible.map((field, fieldKey) => (
            <View key={field.attributeId} spaceBottom={20} $transparent>
              <Stack>
                <FormControl.Label accessible={false}>
                  {getFieldLabel(field)}
                </FormControl.Label>
                {listOfOptions && listOfOptions?.hasOwnProperty(`${lowerCaseFirst(field.name)}Options`) ?
                  <Select
                    h={Platform.OS === "web" ? 9 : undefined}
                    dropdownIcon={<Icon name="caret-down" as={FontAwesome} size={17} />}
                    selectedValue={getValue(lowerCaseFirst(field.name))}
                    placeholder={getFieldLabel(field)}
                    onValueChange={(value) => handleCardholderValueChange(lowerCaseFirst(field.name), value)}
                    isDisabled={cardHolderEditable.findIndex(x => x.name === field.name) === -1}
                    defaultValue={getProfileValue(field)}
                  >
                    {
                      listOfOptions[`${lowerCaseFirst(field.name)}Options`].sort((a: { name: string; }, b: { name: string; }) => a.name.localeCompare(b.name)).map((item: { name: string; id: string; }, index: React.Key | null | undefined) =>
                        <Select.Item key={index} label={item.name} value={item.id} />
                      )
                    }
                  </Select> : (
                    <Input
                      accessibilityLabel={getFieldLabel(field)}
                      placeholder={getFieldLabel(field)}
                      onChangeText={(value) => handleCardHolderChange(lowerCaseFirst(field.name), value)}
                      isReadOnly={cardHolderEditable.findIndex(x => x.name === field.name) === -1}
                      defaultValue={getProfileValue(field)}
                      returnKeyType="done"
                    />
                  )
                }
              </Stack>
            </View>
          ))}

          {
            addressField?.showAddress &&
            <View spaceBottom={20} $transparent>
              <Stack>
                <FormControl.Label accessible={false}>
                  {t(`profile.address`)}
                </FormControl.Label>
                <Input
                  isReadOnly={!addressField?.allowEditAddress}
                  accessibilityLabel={t(`profile.address`)}
                  placeholder="Street, Locality, Region, Postal code, Country"
                  value={
                    typeof values["address"] !== "undefined"
                      ? values["address"]
                      : data["address"] || ""
                  }
                  onChangeText={(value) => handleChange("address", value)}
                  keyboardType={getFieldTypeKeyboard("address")}
                />
                {
                  addressField?.allowEditAddress &&
                  <Button
                    variant="ghost"
                    leftIcon={<Icon name="home" as={FontAwesome} size={15} />}
                    onPress={() => navigation.navigate("FormAddress")}
                    colorScheme={theme === DARK_MODE ? "blue" : "primary"}
                  // disabled={!addressField?.allowEditAddress}
                  >
                    {data?.addressObject
                      ? t("profile.edit.addresses")
                      : t("profile.edit.add_address")}
                  </Button>
                }
              </Stack>
            </View>
          }

          {isSubmitting ? (
            <View $transparent spaceBottom={10}>
              <ActivityIndicator
                accessible
                accessibilityLabel={t("accessibility.loading", {
                  text: t("tabs.profile"),
                })}
              />
            </View>
          ) :
            <Button
              onPress={handleSave} disabled={isSubmitting} mb={5}
              accessible
              accessibilityLabel={t("accessibility.save")}
              accessibilityRole="button"
            >
              {t("profile.edit.save")}
            </Button>
          }
        </FormControl>
      )}
    </View>
  );
}

export default PersonalDetailsForm;
