import { Heading, Pressable, ScrollView } from "native-base";
import * as React from "react";
import { Linking, Image, ActivityIndicator, Platform , SafeAreaView} from "react-native";
import { Divider, Text, View, Button } from "../components/Styled";
import { useAuth } from "../contexts/auth/hooks";
import { useStartupConfig } from "../contexts/startup/hooks";
import { useTranslation } from "../contexts/translations";
import * as Cache from "../services/cache";
import ClientBrand from "../components/ClientBrand";
import useIsMobile from "../hooks/useIsMobile";
import WizardHeader from "../components/WizardHeader";
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
import * as API from "../services/api";

const CACHE_KEY_PRIVACY_POLICY = "CACHE_KEY_PRIVACY_POLICY";

function PrivacyPolicyScreen({ children }: { children: JSX.Element }) {
  const isMobile = useIsMobile();
  const { privacyPolicyUrl } = useStartupConfig();
  const { t } = useTranslation();
  const { signOut } = useAuth();
  const [hasAccepted, setHasAccepted] = React.useState<boolean | null>();
  const [disabled, setDisabled] = React.useState<boolean>(false);
  const [showPage, setShowPage] = React.useState<boolean | null>();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    (async () => {
        if (__DEV__) {
          try {
            const wSettings = await API.getWizardSettings();
            if (wSettings.data && wSettings.data.privacy) {
              setShowPage(wSettings.data.privacy.show);
            }
          } catch (error) {
            setShowPage(true)
          }
        } else {
          setShowPage(true)
        }
      const hasAcceptedCache = await Cache.get(CACHE_KEY_PRIVACY_POLICY);
      setHasAccepted(!!hasAcceptedCache);
      setIsLoading(false);
    })();
  }, []);

  const handleAccept = async () => {
    try {
      setDisabled(true);
      await Cache.set(CACHE_KEY_PRIVACY_POLICY, "true");
      setHasAccepted(true);
    } catch (err) {
      console.warn("Failed accepting privacy policy", err);
    }
  };

  const SubHeadView = () => {
    return (
      <ScrollView contentContainerStyle={{ flex: 1 }} showsVerticalScrollIndicator={false}>
        <View
          $transparent
          spaceVertical={hp("9%")}
          flexGrow={1}
          width={!isMobile ? wp("23%")  : wp("80%")}
          alignSelf="center"
        >
          <Text $textAlign="center" $responsive size={2}>
            {t("privacy_policy.disclaimer")}{" "}
            <Text
              color={privacyPolicyUrl && "blue"}
              onPress={
                privacyPolicyUrl
                  ? () => Linking.openURL(privacyPolicyUrl)
                  : undefined
              }
            >
              {t("privacy_policy.anchor_text")}
            </Text>
          </Text>
        </View>
      </ScrollView>

    )
  }

  const BottomButtonView = () => {
    return (
      <>
        <Divider $fullSize />
        <View spaceVertical={hp("0.5%")} $transparent flexDirection="row" width={"100%"}>
          <Button
            variant="unstyled"
            alignItems="center"
            justifyContent="center"
            $fullWidth
            height={hp("5%")}
            onPress={() => signOut()}
          >
            <Text color="#2969cf" $responsive size={1.7}>{t("privacy_policy.decline")}</Text>
          </Button>
          <Divider $vertical />
          <Button
            variant="unstyled"
            alignItems="center"
            justifyContent="center"
            $fullWidth
            height={hp("5%")}
            onPress={handleAccept}
            disabled={disabled}
          >
            <Text color="#2969cf" $responsive size={1.7}>{t("privacy_policy.accept")}</Text>
          </Button>
        </View>
      </>
    )
  }

  return hasAccepted === null && showPage === null ? null : 
   showPage === false || showPage === true && hasAccepted ? (
    children
  ) : (
    <View $fullscreen>
      {
        isLoading ?
        <View $transparent flexGrow={1} justifyContent="center">
          <ActivityIndicator
            accessible
            accessibilityLabel={t("accessibility.loading", {
              text: "",
            })}
          />
        </View> :
        isMobile ?
          <SafeAreaView style={{ alignItems: "center", flex: 1 }}>
            <ClientBrand />
            <Text accessibilityRole="header" $bold $textAlign="center" $responsive size={3}>{t("header.privacy")}</Text>
            <SubHeadView />
            <BottomButtonView />
          </SafeAreaView> :
          <View $fullscreen bgColor="#eee">
            <WizardHeader />
            <View bgColor="white"
              spaceVertical={hp("5%")}
              justifyContent="center"
              borderWidth={1}
              borderColor="lightgrey"
              width={wp("50%")}
              alignSelf="center"
              style={{ flex: 1 }}
            >
              <View $transparent spaceTop={hp("10%")}>
                <Text accessibilityRole="header" $bold $textAlign="center" $responsive size={3}>{t("header.privacy")}</Text>
              </View>
              <SubHeadView />
              <View $transparent alignItems="center">
                <BottomButtonView />
              </View>
            </View>
          </View>
      }
    </View>
  );
}

export default PrivacyPolicyScreen;
