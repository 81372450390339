import * as React from "react";
import styled from "styled-components/native";
import { Platform } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { Icon, Text, View } from "./Styled";
import { useTranslation } from "../contexts/translations";
import useKeyboardEvent from "../hooks/useKeyboardEvent";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { DARK_MODE } from "../constants/Colors";
import { useTheme } from "../contexts/theme/hooks";
import { useStartupUser } from "../contexts/startup/hooks";

const Wrapper = styled.SafeAreaView`
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-end;
`;

const Container = styled(View)`
  ${(props) =>
    !props.$fullscreen &&
    `
      margin-top: 20px;
      max-height: ${Platform.OS === "android" ? "90%" : "95%"};
  `}
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  padding: 0 20px;
`;

const Content = styled.ScrollView``;

const Header = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0 10px;
  margin-bottom: 20px;
`;

const CloseButton = styled.TouchableOpacity`
    position: absolute;
    right:1;
    padding: 20px 0 10px;
`;

const BackButton = styled.TouchableOpacity`
    position: absolute;
    left:1;
    padding: 20px 0 10px 0;
`;




export default function Modal({
  title,
  isBtnClose,
  children,
  ...props
}: {
  title: string;
  isBtnClose: boolean;
  children: React.ReactNode;
}) {
  const navigation = useNavigation();
  const { t } = useTranslation();
  const [theme] = useTheme();

  useKeyboardEvent({
    Escape: () => {
      if (navigation.isFocused()) {
        navigation.goBack();
      }
    },
  });
  
  return (
    <Wrapper>
      <Container {...props}>
        <Header>
          <View $transparent style={{flex:1,justifyContent:'center',alignItems:'center'}}>
            <Text
              size={18}
              $uppercase
              $bold
              accessibilityLabel={t("accessibility.modal.title", { title })}
            >
              {title}
            </Text>
          </View>
          {
            isBtnClose ?
            <CloseButton
              onPress={() => navigation.goBack()}
              accessibilityLabel={t("accessibility.modal.close", { title })}
            >
              <Icon as={MaterialCommunityIcons} name="window-close" color={theme === DARK_MODE ? "white" : "black"}/>
            </CloseButton> :
            <BackButton
              onPress={() => navigation.goBack()}
              accessibilityLabel={t("accessibility.modal.close", { title })}
            >
              <Icon as={MaterialCommunityIcons} name="arrow-left" color={theme === DARK_MODE ? "white" : "black"}/>
            </BackButton>
          }
          
        </Header>
        <Content {...props}>{children}</Content>
      </Container>
    </Wrapper>
  );
}
