import * as React from "react";
import { View } from "native-base";
import { TouchableOpacity, StyleSheet } from "react-native";

export default function CameraTrigger({
  onPress,
  disabled,
}: {
  onPress: () => Promise<void>;
  disabled: boolean;
}) {
  return (
    <TouchableOpacity onPress={onPress} disabled={disabled}>
      <View style={styles.border}>
        <View style={styles.center}></View>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  border: {
    borderWidth: 2,
    borderRadius: 25,
    borderColor: "white",
    height: 50,
    width: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  center: {
    borderWidth: 2,
    borderRadius: 20,
    borderColor: "white",
    height: 40,
    width: 40,
    backgroundColor: "white",
  },
});
