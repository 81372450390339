import * as React from "react";
import Modal from "../components/Modal";

export default function withModal(title: string, Component: React.FC, isBtnClose: boolean) {
  return function WithModalComponent(props: {}) {
    return (
      <Modal title={title} isBtnClose={isBtnClose}>
        <Component {...props} />
      </Modal>
    );
  };
}
