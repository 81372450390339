import * as React from "react";
import styled from "styled-components/native";

import {
  useStartupNotifications,
  useStartupUser,
  useStartupConfig,
  useStartupOrganization
} from "../contexts/startup/hooks";
import { Icon, View, Image } from "./Styled";
import useIsMobile from "../hooks/useIsMobile";
import { useTranslation } from "../contexts/translations";
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { Platform, StyleSheet } from "react-native";

const Wrapper = styled.SafeAreaView<{ shadow?: boolean }>`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => props.theme.bgColor};
  box-shadow: 1px 0 5px rgba(0,0,0,0.25);
  `;

const FlexRow = styled.View`
  flex-direction: row;
`;

const Button = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
  padding: 10px;
`;

const ProfileView = styled.View`
  flex-direction: row;
  align-items: center;
  padding: 10px;
`;

const ThemedIcon = styled(Icon)`
  color: ${(props) => props.theme.textColor};
`;

export default function WizardHeader() {
  const user = useStartupUser() || {};
  const { unreadCount = 0 } = useStartupNotifications();
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const { showIdButtonInStand } = useStartupConfig();
  const { logo } = useStartupOrganization();

  return (
    <Wrapper shadow={!isMobile}>
      <View $transparent spaceHorizontal={logo && logo !== "" ? hp("2%") : undefined} width={250} height={70}>
        {
          logo && logo !== "" ? (
            <Image
              style={{ flex: 1, ...StyleSheet.absoluteFillObject, resizeMode: "contain" , width:200 , height:70}}
              source={{ uri: logo }}
            />
          ) :
            <Image
              style={{ flex: 1, ...StyleSheet.absoluteFillObject, resizeMode: "contain" , width:250 , height:70}}
              source={require("../assets/images/cardexchange-logo.png")}
            />
        }
      </View>
    </Wrapper>
  );
}
