import { RatioType } from "./types";

export function parseRatio(ratioString: RatioType) {
  const [h, w] = ratioString.split(":");
  return parseInt(h, 10) / parseInt(w, 10);
}

export function getCorrectSize(size: number, ratio: number) {
  return [size * ratio, size];
}

export function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function safeMergeValues(
  prev?: object | null,
  next?: object | null,
  empty = null
) {
  return prev || next
    ? {
        ...(prev ? prev : {}),
        ...(next ? next : {}),
      }
    : empty;
}

const BASE64_PREFIX = "data:image/jpeg;base64,";

export function getBase64(str: string) {
  return `${BASE64_PREFIX}${cleanBase64(str)}`;
}

export function cleanBase64(str: string) {
  return str.replace(/^data:image\/[a-z]*;base64,/, "");
}

type Size = {
  width: number;
  height: number;
  size: string;
  sum: number;
};

export function getBestPictureSize(sizes: string[]) {
  const MAX_SIZE = 1000;
  const SEPARATOR = "x";
  const values = sizes.reduce((results: Size[], size: string) => {
    const [widthStr, heightStr] = size.split(SEPARATOR);
    const width = parseInt(widthStr, 10);
    const height = parseInt(heightStr, 10);

    if (
      isNaN(width) ||
      isNaN(height) ||
      width > MAX_SIZE ||
      height > MAX_SIZE
    ) {
      return results;
    }

    return results.concat(results, {
      width,
      height,
      size,
      sum: width + height,
    });
  }, []);

  return values.sort((a, b) => a.sum - b.sum).pop();
}
