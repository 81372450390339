export const QRCODE = "qrcode";
export const CODE39 = "CODE39";
export const CODE128 = "CODE128";
export const CODABAR = "codabar";

const BARCODE_TYPES = {
  QRCODE,
  CODE39,
  CODE128,
  CODABAR,
} as const;

export default BARCODE_TYPES;

type BarcodeTypeKeys = keyof typeof BARCODE_TYPES;
export type BarcodeType = typeof BARCODE_TYPES[BarcodeTypeKeys];
