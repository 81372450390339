export const ON_REQUEST = "ON_REQUEST";
export const ON_CACHE_LOADED = "ON_CACHE_LOADED";
export const ON_RESPONSE = "ON_RESPONSE";
export const ON_ERROR = "ON_ERROR";

const ACTIONS = {
    ON_REQUEST,
    ON_CACHE_LOADED,
    ON_RESPONSE,
    ON_ERROR
} as const;

export default ACTIONS

type ActionsKeys = keyof typeof ACTIONS;
export type Actions = typeof ACTIONS[ActionsKeys]