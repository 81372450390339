import * as React from "react";
import { StartupContext } from "./index";
import { CardHolderData, CardsPerType, ConfigType, CredentialsType, NotificationsType, OrganizationType, StartupProfileType, StartupState } from "./types";

export function useStartupUser(): Partial<CardHolderData> | null {

  const [{ user }] = React.useContext(StartupContext);
  return user;
}

export function usecardsPerType(): Partial<CardsPerType> | null {
  const [{ cardsPerType }] = React.useContext(StartupContext);
  return cardsPerType;
}

export function useStartupConfig(): Partial<ConfigType> {
  const [{ config }] = React.useContext(StartupContext);
  return config || {};
}

export function useStartupCredentials(): Partial<CredentialsType> {
  const [{ credentials }] = React.useContext(StartupContext);
  return credentials || {};
}

export function useStartupProfile(): Partial<StartupProfileType> {
  const [{ profile }] = React.useContext(StartupContext);
  return profile || {};
}

export function useStartupOrganization(): Partial<OrganizationType> {
  const [{ organization }] = React.useContext(StartupContext);
  return organization || {};
}

export function useStartupNotifications(): Partial<NotificationsType> {
  const [{ notifications }] = React.useContext(StartupContext);
  return notifications || {};
}

export function useStartupState(): Pick<
  StartupState,
  "error" | "isLoading" | "initialized"
> {
  const [{ isLoading, error, initialized }] = React.useContext(StartupContext);
  return { isLoading, error, initialized };
}

export function useStartupUserActions() {
  const [, actions] = React.useContext(StartupContext);
  return actions;
}

export function useDigitalCard() {
  const [{ digitalCard }] = React.useContext(StartupContext);
  return digitalCard;
}
