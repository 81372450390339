import { Icon as BaseIcon, Input as BaseInput, FormControl , Button as BaseButton} from "native-base";
import { ImageProps } from "react-native";
import styled from "styled-components/native";
import { PhotoShape, PORTRAIT } from "../constants/photo-shape";
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';

type Text = {
  $textAlign?: "center" | "left" | "right" | "inherit";
  $bold?: boolean;
  $uppercase?: boolean;
  $responsive?: boolean;
  color?: string;
  size?: number;
  maxWidth?: string;
};
export const Text = styled.Text<Text>`
  font-family: ${(props) => (props.$bold ? "Roboto_700Bold" : "Roboto_400Regular")};
  color: ${(props) => (props.color ? props.color : props.theme.textColor)};
  ${(props) => props.$textAlign && `text-align: ${props.$textAlign}`}
  ${(props) => props.$uppercase && `text-transform: uppercase`}
  ${(props) => props.size && `font-size: ${props.$responsive ? hp(`${props.size}%`) : `${props.size}px`}`}
  ${(props) => props.maxWidth && `max-width: ${props.maxWidth}`}
`;

type Label = {
  color?: string;
  size?: number;
  accessible?: boolean;
};
export const Label = styled(FormControl.Label)<Label>`
  font-family: Roboto_400Regular;
  text-transform: uppercase;
  color: ${(props) => (props.color ? props.color : props.theme.textLightColor)};
  ${(props) => props.size && `font-size: ${props.size}px`}
`;

type View = {
  width?: number | string;
  height?: number | string;
  flexDirection?: "row" | "column";
  flexGrow?: number;
  flexShrink?: number;
  alignSelf?: "flex-start" | "flex-end" | "center" | "baseline" | "stretch";
  alignItems?: "flex-start" | "flex-end" | "center" | "baseline" | "stretch";
  alignContent?:
    | "flex-start"
    | "flex-end"
    | "center"
    | "stretch"
    | "space-around"
    | "space-between";
  justifyContent?:
    | "flex-start"
    | "flex-end"
    | "center"
    | "space-around"
    | "space-between";
  $transparent?: boolean;
  $fullscreen?: boolean;
  $outline?: boolean;
  bgColor?: string;
  space?: number;
  spaceVertical?: number;
  spaceHorizontal?: number;
  spaceTop?: number;
  spaceRight?: number;
  spaceBottom?: number;
  spaceLeft?: number;
  borderWidth?: number;
  borderColor?: string;
};
export const View = styled.View<View>`
  ${(props) =>
    props.width &&
    `width: ${props.width}${typeof props.width !== "string" ? "px" : ""}`}
  ${(props) =>
    props.height &&
    `height: ${props.height}${typeof props.height !== "string" ? "px" : ""}`}
  ${(props) =>
    typeof props.flexGrow !== "undefined" && `flex-grow: ${props.flexGrow}`}
    ${(props) =>
    typeof props.flexShrink !== "undefined" &&
    `flex-shrink: ${props.flexShrink}`}
  ${(props) => props.flexDirection && `flex-direction: ${props.flexDirection}`}
  ${(props) => props.alignSelf && `align-self: ${props.alignSelf}`}
  ${(props) => props.alignItems && `align-items: ${props.alignItems}`}
  ${(props) => props.alignContent && `align-content: ${props.alignContent}`}
  ${(props) =>
    props.justifyContent && `justify-content: ${props.justifyContent}`}
  background: ${(props) =>
    props.$transparent
      ? "transparent"
      : props.bgColor
      ? props.bgColor
      : props.theme.bgColor};
  ${(props) => props.$fullscreen && `flex: 1`}
  ${(props) =>
    props.$outline &&
    `
    border: 1px solid ${props.theme.lineColor};
    border-radius: 10px;
    padding: 10px;
  `}
  ${(props) =>
    typeof props.space !== "undefined" &&
    `
    margin: ${props.space}px;
  `}
  ${(props) =>
    typeof props.spaceVertical !== "undefined" &&
    `
    margin-top: ${props.spaceVertical}px;
    margin-bottom: ${props.spaceVertical}px;
  `}
  ${(props) =>
    typeof props.spaceHorizontal !== "undefined" &&
    `
    margin-left: ${props.spaceHorizontal}px;
    margin-right: ${props.spaceHorizontal}px;
  `}

  ${(props) =>
    typeof props.spaceTop !== "undefined" &&
    `
    margin-top: ${props.spaceTop}px;
  `}

  ${(props) =>
    typeof props.spaceRight !== "undefined" &&
    `
    margin-right: ${props.spaceRight}px;
  `}

  ${(props) =>
    typeof props.spaceBottom !== "undefined" &&
    `
    margin-bottom: ${props.spaceBottom}px;
  `}

  ${(props) =>
    typeof props.borderWidth !== "undefined" &&
    `
    border-width: ${props.borderWidth}px;
  `}

  ${(props) =>
    typeof props.borderColor !== "undefined" &&
    `
    border-color: ${props.borderColor};
  `}

  ${(props) =>
    typeof props.spaceLeft !== "undefined" &&
    `
    margin-left: ${props.spaceLeft}px;
  `}
`;

type Icon = { size?: number; color?: string };
export const Icon = styled(BaseIcon)<Icon>`
  font-size: ${(props) => `${props.size || 24}px`}
  color: ${(props) => (props.color ? props.color : props.theme.textLightColor)};
`;

const AVATAR_SIZE = 40;

type Avatar = ImageProps & { size?: number };
export const Avatar = styled.Image<Avatar>`
  width: ${(props) => props.size || AVATAR_SIZE}px;
  height: ${(props) => props.size || AVATAR_SIZE}px;
  border-radius: ${(props) => (props.size || AVATAR_SIZE) / 2}px;
`;

type Image = ImageProps & {
  size?: number;
  width?: number;
  height?: number;
  resizeMode?: "cover" | "contain" | "stretch" | "repeat" | "center";
  flex?: boolean;
  roundedBorder?: boolean;
  shape?: PhotoShape;
};
export const Image = styled.Image.attrs((props: Image) => ({
  shape: props?.shape || PORTRAIT,
}))<Image>`
  ${(props) =>
    (props.width || props.size) && `width: ${wp(`${props.width || props.size}%`)}`};
  ${(props) =>
    (props.height || props.size) && `height: ${hp(`${props.height || props.size}%`)}`};
  ${(props) => props.resizeMode && `resize-mode: ${props.resizeMode}`}
  ${(props) => props.flex && `flex: 1`}
  ${(props) =>
    props.roundedBorder && props.shape !== PORTRAIT
      ? `border-radius: ${(props.width || props.size) / 2}px`
      : `border-radius: 5px`}
`;

type Input = { color?: string; disabled?: boolean };
export const Input = styled(BaseInput)<Input>`
  color: ${(props) =>
    props.color
      ? props.color
      : props.disabled
      ? props.theme.textLightColor
      : props.theme.textColor};
`;

type Divider = {
  color?: string;
  size?: number;
  $alignCenter?: boolean;
  $fullSize?: boolean;
  $vertical?: boolean;
};
export const Divider = styled(View)<Divider>`
  ${(props) =>
    props.$vertical ? `
      border-right-width: ${props.size || 1}px;
      border-style: solid;
      border-color: ${props.color || props.theme.lineColor};
    ` :
    `width: ${(props.$fullSize ? "100%" : "50%")};
    border-top-width: ${props.size || 1}px;
    border-style: solid;
    border-top-color: ${props.color || props.theme.lineColor};
    ${props.$alignCenter && `align-self: center`}`
  }
`;

type Button = {
  height?: number;
  alignItems?: string;
  justifyContent?: string;
  $fullWidth?: boolean;
}
export const Button = styled(BaseButton)<Button>`
  ${(props) =>
    typeof props.height !== "undefined" &&
    `
    height: ${props.height};
  `}

  ${(props) =>
    typeof props.justifyContent !== "undefined" &&
    `
    justify-content: ${props.justifyContent};
  `}

  ${(props) =>
    typeof props.alignItems !== "undefined" &&
    `
    align-items: ${props.alignItems};
  `}

  ${(props) => props.$fullWidth && `flex:1;`}
`
