import * as React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import Header from "../components/Header";
import ResetPasswordScreen from "../screens/ResetPasswordScreen";
import ProfileScreen from "../screens/ProfileScreen";
import NotificationsScreen from "../screens/NotificationsScreen";
import NotificationMessageScreen from '../screens/NotificationMessageScreen';
import SettingsScreen from "../screens/SettingsScreen";
import ProfileEditScreen from "../screens/ProfileEditScreen";
import withSidebar from "../hoc/withSidebar";
import { useTranslation } from "../contexts/translations";
import ProfilePhotoScreen from "../screens/ProfilePhotoScreen";
import SignatureUploadScreen from "../screens/SignatureUploadScreen";
import AddressScreen from "../screens/AddressScreen";
import IdSettingScreen from "../screens/IdSettingScreen";
import CredentialScreen from "../screens/CredentialScreen";
import CredentialTypeListScreen from '../screens/CredentialTypeListScreen';
import ProfileActivationScreen from "../screens/ProfileActivationScreen";
import CredentialActionScreen from "../screens/CredentialActionScreen";
import { MainStackParams } from "./types";
import { useStartupUser } from "../contexts/startup/hooks";
import StartupMessageScreen from "../screens/StartupMessageScreen";
import IdentityUploadScreen from "../screens/IndentityUploadScreen";
import MissingFieldScreen from "../screens/MissingFieldScreen";

const Stack = createStackNavigator<MainStackParams>();

const screenOptions = {
  header: () => <Header />,
};

const sidebarScreenOptions = {
  headerShown: false,
  cardStyle: { backgroundColor: "transparent" },
  cardOverlayEnabled: true,
  detachPreviousScreen: false
};
const archivedOptions = {
  headerShown: false
}



export default function DesktopNavigation() {
  const user = useStartupUser();
  const { t } = useTranslation();
  return (
    <Stack.Navigator
      initialRouteName="Profile"
    >
      <Stack.Screen
        name="Profile"
        component={user?.archive ? StartupMessageScreen : ProfileScreen}
        options={ user?.archive ? archivedOptions : screenOptions}
      />

      <Stack.Screen
        name="NotFound"
        component={StartupMessageScreen}
        options={{ title: "Oops!" }}
      />

      <Stack.Screen
        name="EditProfile"
        component={user?.archive ? StartupMessageScreen : withSidebar(t("tabs.profile_edit"), ProfileEditScreen)}
        options={sidebarScreenOptions}
      />

      <Stack.Screen
        name="ProfilePhoto"
        component={user?.archive ? StartupMessageScreen : ProfilePhotoScreen}
        options={{ headerShown: false }}
      />

      <Stack.Screen
        name="IdentityScreen"
        component={user?.archive ? StartupMessageScreen : IdentityUploadScreen}
        options={{ headerShown: false }}
      />

      <Stack.Screen
        name="MissingFields"
        component={user?.archive ? StartupMessageScreen : MissingFieldScreen}
        options={{ headerShown: false }}
      />

      <Stack.Screen
        name="SignatureUpload"
        component={user?.archive ? StartupMessageScreen : SignatureUploadScreen}
        options={{ headerShown: false }}
      />

      <Stack.Screen
        name="Notifications"
        component={user?.archive ? StartupMessageScreen : withSidebar(t("tabs.notifications"), NotificationsScreen)}
        options={sidebarScreenOptions}
      />

      <Stack.Screen
        name="NotificationMessage"
        component={user?.archive ? StartupMessageScreen : withSidebar(t("tabs.notifications"), NotificationMessageScreen)}
        options={sidebarScreenOptions}
      />

      <Stack.Screen
        name="IdSettings"
        component={user?.archive ? StartupMessageScreen : withSidebar(t("tabs.id_settings"), IdSettingScreen)}
        options={sidebarScreenOptions}
      />

      <Stack.Screen
        name="Credentials"
        component={user?.archive ? StartupMessageScreen : withSidebar(t("tabs.credentials"), CredentialScreen)}
        options={sidebarScreenOptions}
      />

      <Stack.Screen
        name="CredentialAction"
        component={user?.archive ? StartupMessageScreen : withSidebar(t("tabs.credentials"), CredentialActionScreen)}
        options={sidebarScreenOptions}
      />

      <Stack.Screen
        name="CredentialTypeList"
        component={user?.archive ? StartupMessageScreen : withSidebar(t("tabs.credentials"), CredentialTypeListScreen)}
        options={sidebarScreenOptions}
      />

      <Stack.Screen
        name="ProfileActivation"
        component={user?.archive ? StartupMessageScreen : withSidebar(t("tabs.profiles"), ProfileActivationScreen)}
        options={sidebarScreenOptions}
      />

      <Stack.Screen
        name="Settings"
        component={user?.archive ? StartupMessageScreen : withSidebar(t("tabs.settings"), SettingsScreen)}
        options={sidebarScreenOptions}
      />

      <Stack.Screen
        name="ResetPassword"
        component={user?.archive ? StartupMessageScreen : withSidebar(t("tabs.new_password"), ResetPasswordScreen)}
        options={sidebarScreenOptions}
      />

      <Stack.Screen
        name="FormAddress"
        component={user?.archive ? StartupMessageScreen : withSidebar(t("tabs.address"), AddressScreen)}
        options={sidebarScreenOptions}
      />

      <Stack.Screen
        name="StartupMessageScreen"
        component={StartupMessageScreen}
        options={{ title: "No access",  headerShown: false }}
      />
    </Stack.Navigator>
  );
}
