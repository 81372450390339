import * as React from "react";
import { Platform } from "react-native";
import styled from "styled-components/native";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";

import {
  useStartupNotifications,
  useStartupUser,
} from "../contexts/startup/hooks";
import { Avatar, Text, Icon, View } from "./Styled";
import { getBase64 } from "../utils";
import { useTranslation } from "../contexts/translations";
import { MainStackParams } from "../navigation/types";
import useKeyboardEvent from "../hooks/useKeyboardEvent";
import { DARK_MODE } from "../constants/Colors";
import { useTheme } from "../contexts/theme/hooks";

const Wrapper = styled.SafeAreaView<{ shadow?: boolean }>`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${Platform.OS === "android" ? "35px" : "15px"} 10px 5px;
  background-color: ${(props) => props.theme.bgColor};
  ${(props) => props.shadow && `box-shadow: 1px 0 5px rgba(0,0,0,0.25)`}
`;

const FlexRow = styled.View`
  flex-direction: row;
`;

const Button = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
  padding: 10px;
`;

const ThemedIcon = styled(Icon)`
  color: ${(props) => props.theme.textColor};
`;

type MobileHeaderProps = {
    routName?: string;
  };

const BackButton = styled.TouchableOpacity`
  justify-content: center;
  width: 50px;
`;

const CloseButton = styled.TouchableOpacity`
  justify-content: center;
  width: 50px;
`;

export default function Header({routName}: MobileHeaderProps) {
  const navigation = useNavigation<StackNavigationProp<MainStackParams>>();
  const user = useStartupUser() || {};
  const [theme] = useTheme();
  const { t } = useTranslation();

  useKeyboardEvent({
    Escape: () => {
      if (navigation.isFocused()) {
        navigation.goBack();
      }
    },
  });

  return (
    <Wrapper>
        <BackButton 
          onPress={() => navigation.goBack()}
          accessible
          accessibilityLabel={t("accessibility.back_btn")}
        >
            <Icon color={theme === DARK_MODE ? "white" : "black"} size={30} as={MaterialCommunityIcons} name="chevron-left" />
        </BackButton>
        <View $transparent flexGrow={1} spaceVertical={10} justifyContent="center" alignItems="center">
            <Text size={17} $bold>{routName?.toUpperCase()}</Text>
        </View>
        <CloseButton/>
    </Wrapper>
  );
}
