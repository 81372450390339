export const PORTRAIT = "Portrait";
export const ROUND = "Round";

const PHOTO_SHAPE = {
  PORTRAIT,
  ROUND,
} as const;

export default PHOTO_SHAPE;

type PhotoShapeKeys = keyof typeof PHOTO_SHAPE;
export type PhotoShape = typeof PHOTO_SHAPE[PhotoShapeKeys];
