import * as React from "react";
import * as AuthSession from "expo-auth-session";
import * as WebBrowserApp from "expo-web-browser";

import { Platform } from "react-native";
import jwtDecode from "jwt-decode";
import { auth, discovery } from "../constants/auth";
import { useRegion } from "../contexts/region";
import { useEnv } from "../contexts/env";
import * as Cache from "../services/cache";
import * as API from "../services/api";
import { PUSH_TOKEN } from "./usePushNotification";

var WebBrowser = WebBrowserApp;

WebBrowser.maybeCompleteAuthSession();

const AUTH_CACHE_KEY = "AUTH_CACHE_KEY";

// const getExpireTime = (state: AuthSession.TokenResponse) =>
//   state?.issuedAt + (state?.expiresIn || 3600);
// const isTokenFresh = (state: AuthSession.TokenResponse) =>
//   Date.now() <= getExpireTime(state);

const useAuthRequest = () => {
  const [env] = useEnv();
  const { region, setAskRegion } = useRegion();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLiveEnabled, setIsLiveEnabled] = React.useState(false);
  const [hasSubStand, setHasSubStand] = React.useState(false);
  const [authState, setAuthState] = React.useState<
    AuthSession.TokenResponse | undefined
  >();
  const authSettings = auth();
  const discoverySettings = discovery(env);
  // const [, result, promptAsync] = AuthSession.useAuthRequest(
  //   authSettings,
  //   discoverySettings
  // );
  const [result, setResult] =
    React.useState<WebBrowser.WebBrowserAuthSessionResult | null>(null);

  const signOut = async () => {
    setAuthState(undefined);
    setIsLoading(true);

    const pushToken = await Cache.get(PUSH_TOKEN);

    if (pushToken) {
      try {
        await API.deletePushToken(pushToken);
      } catch (err) {
        console.warn(
          "Failed request deleting pushToken",
          err?.response?.data || err
        );
      }
    }

    await Cache.clear();

    API.setAuthorizationHeader(null);

    const newurlurl = AuthSession.makeRedirectUri().toString().endsWith("/")
      ? AuthSession.makeRedirectUri().toString().slice(0, -1)
      : AuthSession.makeRedirectUri().toString();

    const redirectUrl =
      Platform.OS === "android" || Platform.OS === "ios"
        ? `${newurlurl}oauthredirect`
        : newurlurl;

    const signoutUrl = `${discoverySettings.endSessionEndpoint}?post_logout_redirect_uri=${redirectUrl}&id_token_hint=${authState?.idToken}`;

    if (Platform.OS === "web") {
      window.location.assign(signoutUrl);
      return;
    }

    setIsLoading(false);
    if (Platform.OS === "web") {
      WebBrowser.openBrowserAsync(signoutUrl);
    }
  };

  const refreshAuth = async () => {
    if (authState && !authState?.refreshToken) {
      signOut();
      return;
    }

    if (!authState || AuthSession.TokenResponse.isTokenFresh(authState)) {
      return;
    }

    setIsLoading(true);
    const refreshResponse = await AuthSession.refreshAsync(
      {
        clientId: authSettings.clientId,
        scopes: authSettings.scopes,
        refreshToken: authState?.refreshToken,
      },
      discoverySettings
    );

    decodeToken(refreshResponse);
    API.setAuthorizationHeader(refreshResponse.accessToken);
    setIsLoading(false);
    setAuthState(refreshResponse);
  };

  function decodeToken(token: AuthSession.TokenResponse) {
    if (!token) {
      return;
    }
    const {
      api_url: BASE_API_URL,
      live_enabled,
    }: { api_url?: string; live_enabled?: boolean } = token?.accessToken
      ? jwtDecode(token.accessToken)
      : {};

    if (BASE_API_URL) {
      API.setBaseURL(BASE_API_URL);
    } else {
      setAskRegion(true);
    }

    const subToken = jwtDecode(token?.accessToken);
    if (subToken && subToken.controller_stand == "licensed") {
      setHasSubStand(true);
    }
    setIsLiveEnabled(subToken?.live_enabled === "true" || false);
  }

  React.useEffect(() => {
    (async () => {
      const cachedAuth: AuthSession.TokenResponse = await Cache.get(
        AUTH_CACHE_KEY
      );

      decodeToken(cachedAuth);
      setAuthState(cachedAuth);
      API.setAuthorizationHeader(cachedAuth?.accessToken);
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      await refreshAuth();
    })();
  }, [authState]);

  function getNewUrl() {
    const newurlurl = authSettings.redirectUri.toString().endsWith("/")
      ? authSettings.redirectUri.toString().slice(0, -1)
      : authSettings.redirectUri.toString();

    const redirectUrl =
      Platform.OS === "android" || Platform.OS === "ios"
        ? `${newurlurl}oauthredirect`
        : newurlurl;

    return redirectUrl;
  }

  function getUrlParams(windowUrl: any) {
    var result = {};
    var params = (windowUrl.split("?")[1] || "").split("&");
    for (var param in params) {
      if (params.hasOwnProperty(param)) {
        var paramParts = params[param].split("=");
        result[paramParts[0]] = decodeURIComponent(paramParts[1] || "");
      }
    }
    return result;
  }

  React.useEffect(() => {
    (async () => {
      if (result?.type === "success") {
        setIsLoading(true);
        const urlparams = getUrlParams(result.url);

        const responseToken = await AuthSession.exchangeCodeAsync(
          {
            clientId: authSettings.clientId,
            scopes: authSettings.scopes,
            redirectUri: getNewUrl(),
            code: urlparams.code,
          },
          discoverySettings
        );

        decodeToken(responseToken);
        API.setAuthorizationHeader(responseToken.accessToken);
        setAuthState(responseToken);
        setIsLoading(false);
      }
    })();
  }, [result]);

  React.useEffect(() => {
    if (authState) Cache.set(AUTH_CACHE_KEY, JSON.stringify(authState));
  }, [authState]);

  return {
    isLoading,
    isLiveEnabled,
    isAuthorized: !!authState?.accessToken,
    disabled: isLoading,
    hasStandSubscription: hasSubStand,
    refreshAuth,
    signIn: async () => {
      let myrul = ``;
      if (Platform.OS === "ios") {
        myrul = `${
          discoverySettings.loginSessionEndpoint
        }?ReturnUrl=/connect/authorize/callback?redirect_uri=${getNewUrl()}%26client_id=${
          authSettings.clientId
        }%26response_type=code%26state=ozZ4w9UZD5%26scope=${authSettings.scopes.join(
          "%20"
        )}`;
      } else {
        myrul = `${
          discoverySettings.loginSessionEndpoint
        }?ReturnUrl=/connect/authorize/callback?redirect_uri=${getNewUrl()}%26client_id=${
          authSettings.clientId
        }%26response_type=code%26state=ozZ4w9UZD5%26scope=${authSettings.scopes.join(
          " "
        )}`;
      }
      const result: WebBrowserApp.WebBrowserAuthSessionResult =
        await WebBrowser.openAuthSessionAsync(myrul, getNewUrl(), {
          createTask: false,
        });
      setResult(result);
    },

    forgotPassword: () =>
      WebBrowser.openBrowserAsync(discoverySettings.forgotPassword),
    signOut,
  };
};

export default useAuthRequest;
 