import * as React from "react";
import { Platform, Modal } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import styled from "styled-components/native";
import useWindowDimensions from "../hooks/useWindowDimensions";
import ImageWithRatio from "./ImageWithRatio";

const CloseModalWrapper = styled.TouchableOpacity`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export default function PhotoWithZoom(props: ImageWithRatio) {
  const [isOpen, setOpen] = React.useState(false);
  const { width } = useWindowDimensions();
  return Platform.OS === "web" ? (
    <ImageWithRatio {...props} />
  ) : (
    <>
      <Modal
        animationType="fade"
        presentationStyle="overFullScreen"
        statusBarTranslucent
        visible={isOpen}
      >
        <CloseModalWrapper onPress={() => setOpen(false)}>
          <ImageWithRatio
            {...props}
            size={Math.min(width, 400)}
            roundedBorder={false}
          />
        </CloseModalWrapper>
      </Modal>
      <TouchableOpacity onPress={() => setOpen(true)}>
        <ImageWithRatio {...props} />
      </TouchableOpacity>
    </>
  );
}
