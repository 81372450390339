import * as React from "react";
import { StyleSheet } from "react-native";
import { useNavigation } from "@react-navigation/native";
import {
  Button,
  Stack,
  FormControl,
  Toast,
} from "native-base";

import { Label, Input, View, Text } from "../components/Styled";
import { useTranslation } from "../contexts/translations";
import {
  addAddress,
  AddAddressPayload,
  updateAddress,
  UpdateAddressPayload,
} from "../services/api";
import { useStartupUser } from "../contexts/startup/hooks";
import { CardHolderContext } from "../contexts/card-holder";

const fields = [
  "streetAddress",
  "locality",
  "region",
  "postalCode",
  "country",
] as const;

type Field = typeof fields[number];
type FieldValues = {
  [K in Field]: string;
};

export default function AddressScreen() {
  const navigation = useNavigation();
  const { t } = useTranslation();
  const { personId } = useStartupUser() || {};
  const [{ data }, { reload }] = React.useContext(CardHolderContext);
  const [isSubmitting, setSubmitting] = React.useState(false);
  const hasAddress = !!data?.addressObject?.id;
  const address = data?.addressObject || {};
  const [values, setValues] = React.useState<FieldValues>(
    () =>
      fields.reduce(
        (acc, field) => ({ ...acc, [field]: address[field] || "" }),
        {}
      ) as FieldValues
  );
  const isValid = fields.every((field) => values[field] !== "");

  const handleChange = (field: Field, value: string | number) => {
    setValues((current) => ({
      ...current,
      [field]: value,
    }));
  };

  const handleSubmit = async () => {
    if (!isValid || isSubmitting) {
      return;
    }

    setSubmitting(true);

    try {
      let res;

      if (hasAddress) {
        res = await updateAddress({
          ...address,
          ...values,
        } as UpdateAddressPayload);
      } else {
        res = await addAddress({
          ...values,
          person: personId,
        } as AddAddressPayload);
      }

      if ([200, 201].includes(res.status)) {
        await reload();
        Toast.show({
          accessible: true,
          accessibilityLabel: t("address.success"),
          duration: 8000,
          render:() => <View bgColor="green" style={{padding:10}}>
            <Text color="white">{t("address.success")}</Text>
          </View>
        })
        navigation.goBack();
      }
    } catch (err) {
      if (err.response?.data?.errors) {
        Object.values(err.response.data.errors).forEach((error) => {
          Toast.show({
            accessible: true,
            accessibilityLabel: t("address.error"),
            duration: 8000,
            render:() => <View bgColor="orange" style={{padding:10}}>
              <Text color="white">{error as string}</Text>
            </View>
          })
        });
      } else {
        Toast.show({
          accessible: true,
          accessibilityLabel: t("address.error"),
          duration: 8000,
          render:() => <View bgColor="orange" style={{padding:10}}>
            <Text color="white">{t("address.error")}</Text>
          </View>
        })
      }
    }

    setSubmitting(false);
  };

  return (
    <FormControl>
      {fields.map((field) => (
        <Stack key={field} style={styles.field}>
          <Label as={FormControl.Label}>{t(`address.${field}`)}</Label>
          <Input
            value={values[field]}
            onChangeText={(value) => handleChange(field, value)}
          />
        </Stack>
      ))}

      <Button
        disabled={!isValid || isSubmitting}
        onPress={handleSubmit}
        style={styles.button}
      >
        {t("address.submit")}
      </Button>
    </FormControl>
  );
}

const styles = StyleSheet.create({
  field: {
    marginBottom: 20,
  },
  button: {
    marginHorizontal: 20,
    marginBottom: 20,
  },
});
