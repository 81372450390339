import React from "react";
import { defaultValue } from "./stand-template"
import { Cardholder } from "./startup/types"

const defaultContextValue: CurrentCardholderContextValue = {
    cardholder: {
        id: "",
        profile: {
            id: "",
            name: "",
            standTemplate: defaultValue
        }
    },
    setCardholder: () => undefined
}

export const defaultCardholderValue: Cardholder = defaultContextValue.cardholder;

type CurrentCardholderContextValue = {
    cardholder: Cardholder,
    setCardholder: React.Dispatch<React.SetStateAction<Cardholder>>
}

const mapProfileToCardholder = (profile: any): Cardholder => {
    return {
        id: profile.id,
        startDate: profile.startDate,
        endDate: profile.endDate,
        email: profile.email,
        externalCompany: profile.externalCompany,
        department: profile.department,
        costAccount: profile.costAccount,
        location: profile.location,
        jobTitle: profile.jobTitle,
        status: profile.status,
        nameOnCard: profile.nameOnCard,
        note: profile.note,
        profileNumber: profile.profileNumber,
        freeField1: profile.freeField1,
        freeField2: profile.freeField2,
        created: profile.created,
        profile: {
          id: profile.profile?.id,
          name: profile.profile?.name,
          standTemplate: profile.profile?.standTemplate ?? defaultValue
        }
    };
};

export const CurrentCardholderContext = React.createContext<CurrentCardholderContextValue>(defaultContextValue);

export const CurrentCardholderContextProvider = ({ children }: any) => {
    const [cardholder, setCardholder] = React.useState<Cardholder>(defaultCardholderValue);

    const setMappedCardholder = (profile: any) => {
        const mappedCardholder = mapProfileToCardholder(profile);
        setCardholder(mappedCardholder);
    };

    return (
        <CurrentCardholderContext.Provider value={{ cardholder, setCardholder: setMappedCardholder }}>
        {children}
        </CurrentCardholderContext.Provider>
    );
};