import { StackNavigationProp } from "@react-navigation/stack";
import { createRef, useEffect } from "react";
import { findNodeHandle } from "react-native";

function getFocusableElements(rootRef: React.RefObject<any>): HTMLElement[] {
  // @ts-ignore
  const rootNode: HTMLElement = findNodeHandle(rootRef);

  return Array.from(
    rootNode.querySelectorAll(
      'a[href], button, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select, [tabindex="0"], [role="button"]'
    ) || []
  );
}

export default function useFocusTrap<T extends {}>(navigation?: StackNavigationProp<T>) {
  const rootRef = createRef<React.RefObject<any> | undefined>();
  const handleTabKey = (e: KeyboardEvent) => {
    if (!rootRef?.current) {
      return;
    }
    const focusableElements = getFocusableElements(rootRef.current);

    const firstElement = focusableElements[0];
    const lastElement = focusableElements[focusableElements.length - 1];

    if (!e.shiftKey && document.activeElement === lastElement) {
      firstElement.focus();
      return e.preventDefault();
    }

    if (e.shiftKey && document.activeElement === firstElement) {
      lastElement.focus();
      e.preventDefault();
    }
  };

  useEffect(() => {
    function focusFirst() {
      if (!rootRef.current) {
        return;
      }
      const focusableElements = getFocusableElements(rootRef.current);

      if (
        !document.activeElement ||
        !focusableElements.includes(document.activeElement as HTMLElement)
      )
        focusableElements[0].focus();
    }

    focusFirst();

    if (!navigation) {
      return undefined;
    }

    const unsubscribe = navigation.addListener("focus", () => {
      focusFirst();
    });

    return unsubscribe;
  }, []);

  return [rootRef, "Tab", handleTabKey];
}
