export const INITIALIZED = "initialized";
export const IN_PRODUCTION = "in_production";
export const PRINTED = "printed";
export const WITHDRAWN = "withdrawn";
export const EXPIRED = "expired";
export const ACTIVATED = "activated";
export const BLOCKED = "blocked";
export const APPROVED = "approved";

const CARD_STATUS = {
  INITIALIZED,
  IN_PRODUCTION,
  PRINTED,
  WITHDRAWN,
  EXPIRED,
  ACTIVATED,
  BLOCKED,
  APPROVED,
 } as const;

export default CARD_STATUS

type CardStatusKeys = keyof typeof CARD_STATUS;
export type CardStatus = typeof CARD_STATUS[CardStatusKeys]