import * as React from "react";
import { Image, StyleSheet } from "react-native";
import { View, Text } from "./Styled";

const AppBrand = () => {
  return (
    <View $transparent height={"40%"} justifyContent="center">
        <Image
            style={[styles.logo]}
            source={require("../assets/images/stand-logo.png")}
        />
    </View>
    
  )
};

const styles = StyleSheet.create({
  logo: {
    alignSelf: "center",
    width: 100,
    height: 100,
    resizeMode: "contain",
  },
});

export default AppBrand;
