import * as React from "react";
import { Platform, Modal } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import styled from "styled-components/native";
import QRCode from "react-native-qrcode-svg";

import useWindowDimensions from "../hooks/useWindowDimensions";
import { Barcode } from "./Barcode";
import QRCodeSecurityWrapper from "./QRCodeSecurityWrapper";
import BARCODE_TYPES, {
  CODE39,
  QRCODE,
  BarcodeType,
} from "../constants/barcode";
import { View } from "./Styled";
import {widthPercentageToDP as wp, heightPercentageToDP as hp} from 'react-native-responsive-screen';
import useIsMobile from "../hooks/useIsMobile";

const CloseModalWrapper = styled.TouchableOpacity`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

type QRCodeWithZoomProps = {
  type?: BarcodeType;
  source: string;
};

export default function QRCodeWithZoom({
  type = QRCODE,
  source,
}: QRCodeWithZoomProps) {
  const [isOpen, setOpen] = React.useState(false);
  const { width } = useWindowDimensions();
  const isMobile = useIsMobile();
  return !isMobile ? (
    <QRCodeSecurityWrapper>
      {type === QRCODE ? (
        <QRCode value={source} quietZone={10} />
      ) : (
        <Barcode
          value={source}
          width={type.toUpperCase() === CODE39 ? 1 : 2}
          height={20}
          format={BARCODE_TYPES[type.toUpperCase()]}
        />
      )}
    </QRCodeSecurityWrapper>
  ) : (
    <>
      <Modal
        animationType="fade"
        presentationStyle="overFullScreen"
        statusBarTranslucent
        visible={isOpen}
      >
        <CloseModalWrapper onPress={() => setOpen(false)}>
          {type === QRCODE ? (
            <QRCodeSecurityWrapper>
              <QRCode value={source} quietZone={10} size={width - 80} />
            </QRCodeSecurityWrapper>
          ) : (
            <View $transparent style={{ transform: [{ rotate: "-90deg" }] }}>
              <QRCodeSecurityWrapper>
                <Barcode
                  value={source}
                  width={type.toUpperCase() === CODE39 ? 3 : 4}
                  height={80}
                  format={BARCODE_TYPES[type.toUpperCase()]}
                />
              </QRCodeSecurityWrapper>
            </View>
          )}
        </CloseModalWrapper>
      </Modal>
      <TouchableOpacity onPress={() => setOpen(true)}>
        <QRCodeSecurityWrapper>
          {type === QRCODE ? (
            <QRCode value={source} quietZone={10} size={hp("10%")} />
          ) : (
            <Barcode
              value={source}
              width={type.toUpperCase() === CODE39 ? 1 : 2}
              height={20}
              format={BARCODE_TYPES[type.toUpperCase()]}
            />
          )}
        </QRCodeSecurityWrapper>
      </TouchableOpacity>
    </>
  );
}
