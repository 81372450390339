import * as React from "react";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import PersonalDetailsForm from "../components/PersonalDetailsForm";
import { CardHolderContext, UserProfileData } from "../contexts/card-holder";
import useUnsavedChanges from "../hooks/useUnsavedChanges";
import { useStartupUserActions , useStartupUser, useStartupProfile} from "../contexts/startup/hooks";
import { MainStackParams } from "../navigation/types";
import useIsMobile from "../hooks/useIsMobile";

export default function ProfileEditScreen() {
  const { reload: reloadStartupData } = useStartupUserActions();
  const navigation = useNavigation<StackNavigationProp<MainStackParams>>();
  const [hasUnsavedChanges, setHasUnsavedChanges] = React.useState(false);
  const [{ data, isLoading }, { updateData, updateCardHolderData }] = React.useContext(CardHolderContext);
  const user = useStartupUser();
  const profile = useStartupProfile();
  const isMobile = useIsMobile()

  useUnsavedChanges(hasUnsavedChanges);

  const handleSubmit = async (profileData: Partial<UserProfileData>, cardHolderData: Partial<UserProfileData>) => {
    const cprofile = data?.cardholderProfile ? data?.cardholderProfile : profile
    const cardholderID = !data?.cardholderId ? user?.cardholderId : data?.cardholderId;

    const cardholderFields = {
      locationId: cardHolderData.location ? cardHolderData.location : cprofile?.location?.id,
      departmentId: cardHolderData.department ? cardHolderData.department : cprofile?.department?.id,
      externalCompanyId: cardHolderData.externalCompany ? cardHolderData.externalCompany : cprofile?.externalCompany?.id,
      businessUnitId: cardHolderData.businessUnit ? cardHolderData.businessUnit : cprofile?.businessUnit?.id,
      costAccountId: cardHolderData.costAccount ? cardHolderData.costAccount : cprofile?.costAccount?.id,
      nameOnCard: cardHolderData.nameOnCard ? cardHolderData.nameOnCard : cprofile?.nameOnCard,
      note: cardHolderData.note ? cardHolderData.note : cprofile?.note,
      jobTitle: cardHolderData.jobTitle ? cardHolderData.jobTitle : cprofile?.jobTitle,
      freeField2: cardHolderData.freeField2 ? cardHolderData.freeField2 : cprofile?.freeField2,
      freeField1: cardHolderData.freeField1 ? cardHolderData.freeField1 : cprofile?.freeField1,
    }
    await updateCardHolderData(cardholderID, cardholderFields);

    
         
    await updateData(profileData);
    setHasUnsavedChanges(false);
    await reloadStartupData();
     if (isMobile) {
      navigation?.navigate("DigitalId")
    } else {
      navigation?.navigate("Profile");
    } 
  };

  return (
    <PersonalDetailsForm
      isSubmitting={isLoading}
      onChange={() => setHasUnsavedChanges(true)}
      onSave={handleSubmit}
    />
  );
}
