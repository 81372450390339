export const EMULATOR = "emulator";
export const LOCAL = "local";
export const DEV = "dev";
export const TEST = "test"
export const PREPROD = "preprod"
export const PROD = "prod";
export const SANDBOX = "sandbox";
// export const DEFAULT_ENV = PROD // set the env for the build
export const DEFAULT_ENV = PROD // set the env for the build

export type Env = typeof EMULATOR | typeof LOCAL | typeof DEV | typeof TEST | typeof PREPROD | typeof PROD | typeof SANDBOX;

export default [EMULATOR, LOCAL, DEV, TEST, PREPROD, PROD, SANDBOX];
