import Constants from 'expo-constants';
import { LOCAL, DEV, TEST, PREPROD, PROD, Env, DEFAULT_ENV, EMULATOR, SANDBOX } from "./envs";
import { EU, US, AS, UK, CA, Region } from "./regions";

const { manifest } = Constants;

let apiUri = "exp://192.168.254.121:5000";
let authUri = "exp://192.168.254.121:5001";
if (manifest && manifest.debuggerHost) {
  apiUri = `https://${manifest?.debuggerHost.split(':').shift()}:5000`;
  authUri = `https://${manifest?.debuggerHost.split(':').shift()}:5001`;
}

console.log('[MANIFEST]');
console.log('api url: ' + apiUri);
console.log('auth url: ' + authUri);

type BaseApiMap = {
  [key in Env]: Partial<
    {
      [keys in "default" | Region]: string;
    }
  >;
};

type BaseAuthMap = {
  [key in Env]: string;
};

const baseAuthDomain: BaseAuthMap = {
  [LOCAL]: "https://localhost:5001",
  [DEV]: "https://cardexchangecloudauthdev.azurewebsites.net",
  [TEST]: "https://cardexchangecloudauthtest.azurewebsites.net",
  [PREPROD]: "https://cardexchangecloudauthpreprod.azurewebsites.net",
  [PROD]: "https://auth.cardexchangecloud.com",
  [EMULATOR]: authUri,
  [SANDBOX]: "https://auth.cardexchangecloud.com"
};

export const baseDomainConfig: BaseApiMap = {
  [LOCAL]: {
    default: "https://localhost:5000",
  },
  [DEV]: {
    default: "https://cardexchangecloudapidev.azurewebsites.net",
  },
  [TEST]: {
    default: "https://cardexchangecloudapitest.azurewebsites.net",
  },
  [PREPROD]: {
    default: "https://cardexchangecloudapipreprod.azurewebsites.net",
  },
  [PROD]: {
    [EU]: "https://api.cardexchangecloud.eu",
    [US]: "https://api.cardexchangecloud.com",
    [AS]: "https://api.cardezecloud.com.au",
    [UK]: "https://api.cardexchangecloud.co.uk",
    [CA]: "https://apicanada.cardexchangecloud.com",
    default: "https://api.cardexchangecloud.eu",
  },
  [EMULATOR]: {
    default: apiUri
  },
  [SANDBOX]: {
    [EU]: "https://sandbox-api.cardexchangecloud.eu",
    [US]: "https://sandbox-api.cardexchangecloud.com",
    [AS]: "https://sandbox-api.cardezecloud.com.au",
    [UK]: "https://sandbox-api.cardexchangecloud.co.uk",
    [CA]: "https://apicanada.cardexchangecloud.com",
    default: "https://sandbox-api.cardexchangecloud.eu",
  }
};

export function getBaseUrlConfig(
  env: Env = DEFAULT_ENV,
  region: Region | "default" = "default"
) {
  const envConfig = baseDomainConfig[env] || baseDomainConfig[DEFAULT_ENV];
  return envConfig[region] || envConfig.default!;
}

export function getBaseAuthUrl(env: Env = DEFAULT_ENV) {
  return baseAuthDomain[env];
}

export function getBaseApiUrl(
  env: Env = DEFAULT_ENV,
  region: Region | "default" = "default"
) {
  return getBaseUrlConfig(env, region);
}
