import React from "react";
import { View } from "../Styled";
import { DatePickerProps, getStringValue } from "./common";
import { useTheme } from "../../contexts/theme/hooks";
import { DARK_MODE } from "../../constants/Colors";
export default function DatePickerWeb({ value, onChange }: DatePickerProps) {
  const [theme] = useTheme();


  function handleOnChange(evt: React.ChangeEvent<HTMLInputElement>) {
    const { value } = evt.target;
    const date = new Date(value);
    onChange(date.toISOString());
  }

  return (
    <View width="100%" $transparent>
      <input
        type="date"
        value={getStringValue(value)}
        onChange={handleOnChange}
        style={{
          backgroundColor:"transparent",
          border: theme === DARK_MODE ? "1px solid #313131" : "1px solid #ddd",
          padding: "8px",
          borderRadius: "5px",
          boxSizing: "border-box",
          font: '14px system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif',
          resize: 'none',
          color: theme === DARK_MODE ? "white" : "black"
        }}
      />
    </View>
  );
}
