import * as React from "react";
import {
  RefreshControl,
  Alert,
  Platform,
  NativeModules,
  PermissionsAndroid,
  DeviceEventEmitter,
  NativeEventEmitter,
  KeyboardAvoidingView,
  ActivityIndicator,
  Modal,
} from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import DigitalId from "../components/DigitalId";
import BottomTab from "../components/BottomTab";
import { View, Text } from "../components/Styled";
import { CardHolderContext } from "../contexts/card-holder";
import { logger, fileAsyncTransport } from "react-native-logs";
import * as FileSystem from "expo-file-system";
import {
  useStartupState,
  useStartupUser,
  useStartupUserActions,
} from "../contexts/startup/hooks";
import { ICard } from "../interfaces/card.interface";
import * as API from "../services/api";
import * as Cache from "../services/cache";
import { useTranslation } from "../contexts/translations";
import * as SaltoSpaceService from "../services/saltoSpaceService";
// React Native Modules Imports
const { OrigoSDKModule } = NativeModules; // Access custom native module off of NativeModules. This should only be used for creating EventEmitter instance for iOS
import OrigoSDKModuleInterface from "../OrigoSDKModuleInterface"; // Interface for react native modules that we can call.
// End of React Native Modules Imports

import { useAuth } from "../contexts/auth/hooks";
import { MessageNotificationTypes } from "../interfaces/saltoSpace.interface";
import { ACTIVATED, BLOCKED, EXPIRED, WITHDRAWN } from "../constants/card-status";
import { SALTO } from "../contexts/startup/constants/digitalSystem";
var digitalCard = {} as ICard;

export default function DigitalIdScreen() {
    const { isLoading } = useStartupState();
    const [interfaceLoading, setInterfaceLoading] =
        React.useState<boolean>(false);
    const { reload: reloadStartup, updateDigitalCard } = useStartupUserActions();
    const [, { reload: reloadCardHolderInfo }] =
        React.useContext(CardHolderContext);
    const { t, setLocale, locale } = useTranslation();
    const { hasStandSubscription } = useAuth();
    const [backgroundScanning, setBackgroundScanning] =
        React.useState<boolean>(false);
    const user = useStartupUser();
  const [isHID, setIsHID] = React.useState<boolean>(false);

  const isHidLoggingEnabled = false;
  const config = {
    transport: fileAsyncTransport,
    transportOptions: {
      FS: FileSystem,
      fileName: "log.txt",
      filePath: FileSystem.cacheDirectory,
    },
  };

  function getLanguageCode(language: string) {
    switch (language) {
      case "French":
        return "fr";
      case "English":
        return "en";
      case "Dutch":
        return "nl";
      case "German":
        return "de";
      case "Italian":
        return "it";
      case "Portuguese":
        return "pt";
      case "Spanish":
        return "es";
      default:
        return "en";
    }
  }

  const log = logger.createLogger(config);
  const redeemInvitationCode = async (res: any, id: string) => {
    if (res.messageNotification !== undefined) {
      if (res.messageNotification.type == MessageNotificationTypes.SaltoSpace) {
        try {
          const cardsObj = await API.getCardCredentials(user?.cardholderId!);

          const cardsArrPerType = cardsObj.data[0].cardsPerType;

          cardsArrPerType.map((card: any) => {
            if (
              Array.from(card.cards).find(
                (s: any) => s.type.category.isDigital
              ) !== undefined
            ) {
              digitalCard = Array.from(card.cards).find(
                (s: any) =>
                  s.type.category.isDigital &&
                  s.status !== BLOCKED &&
                  s.status !== WITHDRAWN &&
                  s.status !== EXPIRED
              ) as ICard;
            }
          });

          updateDigitalCard({type: digitalCard.type?.category.digitalCredentialSystem, status: ACTIVATED});

          const response = await SaltoSpaceService.getBinaryKey(digitalCard.id);
          const key = response?.data?.params?.mobilePhoneBinaryData?.binaryData;

          await Cache.set("salto-key", key);

          await API.markAsRead(id);

          Alert.alert("", t("profile.hid_request_status"));
        } catch (error) {
          console.error("error", error);
        }
      } else if (
        res.messageNotification.type == MessageNotificationTypes.HidMobileAccess
      ) {
        try {
          OrigoSDKModuleInterface.unregisterEndpoint();
          if (Platform.OS === "android") {
            let cachedVersion = (await Cache.get("appVersion")) as string;
            await OrigoSDKModuleInterface.startOrigoKeyController(
              cachedVersion
            );
          } 
          OrigoSDKModuleInterface.submitInvitationCode(res.invitationCode)
            .catch((err) => {
              console.log(err, "This error was thrown");
            })
            .then(async (result) => {
              console.log("[submitInvitationCode]");
              await API.markAsRead(id);
              if (Platform.OS === "android") {
                setTimeout(refreshEndpointAsync, 10000);
              }
            }); // redeem code to OrigoSDK
          Alert.alert("", t("profile.hid_request_status"));
        } catch (ex) {
          console.error("ex", ex);
        }
      }
    }
  };

  const requestLocationPermission = async () => {
    Alert.alert(
      t("profile.location_permission_title"),
      t("profile.location_permission_title_hid"),
      [
        {
          text: "Cancel",
          onPress: () => {
            showWarn();
            console.log("Location permission denied");
          },
          style: "cancel",
        },
        {
          text: "OK",
          onPress: async () => {
            /* PermissionsAndroid.request(
                          PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION,
                          {
                              title: "Location Permission",
                              message:
                                  "This app collects location data to enable communication with HID Mobile Access Readers in your vicinity, even when the app is closed or not in use.",
                              buttonNeutral: "Ask Me Later",
                              buttonNegative: "Cancel",
                              buttonPositive: "OK",
                          }
                      ).then((resultGrant) => {
                          console.log("result", resultGrant); */
            showWarn();
            /* }); */
          },
        },
      ]
    );
  };

  async function showWarn() {
    const showedWarning = await Cache.get("BleHceWarning");
    if (!showedWarning) {
      // Display the prominent disclosure dialog here
      const result = await Cache.get("BleHceWarning");
      if (!result && Platform.OS === "ios") {
        Alert.alert("Bluetooth Permission", t("profile.ble_hce_warning_ios"), [
          {
            text: "Cancel",
            onPress: () => console.log("Bluetooth permission denied"),
            style: "cancel",
          },
          {
            text: "OK",
            onPress: async () => {
              await Cache.set("BleHceWarning", "true");
            },
          },
        ]);
      } else if (!result && Platform.OS === "android") {
        Alert.alert("Bluetooth Permission", t("profile.ble_hce_warning"), [
          {
            text: "Cancel",
            onPress: () => console.log("Bluetooth permission denied"),
            style: "cancel",
          },
          {
            text: "OK",
            onPress: async () => {
              await Cache.set("BleHceWarning", "true");
            },
          },
        ]);
      }
    }
  }

  async function checkIfSwitchingDevice() {
    let isDigital = false;
    let digitalCard: ICard;
    const cardsObj = await API.getCardCredentials(user?.cardholderId);

    const cardsArrPerType = cardsObj.data[0].cardsPerType;

    cardsArrPerType.map((card: any) => {
      if (
        Array.from(card.cards).find((s: any) => s.type.category.isDigital) !==
        undefined
      ) {
        console.log("THIS IS A DIGITAL CARD");
        isDigital = true;
        digitalCard = Array.from(card.cards).find(
          (s: any) => s.type.category.isDigital
        ) as ICard;
      }
    });
    if (digitalCard!.status === "switching_device") {
      Alert.alert(`HID`, `Switching HID device, do you want to proceed?`, [
        {
          text: `${t("profile.button_later")}`,
          onPress: () => {
            console.log("Cancelled");
          },
          style: "cancel",
        },
        {
          text: `${t("profile.button_yes")}`,
          onPress: () => {
            setInterfaceLoading(true);
            checkIfHidUserDeleted(digitalCard);
          },
        },
      ]);
    }
  }

  async function checkDigitalCard() {
    const cardsObj = await API.getCardCredentials(user?.cardholderId);
    var digitalCard = false;
    
    const cardsArrPerType = cardsObj.data[0].cardsPerType;

    cardsArrPerType.map((card: any) => {
      if (
        Array.from(card.cards).find(
          (s: any) =>
            s?.type?.category?.isDigital &&
            s?.type?.category?.digitalCredentialSystem === SALTO &&
            s?.status === ACTIVATED
        ) !== undefined
      ) {
        digitalCard = true;
      }
    });

    digitalCard ? updateDigitalCard({type: SALTO, status: ACTIVATED}) : updateDigitalCard({type: undefined, status: BLOCKED})

  }

  function checkIfHidUserDeleted(digitalCard: ICard) {
    API.updateCardStatus(
      digitalCard.id,
      "activated",
      digitalCard.type!.id,
      user!.cardholderId!,
      user!.personId!,
      user!.profiles![0].profile.id,
      new Date(),
      new Date(new Date().setFullYear(new Date().getFullYear() + 1))
    )
      .then((res: any) => {
        console.log(res.status, "[RE-ACTIVATION STATUS]");
        setInterfaceLoading(false);
        checkNewCredential();
      })
      .catch((err: any) => {
        console.log(err.toJSON(), "THIS IS THE RESPONSE ERROR");
      });
  }

  React.useEffect(() => {
    (async () => {
      await checkDigitalCard();
      setInterval(checkNewCredential, 60 * 1000);

      let isHID = false;
      const cardsObj = await API.getCardCredentials(user?.cardholderId);

      const cardsArrPerType = cardsObj.data[0].cardsPerType;

      cardsArrPerType.map((card: any) => {
        if (
          Array.from(card.cards).find(
            (s: any) =>
              s.type.category.isDigital &&
              s.type.category.digitalCredentialSystem === "hid_mobile_access"
          ) !== undefined
        ) {
          isHID = true;
          setIsHID(true);
        }
      });

      if (hasStandSubscription && Platform.OS !== "web") {
        if (isHID) {
          let locationRequested = await Cache.get("LocationPermission");

          if (!locationRequested) {
            await requestLocationPermission();
            await Cache.set("LocationPermission", "true");
          }
          
          const origoStart = await startOrigoKeyControllerAsync();
          console.log("[ORIGO_START]", origoStart);

          await checkIfSwitchingDevice();
        }

        const newCredential = await checkNewCredential();
        console.log("[NEW_CREDENTIAL]", newCredential);
      }

      const cardHolderInfo = await API.getCardHolderInfo();
      let languageFromDB = cardHolderInfo?.data?.language;
      let languageCode = getLanguageCode(languageFromDB || locale);
      if (languageFromDB !== null || undefined || "") setLocale(languageCode);
    })();
  }, []);

  React.useEffect(() => {
    let onErrorEventListener;
    let onIsEndpointSetupListener;
    let onListMobileKeysListener;
    let onReaderConnectionListener;
    let onHceConnectionListener; // THIS IS ANDROID ONLY

    try {
      if (Platform.OS === "android") {
        // Android event emitters
        console.log("[Android] Adding listeners for events");
        // Initializing listeners so we can trigger remove() if needed.
        onListMobileKeysListener = DeviceEventEmitter.addListener(
          "onListMobileKeysEvent",
          onListMobileKeysEvent
        );
        onIsEndpointSetupListener = DeviceEventEmitter.addListener(
          "onIsEndpointSetupEvent",
          onIsEndpointSetupEvent
        );
        onErrorEventListener = DeviceEventEmitter.addListener(
          "onErrorEvent",
          onErrorEvent
        );
        onReaderConnectionListener = DeviceEventEmitter.addListener(
          "onReaderConnectionEvent",
          onReaderConnectionEvent
        );
        onHceConnectionListener = DeviceEventEmitter.addListener(
          "onHceSessionEvent",
          onHceConnectionEvent
        );

        // getEndpointInfo();
        // onErrorEventListener.remove(); to remove event listeners for Android
      } else if (Platform.OS === "ios") {
        // iOS event emitters
        const eventEmitter = new NativeEventEmitter(OrigoSDKModule);
        onErrorEventListener = eventEmitter.addListener(
          "onErrorEvent",
          onErrorEvent
        );
        onIsEndpointSetupListener = eventEmitter.addListener(
          "onIsEndpointSetupEvent",
          onIsEndpointSetupEvent
        );
        onListMobileKeysListener = eventEmitter.addListener(
          "onListMobileKeysEvent",
          onListMobileKeysEvent
        );
        onReaderConnectionListener = eventEmitter.addListener(
          "onReaderConnectionEvent",
          onReaderConnectionEvent
        );
        console.log("[Platform OS: iOS]");

        // onErrorEventListener.remove(); to remove event listeners for iOS
        // For iOS devices we also have to explicitly call origoKeyController.start()
        // In useEffect because should only be called once or else will error: There already exists an instance of AMSSeosFileManager
      }
    } catch (error) {
      console.log("error", error);
      if (global.isHidLoggingEnabled) {
        log.debug(JSON.stringify(error));
      }
    }
  }, []);

  async function checkNewCredential() {
    try {
      const res = await API.getNewCredential();

      if (res.data !== null && res.data !== "") {
        if (res.data.messageNotification.title === "HID Mobile Access Status Changed") {
          if (
            res.data.messageNotification.message === "Blocked" ||
            res.data.messageNotification.message === "Withdrawn"
          ) {
            OrigoSDKModuleInterface.unregisterEndpoint();
            await API.markAsRead(res.data.id);
          }
        } else {
          Alert.alert(
            res.data.messageNotification.type ==
              MessageNotificationTypes.SaltoSpace
              ? `${t("profile.salto_new_credential_title")}`
              : `${t("profile.hid_new_credential_title")}`,
            res.data.messageNotification.type ==
              MessageNotificationTypes.SaltoSpace
              ? `${t("profile.salto_new_credential_body")}`
              : `${t("profile.hid_new_credential_body")}`,
            [
              {
                text: `${t("profile.button_later")}`,
                onPress: () => {
                  console.log("Cancelled");
                },
                style: "cancel",
              },
              {
                text: `${t("profile.button_yes")}`,
                onPress: () => redeemInvitationCode(res.data, res.data.id),
              },
            ]
          );
        }
      }
    } catch (err) {
      if (global.isHidLoggingEnabled) {
        log.debug(JSON.stringify(err));
      }
      console.log("err", err);
    }
  }

  async function reload() {
    await reloadStartup();
    await checkIfSwitchingDevice();
    await checkDigitalCard();
    const cardHolder = await reloadCardHolderInfo();
    if (isHID) {
      await refreshEndpointAsync();
    }
    if (Platform.OS !== "web") {
      await checkNewCredential();
    }
  }

  // will list mobile keys after endpoint has been registered
  const onListMobileKeysEvent = (event: any) => {
    if (global.isHidLoggingEnabled) {
      log.debug(JSON.stringify(event));
    }
    if (event?.mobileKeys.length < 1 && event?.success) {
      refreshEndpointAsync();
    }
  };

  // Will check if OrigoSDk Endpoint is setup.
  // If false, we need to create a form that will allow the user to input an invitiation code
  const onIsEndpointSetupEvent = async (event: any) => {
    if (global.isHidLoggingEnabled) {
      log.debug(JSON.stringify(event));
    }

    console.log(event?.isSetup, "[IS ENDPOINT SETUP]");

    if (event?.isSetup === true) {
      console.log("REFRESHING ENDPOINTS INFO, ETC");
      getEndpointInfoAsync(); // when endpoint is setup, we can retrieve info
      refreshEndpointAsync(); // For Automatic seos key creation after code redemption, we should refresh the endpoint to retrieve newly issued keys.
    } else {
      const cardsObj = await API.getCardCredentials(user?.cardholderId);

      const cardsArrPerType = cardsObj.data[0].cardsPerType;

      cardsArrPerType.map(async (card: any) => {
        const digitalCard: ICard = Array.from(card.cards).find(
          (s: any) => s.type.category.isDigital
        ) as ICard;

        if (
          digitalCard.status === "activated" ||
          digitalCard.status === "approved"
        ) {
          API.getCard(digitalCard!.id)
            .then((data: any) => {
              if (digitalCard !== undefined) {
                API.updateCardStatus(
                  digitalCard.id,
                  "switching_device",
                  data.data.type.id,
                  data.data.cardholder,
                  user!.personId!,
                  user!.profiles![0].profile.id,
                  new Date(),
                  new Date(new Date().setFullYear(new Date().getFullYear() + 1))
                )
                  .then((res) => {
                    checkIfSwitchingDevice();
                  })
                  .catch((err: any) => {
                    console.log(err.response.data, "ERROR RESPONSE DATA");
                    console.log(err.response.status);
                    console.log(err.response.headers);
                  });
              }
            })
            .catch((err: any) => {
              console.log(err.response.data, "ERROR RESPONSE DATA");
              console.log(err.response.status);
              console.log(err.response.headers);
            });
        }
      });
    }
  };

  // Logs errors thrown by OrigoSDK callback methods
  const onErrorEvent = (event: any) => {
    console.log("[onErrorEvent]");
    console.log(event);
    if (global.isHidLoggingEnabled) {
      log.debug(JSON.stringify(event));
    }
  };

  // Notifies JS if there is a reader connection
  const onReaderConnectionEvent = (event: any) => {
    console.log("[onReaderConnectionEvent]");
    if (global.isHidLoggingEnabled) {
      log.debug(JSON.stringify(event));
    }
  };

  // Notifies JS if theres is an HCE connection (ANDROID ONLY)
  const onHceConnectionEvent = (event: any) => {
    console.log("[onHceConnectionEvent]");
    if (global.isHidLoggingEnabled) {
      log.debug(JSON.stringify(event));
    }
  };

  async function getEndpointInfoAsync() {
    try {
      const endpointInfo = await OrigoSDKModuleInterface.getEndpointInfo();
      console.log("[getEndpointInfoAsync]");
      console.log(endpointInfo);
      if (global.isHidLoggingEnabled) {
        log.debug(JSON.stringify(endpointInfo));
      }
    } catch (error) {
      console.error(error);
      if (global.isHidLoggingEnabled) {
        log.error(JSON.stringify(error));
      }
    }
  }

  async function refreshEndpointAsync() {
    try {
      const result = await OrigoSDKModuleInterface.refreshEndpoint(); // retrieves seos credentials and saves them in the device. retrieveMobileKeys will retrieve null until endpoint has been refreshed for newly create seos keys
      console.log(result, "[refreshEndpointAsync]");
      console.log("isHidLoggingEnabled", isHidLoggingEnabled);
      if (global.isHidLoggingEnabled) {
        log.debug(JSON.stringify(result));
      }
    } catch (error) {
      console.log("error ", error);
      if (global.isHidLoggingEnabled) {
        log.error(JSON.stringify(error));
      }
    }
  }

  async function startOrigoKeyControllerAsync() {
    try {
      let start;
      if (Platform.OS === "android") {
        let cachedVersion = (await Cache.get("appVersion")) as string;
        start = await OrigoSDKModuleInterface.startOrigoKeyController(
          cachedVersion
        );
      } else if (Platform.OS === "ios") {
        start = await OrigoSDKModuleInterface.startOrigoKeyController();
      }
      console.log(start, "[START ORIGO KEY CONTROLLER]");
      if (global.isHidLoggingEnabled) {
        console.warn("ENABLED");
        log.debug(JSON.stringify(start));
      }
    } catch (error) {
      console.log(error);
      log.error(JSON.stringify(error));
      if (global.isHidLoggingEnabled) {
        log.error(JSON.stringify(error));
      }
    }
  }

  return (
    <KeyboardAvoidingView style={{ flex: 1 }}>
      <Modal
        transparent={true}
        style={{
          position: "absolute",
        }}
        animationType="none"
        visible={interfaceLoading}
      >
        <View
          $transparent
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "flex-end",
            alignItems: "center",
            marginBottom: 290,
            height: 20,
          }}
        >
          <View
            style={{
              height: 100,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              paddingHorizontal: 25,
              paddingVertical: 80,
              elevation: 10,
              opacity: 0.93,
              borderRadius: 10,
            }}
          >
            <Text
              style={{
                height: 40,
                marginBottom: 40,
                width: "100%",
                color: "black",
                textAlign: "center",
                fontSize: 16,
              }}
            >
              {"Switching device,"} {"\n"}
              {"please wait"}
            </Text>
            <ActivityIndicator size="large" style={{ paddingBottom: 10 }} />
          </View>
        </View>
      </Modal>
      <View $fullscreen $transparent>
        <View
          as={ScrollView}
          contentContainerStyle={{ flex: 1, justifyContent: "center" }}
          refreshControl={
            <RefreshControl
              refreshing={isLoading && !!data}
              onRefresh={reload}
            />
          }
        >
          <DigitalId />
        </View>
        {!user?.archive && <BottomTab />}
      </View>
    </KeyboardAvoidingView>
  );
}
