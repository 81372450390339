// This exposes the native OrigoSDKModule as a JS module.

import { NativeModules } from "react-native";
const { OrigoSDKModule } = NativeModules;

interface OrigoSDKInterface {
    startOrigoKeyController(version: string) : Promise<any>; 
    startOrigoKeyController() : Promise<any>;
    submitInvitationCode(code: string) : Promise<any>;
    activateBackgroundScan(code: boolean) : Promise<any>;
    refreshEndpoint() : Promise<any>;
    retrieveMobileKeys() : Promise<any>;
    getEndpointInfo() : Promise<any>;
    unregisterEndpoint() : Promise<any>;
    activateMobileKey(cardNumber: string) : Promise<any>;
    deactivateMobileKey(cardNumber: string) : Promise<any>;
    getSeosId() : Promise<any>;
    //iOS only methods
    openClosestReader() : Promise<any>;
    healthCheck() : Promise<any>;
    setEnableBackgroundScan(isEnabled: boolean) : Promise<any>;
}

export default OrigoSDKModule as OrigoSDKInterface;
