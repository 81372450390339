import { MaterialCommunityIcons } from "@expo/vector-icons";
import { HStack, Pressable } from "native-base";
import * as React from "react";
import { StyleSheet } from "react-native";
import { View, Text, Label, Divider, Icon } from "../components/Styled";
import styled from "styled-components/native";
import { useTranslation } from "../contexts/translations";
import {
  useStartupUser, useStartupConfig
} from "../contexts/startup/hooks";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { MainStackParams } from "../navigation/types";
import { CurrentCardholderContext } from "../contexts/current-cardholder";

const ThemedIcon = styled(Icon)`
  color: ${(props) => props.theme.textColor};
`;


export default function IdSettingsScreen() {
  const { t } = useTranslation();
  const {cardholder} = React.useContext(CurrentCardholderContext);
  const navigation = useNavigation<StackNavigationProp<MainStackParams>>();
  const { allowUserViewCredentials, allowUserManageProfiles } = useStartupConfig();



  return (
    <View $transparent height={300}>

      {
        __DEV__ &&
        <>
        <Text $textAlign="center" size={12}>{t("id_settings.active_profile")}</Text>
        <Text $textAlign="center" size={12}>{cardholder && cardholder.profile.name}</Text>
        </>
      }
      

      <View spaceTop={30} $transparent>
        <Divider $fullSize spaceVertical={1} />

        {
          __DEV__ && allowUserManageProfiles &&
          <>
            <Pressable
              disabled={!allowUserManageProfiles}
              style={styles.pressableMenu}
              onPress={() => navigation.navigate("ProfileActivation")}
            >
              <HStack justifyContent="space-between">
                <Text size={14} color={!allowUserManageProfiles ? "#b0b0b0" : undefined}>{t("id_settings.profiles").toUpperCase()}</Text>
                <ThemedIcon as={MaterialCommunityIcons} name="chevron-right" />
              </HStack>
            </Pressable>
            <Divider $fullSize spaceVertical={1} />
          </>
        }
        <Pressable
          disabled={!allowUserViewCredentials}
          style={styles.pressableMenu}
          onPress={() => navigation.navigate("Credentials")}
        >
          <HStack justifyContent="space-between">
            <Text size={14} color={!allowUserViewCredentials ? "#b0b0b0" : undefined}> {t("id_settings.credentials").toUpperCase()}</Text>
            <ThemedIcon as={MaterialCommunityIcons} name="chevron-right" />
          </HStack>
        </Pressable>
        <Divider $fullSize spaceVertical={1} />

      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  pressableMenu: {
    marginVertical: 12
  },
});
