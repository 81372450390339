import { MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import { Button, HStack, Pressable } from "native-base";
import * as React from "react";
import { ActivityIndicator, StyleSheet } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { View, Text, Divider, Icon, Label } from "../components/Styled";
import { useAuth } from "../contexts/auth/hooks";
import {
  useStartupNotifications,
  useStartupUserActions,
} from "../contexts/startup/hooks";
import { useTranslation } from "../contexts/translations";
import * as API from "../services/api";
import {
  useStartupConfig,
  useStartupOrganization,
  useStartupState,
  useStartupUser,
} from "../contexts/startup/hooks";
import styled from "styled-components/native";
import { CardHolderContext, UserProfileData } from "../contexts/card-holder";

import useIsMobile from "../hooks/useIsMobile";
import * as Cache from "../services/cache";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { MainStackParams } from "../navigation/types";

const ThemedIcon = styled(Icon)`
  color: ${(props) => props.theme.textColor};
`;


export default function ProfileActivationScreen() {
  const navigation = useNavigation<StackNavigationProp<MainStackParams>>();

  const { t } = useTranslation();
  const [{ data, error }] = React.useContext(CardHolderContext);

  const isMobile = useIsMobile();
  const [userProfiles, setUserProfiles] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const [profile, setProfile] = React.useState(data?.profileName);
  const [, { requestSwitchCardHolderInfo }] = React.useContext(CardHolderContext);
  const user = useStartupUser();

  React.useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const res = await API.getUserProfiles(user?.personId);
        if(res.data){
          const profiles = res.data.filter((t: { email: string | undefined; }) => t.email === user?.email)
          setUserProfiles(profiles)
        }
        setIsLoading(false);
      } catch (error) {
        console.log("error", error);
        setIsLoading(false);
      }
    })()
  }, [])

  const handleSwitch = async (val: Partial<UserProfileData>) => {
    if (val.profile) {
      try {
        setIsSubmitting(true)
        setProfile(val.profile?.name)
        await requestSwitchCardHolderInfo(val);
        if (isMobile) {
          navigation.navigate("DigitalId");
        } else {
          navigation.navigate("Profile");
        }
      } catch (error) {
        console.log("errior", error);
        setProfile(data?.profileName)
        setIsSubmitting(false)
      }
    }

  };

  return (
    <View $transparent height={300}>
      <Text $textAlign="center" size={12}>{t("id_settings.profile_switch")}</Text>

      <View $transparent spaceTop={30}>
        <Divider $fullSize spaceTop={1} />
        {
          isLoading ?
            <View $transparent style={styles.loadContainer} space={!isMobile ? 20 : 0}>
              <ActivityIndicator
                accessible
                accessibilityLabel={t("accessibility.loading", {
                  text: "",
                })}
              />
            </View> :
            <>
              {
                userProfiles.map((val, index) =>
                  <View key={index} $transparent>
                    <Pressable
                      disabled={isSubmitting}
                      style={styles.pressableMenu}
                      onPress={() => handleSwitch(val)}
                    >
                      <HStack justifyContent="space-between">
                        <Text>{val.profile?.name}</Text>

                        <ThemedIcon as={MaterialCommunityIcons} name={profile === val.profile?.name ? "radiobox-marked" : "radiobox-blank"} />


                      </HStack>
                    </Pressable>
                    <Divider $fullSize spaceVertical={1} />
                  </View>
                )
              }
              {
                isSubmitting &&
                <ActivityIndicator
                  accessible
                  accessibilityLabel={t("accessibility.loading", {
                    text: "",
                  })}
                />
              }
            </>

        }
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  pressableMenu: {
    marginVertical: 12
  },
  loadContainer: {
    flex: 1,
    justifyContent: 'center',
  }
});
