import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  CACHE_KEY_PIN,
  CACHE_KEY_PRIVACY_POLICY,
  FIRST_LOGIN,
} from "../contexts/startup/constants/cache";

type resourceNameType = string;
type valueType = string;

const APP_KEY = "@StandApp";

const resourceNames = [CACHE_KEY_PRIVACY_POLICY, CACHE_KEY_PIN, FIRST_LOGIN];

const TMP = "temp";

function key(resourceName: resourceNameType) {
  if (resourceNames.includes(resourceName)) {
    return `${APP_KEY}:${resourceName}`;
  } else {
    return `${APP_KEY}:${TMP}_${resourceName}`;
  }
}

function parseRaw(raw: string | null = "", returnRawOnError = true) {
  try {
    return JSON.parse(raw ?? "");
  } catch (err) {
    if (returnRawOnError) {
      return raw;
    }
    console.warn("Failed parsing json", err);
    return null;
  }
}

export async function get(resourceName: resourceNameType) {
  const raw = await AsyncStorage.getItem(key(resourceName));

  return parseRaw(raw);
}

export async function getMany(resourceNames: resourceNameType[]) {
  const raw: [string, string | null][] = await AsyncStorage.multiGet(
    resourceNames.map(key)
  );

  return raw.map(([, value]) => parseRaw(value, false));
}

export async function set(resourceName: resourceNameType, value: valueType) {
  return await AsyncStorage.setItem(key(resourceName), value);
}

export async function setMany(resources: [resourceNameType, valueType][]) {
  return await AsyncStorage.multiSet(resources.map(([k, v]) => [key(k), v]));
}

export async function remove(resourceName: resourceNameType) {
  return await AsyncStorage.removeItem(key(resourceName));
}

export async function clear() {
  const allKeys = await AsyncStorage.getAllKeys();
  const appKeys = allKeys.filter((resourceKey: any) =>
    resourceKey.startsWith(`${APP_KEY}:${TMP}_`)
  );
  return await AsyncStorage.multiRemove(appKeys);
}

export async function clearAll() {
  return await AsyncStorage.clear();
}
