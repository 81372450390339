import * as React from "react";
import { useFeatureFlags } from "./feature-flags";
import PinCode from "../components/PinCode";
import * as Cache from "../services/cache";
import { useTranslation } from "./translations";
import LocalAuth from "../components/LocalAuth";

const CACHE_KEY_PIN = "pin";

const INITALIZE = "INITALIZE";
const INVALID_PIN = "INVALID_PIN";
const SET_PIN = "SET_PIN";
const RESET_PIN = "RESET_PIN";
const CONFIRM_PIN = "CONFIRM_PIN";
const ENTER_PIN = "ENTER_PIN";
const ERROR = "ERROR";
const LOCK = "LOCK";
const UNLOCK = "UNLOCK";

export type PinLabelKey =
  | typeof INVALID_PIN
  | typeof SET_PIN
  | typeof CONFIRM_PIN
  | typeof ENTER_PIN;

function getPinAction({ pinValue, pin }: PinState) {
  if (!pin) {
    if (!pinValue) {
      return SET_PIN;
    } else {
      return CONFIRM_PIN;
    }
  }
  return ENTER_PIN;
}

function getLabelKey(action: PinLabelKey, error: boolean = false) {
  return error ? INVALID_PIN : action;
}

type PinState = {
  initialized: boolean;
  pin?: string;
  pinValue?: string;
  isLocked: boolean;
  error: boolean;
  lock: () => void;
  reset: () => void;
};

type PinAction =
  | { type: typeof RESET_PIN | typeof LOCK | typeof UNLOCK | typeof ERROR }
  | { type: typeof INITALIZE; payload: { pin?: string } }
  | {
      type: typeof SET_PIN | typeof CONFIRM_PIN;
      payload: { pin: string };
    };

const initialValue = {
  initialized: false,
  isLocked: true,
  error: false,
  lock: () => undefined,
  reset: () => undefined,
};

const PinContext = React.createContext<PinState>(initialValue);

function pinReducer(prevState: PinState, action: PinAction) {
  switch (action.type) {
    case INITALIZE:
      return {
        ...prevState,
        initialized: true,
        pinValue: undefined,
        pin: action.payload.pin,
        error: false,
        isLocked: true,
      };
    case SET_PIN:
      return {
        ...prevState,
        pin: undefined,
        pinValue: action.payload.pin,
        error: false,
      };
    case CONFIRM_PIN:
      return {
        ...prevState,
        pin: action.payload.pin,
        isLocked: false,
        error: false,
      };
    case RESET_PIN:
      return {
        ...prevState,
        pin: undefined,
        pinValue: undefined,
        isLocked: true,
        error: false,
      };
    case UNLOCK:
      return {
        ...prevState,
        isLocked: false,
        error: false,
      };
    case LOCK:
      return {
        ...prevState,
        isLocked: true,
        error: false,
      };
    case ERROR:
      return {
        ...prevState,
        error: true,
      };
    default:
      return prevState;
  }
}

export const PinProvider = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();
  const { SET_PIN: MUST_SET_PIN } = useFeatureFlags();
  const [state, dispatch] = React.useReducer(pinReducer, {
    ...initialValue,
    isLocked: !!MUST_SET_PIN,
  });
  const [inputValue, setInputValue] = React.useState("");

  const action = getPinAction(state);

  const validatePin = async (pin: string) => {
    setInputValue("");
    const refPin = state.pin || state.pinValue;

    if (refPin && refPin.toString() !== pin.toString()) {
      dispatch({ type: ERROR });
      return;
    }

    switch (action) {
      case ENTER_PIN:
        dispatch({ type: UNLOCK });
        return;
      case SET_PIN:
        dispatch({ type: SET_PIN, payload: { pin } });
        return;
      case CONFIRM_PIN:
        dispatch({ type: CONFIRM_PIN, payload: { pin } });
        return;
    }
  };

  const value = React.useMemo(
    () => ({
      ...state,
      lock: () => {
        dispatch({ type: LOCK });
      },
      reset: () => {
        dispatch({ type: RESET_PIN });
      },
    }),
    [state, dispatch]
  );

  React.useEffect(() => {
    (async function getFromCache() {
      const cachedPin = await Cache.get(CACHE_KEY_PIN);
      dispatch({
        type: INITALIZE,
        payload: { pin: cachedPin ? cachedPin.toString() : undefined },
      });
    })();
  }, []);

  React.useEffect(() => {
    (async function updateCache() {
      if (state.pin) await Cache.set(CACHE_KEY_PIN, state.pin.toString());
      else await Cache.remove(CACHE_KEY_PIN);
    })();
  }, [state.pin]);

  if (!state.initialized) {
    return null;
  }

  return MUST_SET_PIN && state.isLocked ? (
    <PinCode
      onComplete={validatePin}
      label={t(`pin.label.${getLabelKey(action, state.error)}`)}
      value={inputValue}
      onChange={setInputValue}
      hasError={state.error}
      resetPin={action === CONFIRM_PIN ? value.reset : undefined}
    >
      {action === ENTER_PIN && (
        <LocalAuth onConfirm={() => dispatch({ type: UNLOCK })} />
      )}
    </PinCode>
  ) : (
    <PinContext.Provider value={value}>{children}</PinContext.Provider>
  );
};

export const usePin = () => React.useContext(PinContext);
