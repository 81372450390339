import React, { useEffect, useState } from "react";
import { AppConfig } from "./startup/types";
import config from '../assets/app.config.json';


export const AppConfigContext = React.createContext<AppConfig>({});

export const AppConfigProvider = ({children}: any) => {

    return (
        <AppConfigContext.Provider value={config}>
            {children}
        </AppConfigContext.Provider>
    );
}

export default AppConfigProvider;