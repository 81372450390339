import styled from "styled-components/native";
import { ActivityIndicator, Alert, DeviceEventEmitter, NativeEventEmitter, NativeModules, Platform, StyleSheet } from "react-native";
import useIsMobile from "../hooks/useIsMobile";
import React from "react";
import { DEFAULT_CARD_THEME } from "../constants/card-theme";
import { useStartupConfig, useStartupUser } from "../contexts/startup/hooks";
import { CardHolderContext } from "../contexts/card-holder";
import { View, Image, Text, Icon } from "../components/Styled";
import { Button, Stack } from "native-base";
import { useTranslation } from "../contexts/translations";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { MainStackParams } from "../navigation/types";
import * as API from "../services/api";
import { ICard } from "../interfaces/card.interface";
import { BLOCKED, EXPIRED, WITHDRAWN } from "../constants/card-status";
import * as SaltoSpaceService from "../services/saltoSpaceService";
import * as Cache from "../services/cache";

const { SaltoSDKModule, JustinBleModule } = NativeModules;

type Card = { isMobile: boolean };
const Card = styled.View<Card>`
  ${Platform.OS === "android" && `elevation: 5`}
  width: 100%;
  height: 100%;
  align-self: center;
  margin: ${(props) => !props.isMobile ? "40px" : "0"} 20px 20px;
  flex: 1;
`;

type ComonBgColorProp = { bgColor: string };

type CardTop = { bgColor: string, isMobile: boolean };
const CardTop = styled.View<CardTop>`
  background: ${(props) => props.bgColor};
  padding: ${(props) => props.isMobile ? "50px 0 70px" : "0px 0 70px"}
`;

const CardBottom = styled(Stack) <ComonBgColorProp>`
  background: ${(props) => props.bgColor};
  padding: 10px 20px;
`;

var digitalCard = {} as ICard;

export default function KeysScreen() {
    const navigation = useNavigation<StackNavigationProp<MainStackParams>>();
    const isMobile = useIsMobile();
    const [{ data, isLoading }] = React.useContext(CardHolderContext);
    const { t } = useTranslation();
    const user = useStartupUser() || {};
    const [opening, setOpening] = React.useState<boolean>(false);
    const [success, setSuccess] = React.useState<boolean>();

    const handleSalto = async () => {
        setOpening(true);
        
        const cardsObj = await API.getCardCredentials(user?.cardholderId!);

        const cardsArrPerType = cardsObj.data[0].cardsPerType;

        cardsArrPerType.map((card: any) => {
            if (
            Array.from(card.cards).find((s: any) => s.type.category.isDigital) !==
            undefined
            ) {
            digitalCard = Array.from(card.cards).find(
                (s: any) =>
                s.type.category.isDigital &&
                s.status !== BLOCKED &&
                s.status !== WITHDRAWN &&
                s.status !== EXPIRED
            ) as ICard;
            }
        });

        const response = await SaltoSpaceService.getBinaryKey(digitalCard.id);
        const key = response?.data?.params?.mobilePhoneBinaryData?.binaryData;
        
        openSalto(key)
    }

    async function openSalto(key: any) {

        if (Platform.OS === 'ios') {

            await Cache.set("opening-time", new Date().getTime().toString());
            await Cache.set("opening", "true");

            await SaltoSDKModule.startOpening(key);

        } else if (Platform.OS === 'android') {

            await Cache.set("opening-time", new Date().getTime().toString());
            await Cache.set("opening", "true");

            await JustinBleModule.startOpening(key);
        }

        await Cache.set("salto-key", key);
    }

    const onSuccessEvent = () => {
        Cache.set("opening", "false");
        setOpening(false);
        setSuccess(true);
        setTimeout(function(){navigation.goBack()}, 2000);
    };

    const onErrorEvent = () => {
        Cache.set("opening", "false");
        setOpening(false);
        
        Alert.alert(
            `${t("profile.error_opening_title")}`,
            `${t("profile.error_opening_message")}`,
            [
            {
                text: `${t("profile.button_ok")}`,
                style: "cancel",
            },
            ]
        );
    };
    
    React.useEffect(() => {
        let onSuccessListener;
        let onErrorListener;

        if (Platform.OS === 'android') {
            JustinBleModule.isBluetoothEnabled();
        }
        else if (Platform.OS === 'ios') {
            SaltoSDKModule.isBluetoothEnabled();
        }

        try {
            if (Platform.OS === "ios") {
                const eventEmitter = new NativeEventEmitter(SaltoSDKModule);
                eventEmitter.removeAllListeners("onSaltoSucessEvent");
                eventEmitter.removeAllListeners("onSaltoErrorEvent");
                onSuccessListener = eventEmitter.addListener(
                    "onSaltoSuccessEvent",
                    onSuccessEvent
                );
                onErrorListener = eventEmitter.addListener(
                    "onSaltoErrorEvent",
                    onErrorEvent
                );
            } else if (Platform.OS === "android") {
                DeviceEventEmitter.removeAllListeners();
                onSuccessListener = DeviceEventEmitter.addListener(
                    "onSaltoSuccessEvent",
                    onSuccessEvent
                );
                onErrorListener = DeviceEventEmitter.addListener(
                    "onSaltoErrorEvent",
                    onErrorEvent
                );
            }
        } catch (error) {
            console.log("error", error);
        }
    }, []);
    
    React.useEffect(() => {
        (async () => {
            const isOpening = Boolean(await Cache.get("opening"));
            
            const openTime = await Cache.get("opening-time");
            const currentTime = new Date();
            const difference = (currentTime.getTime() - openTime);
            
            if (difference < 10000 && isOpening) {
            setOpening(true);
            const timeoutId = setTimeout(() => {
                setOpening(false);
            }, 10000-difference);
            
            }
        })();
    }, []);

    return (
        <View $fullscreen $transparent justifyContent="center">
            {!isLoading && !!data && (
                <Card isMobile={isMobile}>
                    <CardBottom
                        bgColor={"white"}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-end"
                    >
                        <View $transparent spaceVertical={10} style={{alignItems: 'center', flex: 1, justifyContent: "center", paddingTop: 20}}>
                            <Text style={{paddingBottom: 40, fontSize: 20}}>
                                {opening && !success ? t('keys.present_phone') :
                                !opening && success ? t('keys.success') :
                                t('keys.open')
                                }</Text>
                            {opening ? (
                                <ActivityIndicator
                                    size={250}
                                />
                                ) : (<Button colorScheme="primary" 
                                style={{width: 250, height: 250, borderRadius: 125, backgroundColor: 'limegreen'}}
                            ><Icon size={150} color={'white'} onPress={handleSalto} as={MaterialCommunityIcons} name={"key-variant"}/>
                                
                            </Button>)}
                            
                        </View>
                    </CardBottom>
                </Card>
            )}
        </View>
    );
}