import * as React from "react";
import styled from "styled-components/native";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";

import { View, Text } from "../components/Styled";
import { useTranslation } from "../contexts/translations";
import useFocusTrap from "../hooks/useFocusTrap";
import useKeyboardEvent from "../hooks/useKeyboardEvent";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Icon } from "native-base";
import { MainStackParams } from "../navigation/types";

const Overlay = styled.View`
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
`;

const Sidebar = styled(View)`
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  height: 100vh;
  padding: 20px;
  overflow: auto;
`;

const CloseButton = styled.TouchableOpacity``;

export default function withSidebar(title: string, Component: React.FC) {
  return function withSidebarComponent(props: {}) {
    const { t } = useTranslation();
    const navigation =
      useNavigation<StackNavigationProp<MainStackParams>>();
    const [focusRef, tabKey, tabHandler] = useFocusTrap(navigation);
    useKeyboardEvent({
      Escape: () => {
        if (navigation.isFocused()) {
          navigation.goBack();
        }
      },
      [tabKey]: tabHandler,
    });
    return (
      <Overlay ref={focusRef}>
        <Sidebar width={460}>
          <View
            $transparent
            flexDirection="row"
            justifyContent="space-between"
            spaceBottom={20}
          >
            <Text
              size={18}
              $uppercase
              $bold
              // accessibilityLabel={t("accessibility.modal.title", { title })}
            >
              {title}
            </Text>
            <CloseButton
              onPress={() => navigation.goBack()}
              accessible
              accessibilityLabel={t("accessibility.modal.close", { title })}
              accessibilityRole="button"
            >
              <Icon as={MaterialCommunityIcons} name="window-close" />
            </CloseButton>
          </View>
          <View $transparent $fullscreen>
            <Component {...props} />
          </View>
        </Sidebar>
      </Overlay>
    );
  };
}
