export type DatePickerProps = {
  disabled?: boolean
  value: string;
  onChange: (date: string) => void;
};

export function getStringValue(value: Date | string) {
  var moment = require('moment');

  if (!value) {
    return "";
  }

  const valueDate = typeof value === "string" ? new Date(value) : value;
  return moment(valueDate).format("YYYY-MM-DD")
}
