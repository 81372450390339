import React, { useState, useEffect, useRef } from "react";
import { Platform } from "react-native";
import { Camera, CameraCapturedPicture, CameraMountError } from "expo-camera";
import { CameraType } from "expo-camera/build/Camera.types";
import { Button, Icon, Toast, View as NativeView } from "native-base";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useTranslation } from "../../contexts/translations";
import { RatioType } from "../../types";
import { getBestPictureSize, getCorrectSize, parseRatio } from "../../utils";
import CameraTrigger from "./CameraTrigger";
import { View, Text } from "../Styled";
import Preview from "./Preview";
import { MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";

type CameraViewPropType = {
  onCancel: () => Promise<void> | void;
  onSelect: (picture: CameraCapturedPicture) => Promise<void> | void;
  defaultCamera?: CameraType;
};

function useCorrectRatio(ratioString: RatioType) {
  const { width } = useWindowDimensions();
  return getCorrectSize(width, parseRatio(ratioString));
}

const takePictureOptions = {
  quality: 0.5,
  base64: true,
  skipProcessing: false,
  exif: true,
  forceUpOrientation: true,
  fixOrientation: true,
};

const safeAsync = async (
  promise: () => Promise<any>,
  onErrorResponse: any = undefined
) => {
  try {
    return await promise();
  } catch (err) {
    console.warn("Fialed calling a promise", err);
    return onErrorResponse;
  }
};

export default function CameraView({
  onCancel,
  onSelect,
  defaultCamera = CameraType.front,
}: CameraViewPropType) {
  const ratio = "4:3";
  const [height, width] = useCorrectRatio(ratio);
  const cameraRef = useRef<Camera | null>();
  const [type, setType] = useState<CameraType>(defaultCamera);
  const [hasBackCamera, setHasBackCamera] = useState<boolean>(false);
  const [showCamera, setShowCamera] = useState<boolean>(true);
  const [hasPermission, setHasPermission] = useState<boolean | null>(null);
  const [isCameraReady, setCameraReady] = useState<boolean>(false);
  const [pictureSize, setPictureSize] = useState<string | undefined>(undefined);
  const [picture, setPicture] = useState<CameraCapturedPicture>();
  const { t } = useTranslation();
  const [permission, requestPermission] = Camera.useCameraPermissions();


  // const getPermissions = async () => {
  //   let permissions = await safeAsync(() =>
  //     Camera.getCameraPermissionsAsync()
  //   );

  //   /**
  //    * Camera.isAvailableAsync can only be called in web
  //    */
  //   const isCameraAvailable =
  //     Platform.OS !== "web" ||
  //     (await safeAsync(() => Camera.isAvailableAsync(), false));

  //   if (!isCameraAvailable) {
  //     Toast.show({
  //       status: "warning",
  //       title: t("camera.not_available"),
  //       duration: 8000,
  //     });
  //     return;
  //   }

  //   if (!permissions || (permissions.canAskAgain &&
  //       (permissions.status !== "granted" ||
  //         (Platform.OS === "web" && (await Camera.isAvailableAsync()))))
  //   ) {
  //     permissions = await safeAsync(() =>
  //     Camera.getCameraPermissionsAsync()
  //   );
  //   }

  //   setHasPermission(
  //     (!permissions && (await Camera.isAvailableAsync())) || // this is odd! if I can't get permissions I can simply assume it's granted (Firefox case)
  //       permissions.status === "granted"
  //   );
  // };

  const getAvailablePictureSizes = async () => {
    const sizes = await cameraRef.current?.getAvailablePictureSizesAsync(ratio);
    if (sizes) {
      setPictureSize(getBestPictureSize(sizes)?.size);
    }
  };

  const setCameraRef = async (ref: Camera) => {
    cameraRef.current = ref;
  };

  const handleTrigger = async () => {
    if (cameraRef.current) {
      const _picture = await cameraRef.current.takePictureAsync({
        quality: 0.5,
        base64: true,
        skipProcessing: false,
        exif: true,
        isImageMirror: type === CameraType.front ?  true : false,
      });
      // setPicture(_picture);
      onSelect(_picture);
    }
  };

  const handleDismiss = async () => {
    setPicture(undefined);
  };

  const handleConfirm = () => {
    if (picture) {
      onSelect(picture);
    }
  };

  const onCameraReady = async () => {
    await getAvailablePictureSizes();
    setCameraReady(true);
  };

  const onMountError = (event: CameraMountError) => {
    console.warn("Failed mounting camera", event);
    Toast.show({
      accessible: true,
      accessibilityLabel: t("camera.error"),
      duration: 8000,
      render:() => <View bgColor="orange" style={{padding:10}}>
        <Text color="white">{t("camera.error")}</Text>
      </View>
    })
  };

  const toggleCamera = async () => {
    setShowCamera(false)

    setType(
      type === Camera.Constants.Type.back
        ? Camera.Constants.Type.front
        : Camera.Constants.Type.back
    );
    setTimeout(async () => {
      setShowCamera(true)
    }, 200);

    
  };

  useEffect(() => {
    // getPermissions();
    if (permission && !permission.granted) {
      requestPermission()
    }else{
      setHasPermission(true)
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (await Camera.isAvailableAsync()) {
        const types = await Camera.getAvailableCameraTypesAsync();
        if (types.filter(t => t === "back")[0] !== undefined) {
          setHasBackCamera(true)
        }
      }
    })()

  })

  useEffect(() => {
    if (hasPermission === false) {
      Toast.show({
        accessible: true,
        accessibilityLabel: t("camera.permission_denined"),
        duration: 8000,
        render:() => <View bgColor="orange" style={{padding:10}}>
          <Text color="white">{t("camera.permission_denined")}</Text>
        </View>
      })
      onCancel();
    }
  }, [hasPermission]);

  if (!hasPermission) {
    return null;
  }

  if(showCamera){
    return (
      <View $fullscreen justifyContent="center">
        {/* {picture ? (
          <Preview
            photo={picture}
            onCancel={handleDismiss}
            onConfirm={handleConfirm}
          />
        ) : ( */}
        {
          showCamera &&
          <Camera
          style={{ width, height }}
          type={type} // Chrome doesn't like if we set camera type, so we prevent it on web
          autoFocus={Camera.Constants.AutoFocus.on}
          flashMode={Camera.Constants.FlashMode.off}
          whiteBalance={Camera.Constants.WhiteBalance.auto}
          videoStabilizationMode={Camera.Constants.VideoStabilization.standard}
          pictureSize={pictureSize}
          onCameraReady={onCameraReady}
          onMountError={onMountError}
          ref={setCameraRef}
          focusDepth={0}
          // ratio={ratio}
          zoom={0}
        >
          <View bgColor="white" space={10} width={40} height={40} justifyContent="center" alignItems="center">
            <Button variant="unstyled" onPress={onCancel}>
              <MaterialCommunityIcons name="chevron-left" size={28} />
            </Button>
          </View>
          <View
            $fullscreen
            $transparent
            flexDirection="row"
            alignItems="flex-end"
            spaceBottom={40}
          >
            <View
              $fullscreen
              $transparent
              flexDirection="row"
              alignContent="space-between"
              justifyContent="space-around"
            >
  
              {/* {!picture ? ( */}
              <>
                <NativeView style={{ width: 40 }} />
  
                <CameraTrigger
                  onPress={handleTrigger}
                  disabled={!isCameraReady}
                />
                {hasBackCamera ? (
                  <Button
                    onPress={toggleCamera}
                  >
                    <Icon name="refresh" as={MaterialIcons} color="white" />
                  </Button>
                ) : (
                  <NativeView style={{ width: 40 }} />
                )}
              </>
              {/* ) : (
                  <Button.Group>
                    <Button
                      colorScheme="danger"
                      leftIcon={
                        <Icon name="close" as={MaterialIcons} color="white" />
                      }
                      onPress={handleDismiss}
                    >
                      {t("camera.retry")}
                    </Button>
                    <Button
                      colorScheme="success"
                      leftIcon={
                        <Icon name="check" as={MaterialIcons} color="white" />
                      }
                      onPress={handleConfirm}
                    >
                      {t("camera.save")}
                    </Button>
                  </Button.Group>
                )} */}
            </View>
          </View>
        </Camera>
        }
        {/* )} */}
      </View>
    );
  }else{
    return null;
  }
  
}
