import { Checkbox, FormControl, Icon, ScrollView, Stack, Select } from "native-base";
import * as React from "react";
import { Button, Text, View, Divider, Input } from "../components/Styled";
import { useStartupConfig, useStartupProfile, useStartupUser, useStartupUserActions } from "../contexts/startup/hooks";
import { useTranslation } from "../contexts/translations";
import * as Cache from "../services/cache";
import { HStack, Pressable, Toast } from "native-base";
import { Image, StyleSheet, ActivityIndicator, Platform, SafeAreaView } from "react-native";
import ClientBrand from "../components/ClientBrand";
import useIsMobile from "../hooks/useIsMobile";
import * as API from "../services/api";
import { useAuth } from "../contexts/auth/hooks";
import { FontAwesome, Ionicons } from "@expo/vector-icons";
import WizardHeader from "../components/WizardHeader";
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { CardHolderContext, UserProfileData } from "../contexts/card-holder";
import {
    editableFields,
    fieldsKeyboardType,
    profileFields,
    cardHolderFields
} from "../constants/fixtures";
import { useTheme } from "../contexts/theme/hooks";
import { DARK_MODE } from "../constants/Colors";
import DatePicker from "../components/DatePicker";

type Field = typeof profileFields[number];
type FieldsState = Partial<UserProfileData>;

type PersonFields = {
    attributeId: string,
    isEnum: boolean,
    name: string,
    translation: string,
    uneditable: boolean
}
type FreeFieldsType = {
    show: boolean;
    required: boolean;
    description: string;
}


function MissingFieldScreen({ children }: { children: JSX.Element }) {
    const isMobile = useIsMobile();
    const { t } = useTranslation();

    const data = useStartupUser() || {};
    const [theme] = useTheme();
    const [isLoading, setIsLoading] = React.useState(true);
    const [isSavingLoads, setIsSavingLoads] = React.useState(false);
    const [wizardFields, setWizardFields] = React.useState<any[]>([]);
    const [freeFields, setFreeFields] = React.useState<{}>({});
    const [systemLabels, setSystemLabels] = React.useState<any[]>([]);
    const [wizardDone, setWizardDone] = React.useState<boolean | null>();
    const [listOfOptions, setListOfOptions] = React.useState(null);
    const { reload: reloadStartupData } = useStartupUserActions();
    const { refreshAuth } = useAuth();
    const [values, setValues] = React.useState<FieldsState>({});
    const profile = useStartupProfile();
    const [isSubmitted, setIsSubmitted] = React.useState(false);


    function getValue(field: string) {
        return typeof values[field] !== "undefined"
          ? values[field]
          : data
            ? data[field]
            : "";
      }

    function getFieldTypeKeyboard(field: string) {
        return Object.keys(fieldsKeyboardType).includes(field)
            ? fieldsKeyboardType[field as keyof typeof fieldsKeyboardType]
            : "default";
    }

    React.useEffect(() => {
        (async () => {
            // if (__DEV__) {
                await refreshAuth();

                const concurrentRequests = [
                    API.getWizardSettings(),
                    API.getSystemLabels(),
                    API.getEnumOptions(),
                ];
                Promise.allSettled(concurrentRequests)
                    .then((result) => {

                        if (result[0]?.status === "fulfilled") {
                            const wFields = result[0].value;
                            if (wFields.data) {
                                setFreeFields(wFields.data.freeFieldsDescription);
                                setWizardFields(wFields.data.wizardFields);
                            }
                        }

                        if (result[1]?.status === "fulfilled") {
                            const sysL = result[1].value;
                            if (sysL.data) {
                                setSystemLabels(sysL.data)
                            }
                        }

                        if (result[2]?.status === "fulfilled") {
                            const resEnums = result[2].value;
                            setListOfOptions(resEnums.data);
                        }
                        setIsLoading(false)

                    })
                    .catch((err) => {
                        console.log(err);
                        setIsLoading(false)

                    });
            // } else {
            //     setIsLoading(false)
            // }
            const isFieldsDone = await Cache.get("WIZARD_FIELDS");
            setWizardDone(!!isFieldsDone);
        })()
    }, [])

    const lowerCaseFirst = (str: any) => {
        return str.charAt(0).toLowerCase() + str.slice(1);
    };


    const getFieldLabel = (field: any) => {
        if (systemLabels.length > 0) {
            let sysLabel = systemLabels.find(x => x.attributeId == field.id);
            return (sysLabel != null) ? sysLabel.label : field.translation;
        } else {
            return field.translation;
        }
    }


    function dateIsValid(date: string | number | Date) {
        return !Number.isNaN(new Date(date).getTime());
    }

    const handleOptionChange = (field: Field | string, value: string | number) => {
        setValues((current) => ({
            ...current,
            [field]: value,
          }));
    }

    const handleChange = (field: Field | string, value: string | number) => {
        let inputValues=values;
        inputValues[field]=value;
        setValues(values)
    };

    const handleSubmit = async () => {
        setIsSavingLoads(true);
        try {
            const cardholderFields = {
                locationId: values.location ? values.location : profile?.location?.id,
                departmentId: values.department ? values.department : profile?.department?.id,
                externalCompanyId: values.externalCompany ? values.externalCompany : profile?.externalCompany?.id,
                businessUnit: values.businessUnit ? values.businessUnit : profile?.businessUnit?.id,
                costAccount: values.costAccount ? values.costAccount : profile?.costAccount?.id,
                nameOnCard: values.nameOnCard ? values.nameOnCard : profile?.nameOnCard,
                note: values.note ? values.note : profile?.note,
                jobTitle: values.jobTitle ? values.jobTitle : profile?.jobTitle,
                // freeField2: cardHolderData.freeField2 ? cardHolderData.freeField2 : cprofile?.freeField2,
                // freeField1: cardHolderData.freeField1 ? cardHolderData.freeField1 : cprofile?.freeField1,
            }

            await refreshAuth();
            const resProfile = await API.updateCardHolder(data?.cardholderId, cardholderFields);
            const res = await API.updateUser(values);
            if (res.data && resProfile.data) {
                await reloadStartupData();
                setTimeout(() => {
                    setIsSubmitted(true);
                    setIsSavingLoads(false);
                    setWizardDone(true);
                }, 1000);
            } else {
                Toast.show({
                    accessible: true,
                    accessibilityLabel: t("profile.edit.failed"),
                    duration: 8000,
                    render: () => <View bgColor="orange" style={{ padding: 10 }}>
                        <Text color="white">{t("profile.edit.failed")}</Text>
                    </View>
                })
                setIsSavingLoads(false);
            }
            await Cache.set("WIZARD_FIELDS", "true");
        } catch (error) {
            Toast.show({
                accessible: true,
                accessibilityLabel: t("profile.edit.failed"),
                duration: 8000,
                render: () => <View bgColor="orange" style={{ padding: 10 }}>
                    <Text color="white">{t("profile.edit.failed")}</Text>
                </View>
            })
            console.warn("Failed updating user data", error.response.data);
            setIsSavingLoads(false);
        }
    }

    const SubHeadView = () => {
        return (
            <View $fullscreen as={ScrollView}>
                {isSavingLoads &&
                    <ActivityIndicator
                        accessible
                        accessibilityLabel={t("accessibility.loading", {
                            text: "",
                        })}
                    />}
                <FormControl style={{ padding: 10 }}>
                    {
                        wizardFields.map((field, fieldKey) => (
                            <View key={field.id} spaceBottom={20} $transparent>
                                <FormControl.Label accessible={false}>
                                    {getFieldLabel(field)}
                                </FormControl.Label>
                                {
                                    field.name === "BirthDate" ?
                                        (
                                            <DatePicker
                                                value={dateIsValid(getValue(lowerCaseFirst(field.name))) ? getValue(lowerCaseFirst(field.name)) : data.birthdate}
                                                onChange={(value) => handleChange(lowerCaseFirst(field.name), value)}
                                            />
                                        ) :
                                        listOfOptions && listOfOptions?.hasOwnProperty(`${lowerCaseFirst(field.name)}Options`) ?
                                            <Select
                                                h={Platform.OS === "web" ? 9 : undefined}
                                                dropdownIcon={<Icon name="caret-down" as={FontAwesome} size={17} />}
                                                selectedValue={getValue(lowerCaseFirst(field.name))}
                                                placeholder={getFieldLabel(field)}
                                                onValueChange={(value) => handleOptionChange(lowerCaseFirst(field.name), value)}
                                                defaultValue={profile[lowerCaseFirst(field.name)]?.id}
                                            >
                                                {
                                                    listOfOptions[`${lowerCaseFirst(field.name)}Options`].sort((a: { name: string; }, b: { name: string; }) => a.name.localeCompare(b.name)).map((item: { name: string; id: string; }, index: React.Key | null | undefined) =>
                                                        <Select.Item key={index} label={item.name} value={item.id} />
                                                    )
                                                }
                                            </Select> :
                                            <Input
                                                accessibilityLabel={getFieldLabel(field)}
                                                placeholder={field.name === "MobileNumber" ? "eg: +19222222222" : field.name === "PhoneNumber" ? "Office/Home Number" : getFieldLabel(field)}
                                                onChangeText={(value) => handleChange(lowerCaseFirst(field.name), value)}
                                                keyboardType={getFieldTypeKeyboard(lowerCaseFirst(field.name))}
                                                defaultValue={getValue(lowerCaseFirst(field.name))}
                                                onBlur={() => {
                                                    console.log("")
                                                }}
                                            />
                                }

                            </View>
                        ))
                    }
                </FormControl>
            </View>
        )
    }

    const BottomButtonView = () => {
        return (
            <>
                <Divider $fullSize color={theme === DARK_MODE ? "#5c5b5b" : "#ddd"} />
                <View spaceVertical={hp("0.5%")} $transparent flexDirection="row" width={"100%"}>
                    <>
                        <Button
                            variant="unstyled"
                            alignItems="center"
                            justifyContent="center"
                            $fullWidth
                            height={hp("5%")}
                            disabled={isSavingLoads}
                            onPress={async () => setWizardDone(true)}
                        >
                            <Text color={isSavingLoads ? "grey" : "#2969cf"} $responsive size={1.7}>{t("profile.button_skip")}</Text>
                        </Button>
                        <Divider $vertical />
                        <Button
                            variant="unstyled"
                            alignItems="center"
                            justifyContent="center"
                            $fullWidth
                            height={hp("5%")}
                            // isDisabled={!isSubmitted}
                            // display={!isSubmitted}
                            onPress={async () => {
                                if(Object.keys(values).length === 0){
                                    await Cache.set("WIZARD_FIELDS", "true");
                                    setWizardDone(true);
                                }else{
                                    handleSubmit()
                                }
                            }}
                            disabled={isSavingLoads}
                        >
                            <Text color={isSavingLoads ? "grey" : "#2969cf"} $responsive size={1.7}>{t("auth.btn_next")}</Text>
                        </Button>
                    </>
                </View>
            </>
        )
    }

    return wizardDone === null ? null : wizardDone === true || wizardFields.length === 0 && !isLoading || !freeFields ? children : (
        <View $fullscreen>
            {
                isLoading ?
                    <View $transparent flexGrow={1} justifyContent="center">
                        <ActivityIndicator
                            accessible
                            accessibilityLabel={t("accessibility.loading", {
                                text: "",
                            })}
                        />
                    </View> :
                    isMobile ?
                        <SafeAreaView style={{ justifyContent: "space-around", flex: 1 }}>
                            <View $transparent alignItems="center">
                                <ClientBrand />
                            </View>
                            {freeFields !== undefined && freeFields.show && (
                                    <View justifyContent="center" alignItems="center" spaceVertical={hp("5%")}>
                                        <Text $textAlign="center" $responsive size={2}>{freeFields.description}</Text>
                                    </View>
                                )}
                            <SubHeadView />
                            <View $transparent spaceTop={10}>
                                <BottomButtonView />
                            </View>
                        </SafeAreaView>
                        :
                        <View $fullscreen bgColor="#eee">
                            <WizardHeader />
                            <View bgColor="white"
                                spaceVertical={hp("5%")}
                                justifyContent="center"
                                borderWidth={1}
                                borderColor="lightgrey"
                                width={wp("50%")}
                                alignSelf="center"
                                style={{ flex: 1 }}
                            >
                                <View $transparent spaceTop={hp("10%")}>
                                    <Text accessibilityRole="header" $bold $textAlign="center" $responsive size={3}>{t("header.a_information")}</Text>
                                </View>
                                {freeFields !== undefined && freeFields.show && (
                                    <View justifyContent="center" alignItems="center" spaceVertical={hp("5%")}>
                                        <Text $textAlign="center" $responsive size={2}>{freeFields.description}</Text>
                                    </View>
                                )}

                                <SubHeadView />
                                <View $transparent alignItems="center">
                                    <BottomButtonView />
                                </View>
                            </View>
                        </View>
            }
        </View>
    )
}

export default MissingFieldScreen;
