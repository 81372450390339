import React from "react";
import styled from "styled-components/native";
import { useAuth } from "../contexts/auth/hooks";

const SandboxBannerWrapper = styled.Text`
  background: red;
  color: white;
  font-size: 14px;
  text-align:center;
  padding: 4px;
`;

export default function SandboxBanner(props: React.Component["props"]) {
  const { isLiveEnabled } = useAuth();
  return (
    <>
      {!isLiveEnabled && (
        <SandboxBannerWrapper>
          You're currently running the sandbox environment
        </SandboxBannerWrapper>
      )}
      {props.children}
    </>
  );
}
