import React from 'react';
import { StandTemplate } from '../interfaces/standTemplate.interface';

const defaultContextValue: StandTemplateContextValue = {
  standTemplate: {
    template: {
      cardTop: {
        background: "#FFFFFF",
        text: "#FFFFFF"
      },
      cardMiddle: {
        background: "#5EC945",
        text: "#FFFFFF"
      },
      cardBottom: {
        background: "#419E2B",
        text: "#FFFFFF"
      },
      firstPosition: ["Person.FirstName", "Person.LastName"],
      secondPosition: "Person.PersonNumber",
      thirdPosition: "",
      fourthPosition: "Cardholder.ProfileName",
      fifthPosition: "Cardholder.EndDate"
    },
    logo: ''
  },
  setStandTemplate: () => undefined
};
export const defaultValue: StandTemplate = defaultContextValue.standTemplate;

type StandTemplateContextValue = {
  standTemplate: StandTemplate,
  setStandTemplate: React.Dispatch<React.SetStateAction<StandTemplate>>
}

export const StandTemplateContext = React.createContext<StandTemplateContextValue>(defaultContextValue);

export const StandTemplateProvider = ({ children }: any) => {
  const [standTemplate, setStandTemplate] = React.useState<StandTemplate>(defaultValue);

  return (
    <StandTemplateContext.Provider value={{ standTemplate, setStandTemplate }}>
      {children}
    </StandTemplateContext.Provider>
  );
};
