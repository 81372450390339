export interface SaltoSpaceResponse {
    requestName: string;
    params: RequestResponseParams;
    exception: Exception;
}

interface RequestResponseParams {
    protocolId: string;
    protocolVersion: string;
    dateTime: Date;
    defaultLanguage: string;
    extUserId: string;
    pictureFileResult: string;
}

interface Exception {
    code: number;
    message: string;
}

export enum MessageNotificationTypes {
    HidMobileAccess = "hid_mobile_access",
    SaltoSpace = "salto_space"
}